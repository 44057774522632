import React, { useState, useEffect } from 'react'
import API from '@service/index'
import DownloadApp from '@components/downloadApp/download_white'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import './styles.less'


const RecommendShare = ({ history }: any) => {

	const [shareInfo, setShareInfo] = useState<any>([])
	const [isNative, setIsNative] = useState<boolean>(false)

	const getDataInfo = async (articleNo) => {
		const res = await API.id64418({
			articleNo
		})
		if (res) {
			setShareInfo(res)
		}
	}
	useEffect(() => {
		setIsNative(!!window['YoTown'])
		const articleNo = window.location.href?.split('?')?.[1]?.split('=')?.[1];
		getDataInfo(articleNo)
	}, [])
	return (
		<div className="recommendShare">
			<div className='recommendShare-inner'>
				{!isNative && <DownloadApp />}
				<Swiper
					speed={1000}
					loop
					autoplay
					navigation={{ prevEl: '.prev', nextEl: '.next' }}
					touchMoveStopPropagation
					pagination={{
						el: '.swiper-pagination',
						type: 'bullets',
						clickable: true,
					}}
					style={{
						width: '100%',
					}}
				>
					{shareInfo?.pictures?.map((item, index) => {
						return (
							<SwiperSlide key={index} style={{

								minHeight: '400px'
							}}>
								<img src={item} alt="" className='item-img' />
							</SwiperSlide>
						)
					})}
					<div className="swiper-pagination"></div>
				</Swiper>
				<div className='text-container'>
					<div className='content-text'>
						<div className='content-title'>{shareInfo?.title ?? '--'}</div>
						<div className='content-container' dangerouslySetInnerHTML={{ __html: shareInfo?.content }} />
					</div>
					<div className='user-info'>
						<img src={shareInfo?.userHeadPicture} alt="" className='user-head-img' />
						<div className='user-nickName'>{shareInfo?.nickName ?? '--'}</div>
					</div>
					<div className='products-list'>
						<div className='scroll-product'
							style={{
								width: `${shareInfo?.products?.length * 230}px`
							}}
						>
							{
								shareInfo?.products?.map((item: any) => <div
									className='item-card'
									key={item?.productNo}
									onClick={() => {
										history.push(`/goodsDetail?productNo=${item?.productNo}`)
									}}
								>
									<img src={item?.previewImgUrl} alt="" className='product-img' />
									<div className='product-context'>{item?.productName ?? '--'}</div>
								</div>)
							}
						</div>
					</div>
				</div>
			</div>

		</div >
	)
}

export default RecommendShare
