import { YWH5Track } from './h5_track'

const pathname = window.location.pathname
const { aplus_queue, aplus } = window as any

/**
 * pv
 * open : pv
 * close : timestamp
 *
 * */

export const routerView = {
	open: () => {
		console.log('pv', pathname)
		aplus_queue.push({
			action: 'aplus.sendPV',
			arguments: [{ is_auto: false }],
		})
	},
	close: (timestamp: number) => {
		console.log('pv leave', timestamp, pathname)

		if (timestamp) {
			const eventCode = YWH5Track.router2trackIds.get(pathname)
			const eventType = 'CLK'
			const eventParams = { pathname, timestamp }
			aplus_queue.push({
				action: 'aplus.record',
				arguments: [eventCode, eventType, eventParams],
			})
		}
	},
}
// 点击事件
export const CLICK_TRACK = (eventCode, eventType = 'CLK', eventParams = {}) => {
	console.log('点击事件', eventCode)
	aplus_queue.push({
		action: 'aplus.record',
		arguments: [eventCode, eventType, eventParams],
	})
}
// 分享
export const SHARE_TRACK = (eventCode, eventType = 'CLK', eventParams) => {
	aplus.trackShare({}, function() {
		console.log('分享回调')
	})
}
