import React from 'react'
import { Tag } from 'antd-mobile'
import { YT_BRADGE_COLOR, YT_BRADGE } from '@utils/constants'
import './index.less'

type YTBradgeProps = {
	rarityLevel: number
	rarityLevelDesc: string
}

const YTBradge = (props: YTBradgeProps) => {
	const { rarityLevel, rarityLevelDesc } = props
	return (
		<div className="ytbrange">
			<Tag className="selfTag" color={YT_BRADGE_COLOR.get(rarityLevel)}>
				{rarityLevelDesc || YT_BRADGE.get(rarityLevel)}
			</Tag>
		</div>
	)
}

export default YTBradge
