import React from 'react'
import './style/index.less'
const Test1 = () => {
	return (
		<div className="test1">
			<h3>
				<span className="s1">
					<b>yotown</b>
				</span>
				<span className="s2">
					<b>（柚堂）软件许可及服务协议</b>
				</span>
			</h3>
			<p className="p2">
				<span className="s2">欢迎您使用</span>
				<span className="s1">yotown</span>
				<span className="s2">（柚堂）软件及服务！</span>
			</p>
			<p className="p2">
				<span className="s2">《</span>
				<span className="s1">yotown</span>
				<span className="s2">（柚堂）软件用户服务协议》（以下简称</span>
				<span className="s1">“</span>
				<span className="s2">本协议</span>
				<span className="s1">”</span>
				<span className="s2">
					）是您与杭州那夏网络科技有限公司（作为运营方）就向您提供明星潮品软件服务（以下简称
				</span>
				<span className="s1">“</span>
				<span className="s2">本服务</span>
				<span className="s1">”</span>
				<span className="s2">
					）所订立的有效合约。请您先仔细阅读本协议内容，尤其是字体加粗部分。如您对本协议内容或页面提示信息有任何疑问的，请勿进行下一步操作。您可以通过软件的在线客服进行咨询，以便我们为您解释和说明。
				</span>
			</p>
			<p className="p3">
				<span className="s2"> </span>
			</p>
			<p className="p2">
				<span className="s2">【特别提醒】</span>
			</p>
			<p className="p3">
				<span className="s2"> </span>
			</p>
			<p className="p2">
				<span className="s2">
					请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制那夏责任的条款、对用户权利进行限制的条款、约定争议解决方式和司法管辖的条款等，以及开通或使用某项服务的单独协议。限制、免责条款或者其他涉及您重大权益的条款可能以加粗、加下划线等形式提示您重点注意。如果您对本协议内容或页面提示信息有任何疑问，请先联系客服咨询。除非您已充分阅读、完全理解并接受本协议所有条款，否则您无权下载、安装或使用本软件和
				</span>
				<span className="s1">/</span>
				<span className="s2">或相关服务。您点击</span>
				<span className="s1">“</span>
				<span className="s2">同意</span>
				<span className="s1">”</span>
				<span className="s2">
					或您的下载、安装、使用、登录等行为或者您以其他任何明示或者默示方式表示接受本协议的，即视为您已阅读并同意本协议的约束。本协议即在您与杭州那夏网络科技有限公司（以下简称
				</span>
				<span className="s1">“</span>
				<span className="s2">本公司</span>
				<span className="s1">”</span>
				<span className="s2">
					）之间产生法律效力，成为对双方均具有约束力的法律文件。任何未满
				</span>
				<span className="s1">18</span>
				<span className="s2">
					周岁的未成年人均不得注册帐号或使用本服务。本公司将依赖您提供的个人信息判断用户是否为未成年人。此外，如果您不具有完全民事行为能力，请在法定监护人（以下简称
				</span>
				<span className="s1">“</span>
				<span className="s2">监护人</span>
				<span className="s1">”</span>
				<span className="s2">
					）的陪同下阅读和判断是否同意本协议，您点击确认或其他类似按钮并继续使用本服务即视为您已经取得监护人的必要同意。
				</span>
			</p>
			<p className="p3">
				<span className="s2"> </span>
			</p>
			<p className="p2">
				<span className="s2">一、【协议的范围】</span>
			</p>
			<p className="p2">
				<span className="s1">1.1 </span>
				<span className="s2">本协议是您与杭州那夏网络科技有限公司（下称</span>
				<span className="s1">“</span>
				<span className="s2">那夏</span>
				<span className="s1">”</span>
				<span className="s2">或</span>
				<span className="s1">“</span>
				<span className="s2">我们</span>
				<span className="s1">”</span>
				<span className="s2">
					）之间关于您下载、安装、登录本软件，以及使用本服务所订立的协议。
				</span>
			</p>
			<p className="p2">
				<span className="s1">1.2 </span>
				<span className="s2">本协议的内容，包括但不限于以下与本软件和</span>
				<span className="s1">/</span>
				<span className="s2">
					或本服务相关的协议、规则、规范以及那夏可能不断发布的关于本软件和
				</span>
				<span className="s1">/</span>
				<span className="s2">
					或本服务的相关协议、规则、规范等内容。前述内容一经正式发布，即为本协议不可分割的组成部分，与其构成统一整体。
				</span>
			</p>
			<p className="p2">
				<span className="s1">1.3 </span>
				<span className="s2">
					那夏作为本软件的运营主体以及服务提供方，有权不经另行通知变更软件运营和
				</span>
				<span className="s1">/</span>
				<span className="s2">
					或服务提供主体。变更后的主体将继续按照本协议约定并向您提供服务，上述主体的变更不会影响您本协议项下的权益。
				</span>
			</p>
			<p className="p3">
				<span className="s2"> </span>
			</p>
			<p className="p2">
				<span className="s2">二、【本服务说明】</span>
			</p>
			{/* <p className="p2"><span className="s1">2.1 yotown</span><span className="s2">（柚堂）依托至信链（<a href="https://www.zxinchain.com/"><span className="s3">https://www.zxinchain.com/</span></a>）或后续新增的第三方合作方（具体以页面实际展示为准）提供的技术服务，通过本软件向您提供访问、分享、购买明星潮品（下称</span><span className="s1">“</span><span className="s2">潮品</span><span className="s1">”</span><span className="s2">）的入口。潮品具有独特性和限量属性，即潮品元数据中的创作者、创作和发布时间、购买者等信息，一经确认则不可篡改。您购买潮品后，作为购买者，您的相关信息将写入该潮品的元数据中，作为您拥有该潮品所有权的凭证。</span></p> */}
			<p className="p2">
				<span className="s1">2.1 </span>
				<span className="s2">
					为了获得潮品，您可能需要支付一定费用，具体收费标准以页面实际展示为准。您应该通过那夏指定的现有方式包括但不限于苹果支付、微信支付、支付宝支付以及各网银支付等或今后那夏指定的方式支付费用。
				</span>
			</p>
			<p className="p2">
				<span className="s1">2.2 </span>
				<span className="s2">
					您成功购买潮品后，本软件将会提供潮品展示的服务，并提供给您实物。除上述使用目的外，您不得用于任何其他目的。
				</span>
			</p>
			<p className="p2">
				<span className="s1">2.3</span>
				<span className="s2">
					您知晓并同意，鉴于网络服务的特殊性，如出现技术升级、服务体系升级、经营策略调整或配合国家重大技术、法律法规、政策等变化，那夏可能会根据需要更新或调整本软件和
				</span>
				<span className="s1">/</span>
				<span className="s2">
					或本服务的内容，甚至中止、终止向您提供全部或部分服务。上述行为不视为那夏违约，您无权要求那夏承担任何责任。
				</span>
			</p>
			<p className="p3">
				<span className="s2"> </span>
			</p>
			<p className="p2">
				<span className="s2">三、【关于本软件】</span>
			</p>
			<p className="p2">
				<span className="s1">3.1 </span>
				<span className="s2">
					本软件是指那夏或其关联公司开发并按照本协议之约定，授权用户下载、安装、登录、使用的软件。您可以通过手机终端以
				</span>
				<span className="s1">APP</span>
				<span className="s2">
					的形式使用本服务，具体以那夏提供的为准。同时，那夏会不断丰富您使用本服务的终端、形式等。当您使用本服务时，您应选择与您的终端、系统等相匹配的本软件版本，否则，您可能无法正常使用本服务。
				</span>
			</p>
			<p className="p2">
				<span className="s2">
					那夏给予您一项个人的、不可转让及非排他性的许可，以按照本协议及那夏其他协议、规则限定的范围和方式使用本软件及相关服务。您且仅限于您个人，可为非商业目的使用。
				</span>
			</p>
			<p className="p2">
				<span className="s1">3.1.1</span>
				<span className="s2">
					您可以为非商业目的在单台终端设备上下载、安装、使用、登录本软件。
				</span>
			</p>
			<p className="p2">
				<span className="s1">3.1.2</span>
				<span className="s2">
					本协议未明示授权的其他一切权利仍由那夏保留，您在行使这些权利时须另外取得那夏的书面许可。那夏如果未行使前述任何权利，并不构成对该权利的放弃。
				</span>
			</p>
			<p className="p2">
				<span className="s1">3.2 </span>
				<span className="s2">
					您可以直接从本软件的官方网站、官方应用分发本软件以及那夏授权的第三方网站、应用分发本软件等那夏官方或那夏授权的渠道获取本软件。
				</span>
			</p>
			<p className="p2">
				<span className="s1">3.3 </span>
				<span className="s2">
					如果您从未经那夏授权的第三方获取本软件或与本软件名称相同的安装程序，那夏无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。
				</span>
			</p>
			<p className="p2">
				<span className="s1">3.4 </span>
				<span className="s2">
					那夏可能为不同的终端、系统等开发不同的软件版本，您应当根据实际情况选择下载合适的版本进行安装。
				</span>
			</p>
			<p className="p2">
				<span className="s1">3.5 </span>
				<span className="s2">
					下载安装程序后，您需要按照该程序提示的步骤正确安装。为提供更加优质、安全的服务，在本软件安装时，那夏可能推荐您安装其他软件，您可以选择安装或不安装。
				</span>
			</p>
			<p className="p2">
				<span className="s1">3.6 </span>
				<span className="s2">
					如果您不再需要使用本软件或者需要安装新版软件，可以自行卸载。如果您愿意帮助那夏改进产品服务，请告知卸载的原因。
				</span>
			</p>
			<p className="p2">
				<span className="s1">3.7 </span>
				<span className="s2">
					为了增进用户体验、完善服务内容，那夏可能不断努力开发新的服务，并为您不时提供软件更新。
				</span>
			</p>
			<p className="p2">
				<span className="s1">3.8 </span>
				<span className="s2">
					为了改善用户体验或提高服务安全性、保证功能的一致性等目的，那夏有权对本软件进行更新，或者对本软件的部分功能效果、服务内容进行改变。
				</span>
			</p>
			<p className="p2">
				<span className="s1">3.9 </span>
				<span className="s2">
					本软件新版本发布后，旧版本的软件可能无法使用。那夏不保证旧版本软件继续可用，也不保证继续对旧版本软件提供相应的客服和维护服务，请您随时核对并下载最新版本。
				</span>
			</p>
			<p className="p3">
				<span className="s2"> </span>
			</p>
			<p className="p2">
				<span className="s2">四、【服务帐号】</span>
			</p>
			<p className="p2">
				<span className="s1">4.1 </span>
				<span className="s2">
					在您通过注册或使用本服务之前，您应确保您具备与使用本服务及购买、转赠明星潮品等相适应的民事行为能力，并有能力承担对应的法律后果和法律责任。如果您是未成年人，您在本软件购买、转赠明星潮品或进行其他行为，应获得您监护人的同意。您在使用本服务前需要使用手机号码注册帐号（以下或称
				</span>
				<span className="s1">“</span>
				<span className="s2">帐号</span>
				<span className="s1">”</span>
				<span className="s2">
					），并自行设置登录密码。那夏有权变更本软件帐号申请、使用、找回的规则，具体以那夏公布为准。在本软件一个手机号仅能注册和绑定一个账户。为保障您的账户安全，您同意按照本软件的要求及相关法律法规规定完成账户的实名认证。
				</span>
			</p>
			<p className="p2">
				<span className="s1">4.2 </span>
				<span className="s2">
					您在注册帐号或使用本服务的过程中，可能需要填写一些必要的信息。若国家法律法规（本协议中的
				</span>
				<span className="s1">“</span>
				<span className="s2">法律法规</span>
				<span className="s1">”</span>
				<span className="s2">指用户所属</span>
				<span className="s1">/</span>
				<span className="s2">
					所处地区、国家现行有效的法律、行政法规、司法解释、地方法规、地方规章、部门规章及其他规范性文件以及对于该等法律法规的不时修改和补充，以及相关政策规定等，下同）有特殊规定的，您需要填写真实、准确、合法、有效的身份信息（包括但不限于手机号码、身份证件等信息）。若您填写的信息不真实、不准确、不合法、不规范，我们有理由怀疑为错误、不实或不合法的资料，则我们有权拒绝为您提供相关服务或您可能无法使用本软件及相关服务或在使用过程中部分功能受到限制。如果您提供的注册资料不合法、不真实、不准确、不详尽的，您需承担因此引起的相应责任及后果，并且那夏保留终止您使用本服务的权利。
				</span>
			</p>
			<p className="p2">
				<span className="s1">4.3 </span>
				<span className="s2">
					用户有义务妥善保管其帐号及密码，并正确、安全地使用其帐号及密码，并对您以服务帐号名义所从事的活动承担全部法律责任，包括但不限于您在本软件进行的任何言论发表、购买、款项支付（如有）等操作行为。用户未尽上述义务导致帐号密码遗失、帐号被盗等情形而给用户和他人的民事权利造成损害的，应当自行承担由此产生的法律责任。
				</span>
			</p>
			<p className="p2">
				<span className="s1">4.4 </span>
				<span className="s2">
					服务帐号的所有权归那夏公司所有，用户完成申请、注册手续后，仅获得该帐号的使用权，且该使用权仅属于初始申请注册人。同时，初始申请注册人不得赠与、借用、租用、转让或售卖帐号或者以其他方式许可非初始申请注册人使用帐号。非初始申请注册人不得通过受赠、继承、承租、受让或者其他任何方式使用帐号。
				</span>
			</p>
			<p className="p2">
				<span className="s1">4.5 </span>
				<span className="s2">
					您理解并承诺，您所设置的帐号不得违反国家法律法规及本软件使用的相关规则。您设置的帐号名称、头像等信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等足以让人引起混淆的方式）开设帐号。您在帐号注册及使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益或有害社会道德风尚的行为。我们有权依据本协议约定或者法律法规的规定对您的账户进行限权或关闭。在限权或关闭账户之前，我们将通知您对账户内的明星潮品进行处置。
				</span>
			</p>
			<p className="p2">
				<span className="s1">4.6 </span>
				<span className="s2">
					如需注销帐号，您应审慎操作并提交注销申请。您仅能申请注销您本人的帐号，并在本软件内按照要求的流程进行注销。注销成功后，帐号涉及的个人信息、使用记录等将无法恢复或提供。
				</span>
			</p>
			<p className="p3">
				<span className="s2"> </span>
			</p>
			<p className="p2">
				<span className="s2">五、【用户个人信息保护】</span>
			</p>
			<p className="p2">
				<span className="s1">5.1 </span>
				<span className="s2">
					保护用户个人信息是那夏的一项基本原则，那夏将按照本协议及《
				</span>
				<span className="s1">yotown</span>
				<span className="s2">
					（柚堂）软件隐私政策》的约定收集、使用、储存和分享您的个人信息。本协议对个人信息保护相关内容未作明确规定的，均应以《
				</span>
				<span className="s1">yotown</span>
				<span className="s2">（柚堂）软件隐私政策》的内容为准。</span>
			</p>
			<p className="p2">
				<span className="s1">5.2 </span>
				<span className="s2">
					那夏不会将您的个人信息转移或披露给任何第三方，除非：
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">1</span>
				<span className="s2">）相关法律法规或司法机关、行政机关要求；或</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">2</span>
				<span className="s2">）为完成合并、分立、收购或资产转让而转移；或</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">3</span>
				<span className="s2">）为提供您要求的服务所必需。</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">4</span>
				<span className="s2">）依据《</span>
				<span className="s1">yotown</span>
				<span className="s2">
					（柚堂）软件隐私政策》或其他相关协议规则可以转移或披露给任何第三方的情形。
				</span>
			</p>
			<p className="p2">
				<span className="s1">5.3 </span>
				<span className="s2">
					为了更好地向您提供本服务，在获得您的明确同意后，那夏可对您服务帐号中的昵称、头像以及在本软件中的相关操作信息（以下称
				</span>
				<span className="s1">“</span>
				<span className="s2">该等信息</span>
				<span className="s1">”</span>
				<span className="s2">
					）进行使用，并可在本软件中向您本人或其他用户展示该等信息。
				</span>
			</p>
			<p className="p2">
				<span className="s1">5.4 </span>
				<span className="s2">
					您在使用本服务某一特定功能时，那夏在获得您明确同意的前提下可以使用您终端设备的相关权限、接口及相关信息等以实现相应的功能。您可以选择不向那夏提供您的相关权限、接口、信息，或者根据产品设置取消那夏使用相关权限、接口或收集某些信息的权利，但因此可能会导致相关服务功能无法实现。
				</span>
			</p>
			<p className="p2">
				<span className="s1">5.5 </span>
				<span className="s2">您应对通过本软件和</span>
				<span className="s1">/</span>
				<span className="s2">
					或本服务了解、接收或可接触到的包括但不限于其他用户在内的任何人的个人信息予以充分尊重，您不应以搜集、复制、存储、传播或以其他任何方式使用其他用户的个人信息，否则，由此产生的后果由您自行承担。
				</span>
			</p>
			<p className="p3">
				<span className="s2"> </span>
			</p>
			<p className="p2">
				<span className="s2">六、【用户行为规范】</span>
			</p>
			<p className="p2">
				<span className="s1">6.1  </span>
				<span className="s2">明星潮品的购买：</span>
			</p>
			<p className="p2">
				<span className="s2">您通过本软件购买或兑换明星潮品，应当是已年满</span>
				<span className="s1">14</span>
				<span className="s2">
					周岁的中国大陆居民，并且已经通过本软件的实名认证流程。
				</span>
			</p>
			<p className="p2">
				<span className="s1">6.2 </span>
				<span className="s2">明星潮品的使用</span>
			</p>
			<p className="p2">
				<span className="s1">6.2.1 </span>
				<span className="s2">
					您有权在本软件上享受明星潮品的浏览、购买、分享、争议处理、订单管理以及明星潮品的访问、欣赏、炫耀、信息查看等信息技术服务，部分明星潮品还可通过本软件合作的应用场景进行使用，具体服务内容和功能以本软件和应用场景页面向您实际展示的为准。
				</span>
				<span className="s1">
					<br />
					    6.2.2 
				</span>
				<span className="s2">
					您可以通过本软件浏览自本第三方本软件的发行方处获取。当您在本软件下单购买前，请您务必仔细确认所购买明星潮品的名称、介绍、发行方、价格、数量及
				</span>
				<span className="s1">/</span>
				<span className="s2">
					或其他限制性要求等重要事项，并对是否购买明星潮品做出独立决策和判断。一旦您提交订单，请在规定时限内完成支付。您支付成功后即视为您与本软件之间就已支付价款部分的订单建立了合同关系。如果您未能在规定时间内完成支付或在支付完成后又退款成功的，我们将回收您订单项下的明星潮品。
				</span>
				<span className="s1">
					<br />
					 <br />
					    6.2.3 
				</span>
				<span className="s2">
					明星潮品的图文展示、介绍、创作者等相关信息均由发行方提供，所有明星潮品的内容和版权由发行方承担责任。您应当对通过本软件接触到的内容及其准确性、完整性和有效性自行审慎判断并承担由此引起的相应风险。除我们存在故意或重大过失外，我们不对您因前述风险而导致的损失或损害承担责任。
				</span>
				<span className="s1">
					<br />
					    6.2.4 
				</span>
				<span className="s2">
					为保障明星潮品发售交易和资金安全，您同意以本软件认可的其他支付方式作为您购买明星潮品的支付工具。您同时授权支付宝或本软件认可的其他支付方式的提供商可以按照我们向您发出的指令为您提供资金结算和支付账户管理等服务。
				</span>
				<span className="s1">
					<br />
					{/*     6.2.5   </span><span className="s2">您理解并同意，明星潮品的版权由发行方或原作创作者拥有。除另行取得版权权利人书面同意外，您不得将明星潮品用于任何商业用途。除本软件规则或本软件功能允许外，您获得的明星潮品应仅用于自身收藏、欣赏和展示目的，不得用于任何其他目的。</span><span className="s1"><br /> */}
					{/*     6.2.6 </span><span className="s2">我们严格禁止您利用明星潮品进行售卖、炒作、场外交易、欺诈或以任何其他非法方式进行使用。我们保留随时根据业务发展和法律法规政策变化进行调整的权利。您认可并同意，为了保障交易安全、有序、合法以及履行我们反洗钱的义务，我们有权对您的区块链地址进行安全查询。如发现存在售卖、炒作、欺诈等异常行为，或您存在违反法律法规或本协议约定等情形，我们有权对您采取本协议第五条约定的必要措施。</span><span className="s1"><br /> */}
					<br />
					    6.3 
				</span>
				<span className="s2">【用户禁止行为】</span>
			</p>
			<p className="p2">
				<span className="s2">您在使用本软件和</span>
				<span className="s1">/</span>
				<span className="s2">
					或本服务的过程中，应遵守相关法律法规、本协议、规则、规范等，不得从事包括但不限于以下任何行为，也不得为以下任何行为提供便利：
				</span>
			</p>
			<p className="p2">
				<span className="s1">6.3.1 </span>
				<span className="s2">法律法规禁止的行为</span>
			</p>
			<p className="p2">
				<span className="s2">
					您在使用本服务时须遵守法律法规，不得制作、复制、发布、传播含有下列内容的信息或从事相关行为，也不得为制作、复制、发布、传播含有下列内容的信息或从事相关行为提供便利：
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">1</span>
				<span className="s2">）反对宪法所确定的基本原则的；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">2</span>
				<span className="s2">
					）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">3</span>
				<span className="s2">）损害国家荣誉和利益的；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">4</span>
				<span className="s2">）煽动民族仇恨、民族歧视，破坏民族团结的；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">5</span>
				<span className="s2">）破坏国家宗教政策，宣扬邪教和封建迷信的；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">6</span>
				<span className="s2">）散布谣言，扰乱社会秩序，破坏社会稳定的；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">7</span>
				<span className="s2">
					）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">8</span>
				<span className="s2">）侮辱或者诽谤他人，侵害他人合法权益的；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">9</span>
				<span className="s2">
					）违反法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的
				</span>
				<span className="s1">“</span>
				<span className="s2">七条底线</span>
				<span className="s1">”</span>
				<span className="s2">要求的；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">10</span>
				<span className="s2">
					）法律法规、本协议或使用本软件的其他规则等禁止的其他行为。
				</span>
			</p>
			<p className="p3">
				<span className="s2">6.3.2 </span>
				<span className="s4">软件使用</span>
			</p>
			<p className="p2">
				<span className="s2">
					除非法律法规允许或那夏书面许可，您不得从事下列行为：
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">1</span>
				<span className="s2">）删除本软件及其副本上关于著作权的信息；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">2</span>
				<span className="s2">
					）对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">3</span>
				<span className="s2">
					）对那夏拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">4</span>
				<span className="s2">
					）对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非那夏经授权的第三方工具
				</span>
				<span className="s1">/</span>
				<span className="s2">服务接入本软件和相关系统；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">5</span>
				<span className="s2">
					）通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">6</span>
				<span className="s2">
					）通过非那夏开发、授权的第三方软件、插件、外挂、系统，登录或使用本软件和
				</span>
				<span className="s1">/</span>
				<span className="s2">或本服务，或制作、发布、传播上述工具；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">7</span>
				<span className="s2">）自行、授权他人或利用第三方软件对本软件和</span>
				<span className="s1">/</span>
				<span className="s2">或本服务及其组件、模块、数据等进行干扰；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">8</span>
				<span className="s2">）其他可能影响、干扰本软件和</span>
				<span className="s1">/</span>
				<span className="s2">或本服务正常运行的行为。</span>
			</p>
			<p className="p3">
				<span className="s2">6.3.3 </span>
				<span className="s4">数据获取使用</span>
			</p>
			<p className="p2">
				<span className="s2">
					为了保护数据安全，您不得从事包括但不限于以下行为，也不得为其提供便利：
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">1</span>
				<span className="s2">
					）未经其他用户明确同意，或在未向其他用户如实披露数据用途、使用范围等相关信息的情形下收集、复制、存储、使用或传输其他用户数据，侵害其他用户合法权益；
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">2</span>
				<span className="s2">
					）将其他用户的帐号、昵称等个人信息用于任何未经用户及那夏授权的用途；
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">3</span>
				<span className="s2">
					）企图进行反射查找、跟踪、关联、挖掘、获取用户帐号、手机号和出生日期等个人信息；
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">4</span>
				<span className="s2">
					）通过各种程序、软件等抓取任何用户的信息或与本软件和
				</span>
				<span className="s1">/</span>
				<span className="s2">或本服务相关的任何信息、数据；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">5</span>
				<span className="s2">
					）未经那夏授权使用任何第三方软件、插件、外挂、系统等查看、获取本软件和
				</span>
				<span className="s1">/</span>
				<span className="s2">
					或本服务中所包含的那夏、那夏合作伙伴或用户的任何相关信息、数据等内容。
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">6</span>
				<span className="s2">）其他危害数据安全的行为。</span>
			</p>
			<p className="p2">
				<span className="s1">6.4 </span>
				<span className="s2">【法律责任】</span>
			</p>
			<p className="p2">
				<span className="s1">6.4.1 </span>
				<span className="s2">您理解并同意：若您违反法律法规、本协议和</span>
				<span className="s1">/</span>
				<span className="s2">
					或那夏其他协议、规则的，那夏有权随时单方根据相应情形采取以下一项或多项措施（具体措施以及采取措施的时间长短由那夏根据您的违法、违约情节相应情况确定）：
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">1</span>
				<span className="s2">）对您进行警告；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">2</span>
				<span className="s2">）删除违法违规信息；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">3</span>
				<span className="s2">）限制您使用本软件和</span>
				<span className="s1">/</span>
				<span className="s2">或本服务部分或全部功能；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">4</span>
				<span className="s2">）中止、终止您对相应服务帐号的使用</span>
				<span className="s1">(</span>
				<span className="s2">简称</span>
				<span className="s1">“</span>
				<span className="s2">封号</span>
				<span className="s1">”) </span>
				<span className="s2">；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">5</span>
				<span className="s2">）采取其他合理、合法的措施；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">6</span>
				<span className="s2">
					）依法移交有关行政管理机关给予行政处罚，或者依法向有关机关报案追究刑事责任。
				</span>
			</p>
			<p className="p2">
				<span className="s1">6.4.2 </span>
				<span className="s2">如果您违反法律法规、本协议和</span>
				<span className="s1">/</span>
				<span className="s2">
					或那夏其他协议、规则，那夏对您或您使用的本软件和
				</span>
				<span className="s1">/</span>
				<span className="s2">
					或本服务采取任何行为或措施后，导致您暂时或永久无法使用服务帐号，您应自行承担相应后果（包括但不限于通信中断、用户资料及相关数据的清空或无法使用等）。由此造成您损失的，您应自行承担。
				</span>
			</p>
			<p className="p2">
				<span className="s1">6.4.3 </span>
				<span className="s2">
					您导致任何第三方损害的，您应当独立承担责任；那夏因此遭受损失的，您也应当一并赔偿。
				</span>
			</p>
			<p className="p2">
				<span className="s1">6.5 </span>
				<span className="s2">【对自己行为负责】</span>
			</p>
			<p className="p2">
				<span className="s2">您充分了解并同意，您必须为自己使用本软件和</span>
				<span className="s1">/</span>
				<span className="s2">
					或本服务的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对使用本软件和
				</span>
				<span className="s1">/</span>
				<span className="s2">
					或本服务时接触到的内容自行加以判断，包括因对内容的正确性、完整性或实用性的依赖而产生的风险，并承担由此引起的相应风险。那夏在法律允许的范围内不对您因前述风险而导致的任何损失或损害承担责任。
				</span>
			</p>
			<p className="p3">
				<span className="s2"> </span>
			</p>
			<p className="p2">
				<span className="s2">七、【知识产权】</span>
			</p>
			<p className="p2">
				<span className="s1">7.1 </span>
				<span className="s2">
					那夏是本软件的知识产权权利人。本软件的著作权、商标权、专利权、商业秘密等知识产权，以及与本软件相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受法律法规和相应的国际条约保护，那夏依法享有上述相关知识产权，但相关权利人依照法律规定应享有的权利除外。
				</span>
			</p>
			<p className="p2">
				<span className="s1">7.2 </span>
				<span className="s2">
					您理解并同意，明星潮品的知识产权由发行方或其他权利人拥有。上述权利并不因您购买明星潮品的行为而发生任何转移或共享。除另行取得拥有明星潮品知识产权的权利人书面同意外，您不得将明星潮品用于任何商业用途。
				</span>
			</p>
			<p className="p2">
				<span className="s2">
					未经那夏或相关权利人书面同意，您不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。
				</span>
			</p>
			<p className="p2">
				<span className="s1">7.3 </span>
				<span className="s2">
					您承诺对您通过本软件所上传的内容（包括但不限于文字、图片、视频、音频及
				</span>
				<span className="s1">/</span>
				<span className="s2">
					或视频、音频中的音乐作品、声音等）拥有完整著作权或已获得合法授权，未侵犯任何第三方之合法权益。
				</span>
			</p>
			<p className="p2">
				<span className="s1">7.4 </span>
				<span className="s2">
					如您发现本软件及相关网站内，或明星潮品存在任何侵犯您权利的内容，请立即通过邮箱
				</span>
				<span className="s1">yotown@yowant.com</span>
				<span className="s2">
					通知那夏，提供您有关权利的初步证据，那夏会根据法律法规要求及时处理您的投诉。
				</span>
			</p>
			<p className="p3">
				<span className="s2"> </span>
			</p>
			<p className="p2">
				<span className="s2">八、【第三方软件或技术】</span>
			</p>
			<p className="p2">
				<span className="s1">8.1 </span>
				<span className="s2">
					本软件可能会使用第三方软件或技术（包括本软件可能使用的开源代码和公共领域代码等，下同），这种使用已经获得合法授权。
				</span>
			</p>
			<p className="p2">
				<span className="s1">8.2 </span>
				<span className="s2">
					本软件如果使用了第三方的软件或技术，那夏将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本软件安装包特定文件夹中打包等形式进行展示，它们可能会以
				</span>
				<span className="s1">“</span>
				<span className="s2">软件使用许可协议</span>
				<span className="s1">”“</span>
				<span className="s2">授权协议</span>
				<span className="s1">”“</span>
				<span className="s2">开源代码许可证</span>
				<span className="s1">”</span>
				<span className="s2">或其他形式来表达。</span>
			</p>
			<p className="p2">
				<span className="s1">8.3 </span>
				<span className="s2">
					前述相关协议或其他文件均是本协议不可分割的组成部分，与本协议具有同等的法律效力，您应当遵守这些要求。如果您没有遵守这些要求，该第三方或者国家机关可能会对您提起诉讼、罚款或采取其他制裁措施，并要求那夏给予协助，您应当自行承担法律责任。
				</span>
			</p>
			<p className="p3">
				<span className="s2"> </span>
			</p>
			<p className="p2">
				<span className="s2">九、【不可抗力及其他免责事由】</span>
			</p>
			<p className="p2">
				<span className="s1">9.1 </span>
				<span className="s2">
					您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务受到影响。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，那夏将努力在第一时间与相关单位配合，争取及时进行处理，但是由此给您造成的损失，那夏在法律允许的范围内免责。
				</span>
			</p>
			<p className="p2">
				<span className="s1">9.2 </span>
				<span className="s2">
					在法律允许的范围内，那夏对以下情形导致的服务中断或受阻不承担责任：
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">1</span>
				<span className="s2">
					）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、电信设备故障、第三方服务瑕疵等原因。
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">2</span>
				<span className="s2">
					）用户或那夏的电脑软件、系统、硬件和通信线路出现故障。
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">3</span>
				<span className="s2">
					）用户操作不当或用户通过非那夏授权的方式使用本服务。
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">4</span>
				<span className="s2">）程序版本过时、设备的老化和</span>
				<span className="s1">/</span>
				<span className="s2">或其兼容性问题。</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">5</span>
				<span className="s2">）</span>
				<span className="s1"> </span>
				<span className="s2">在与本服务相关的系统停机维护或升级期间。</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">6</span>
				<span className="s2">
					）因自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、法律法规政策变化和政府行为等不可抗力原因。
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">7</span>
				<span className="s2">）其他那夏无法控制或合理预见的情形。</span>
			</p>
			<p className="p2">
				<span className="s1">9.3 </span>
				<span className="s2">
					那夏将会尽其商业上的合理努力保障您在本服务中的数据存储安全，但是，那夏并不能就此提供完全保证，包括但不限于以下情形：
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">1</span>
				<span className="s2">）那夏不对由于非那夏原因造成的您在本软件和</span>
				<span className="s1">/</span>
				<span className="s2">或本服务中相关数据的删除或储存失败负责。</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">2</span>
				<span className="s2">）您应自行备份存储在本软件和</span>
				<span className="s1">/</span>
				<span className="s2">或本服务中的数据、信息或与本软件和</span>
				<span className="s1">/</span>
				<span className="s2">
					或本服务相关的数据、信息，双方另有约定的按相应约定执行。
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">3</span>
				<span className="s2">）如果您停止使用本软件和</span>
				<span className="s1">/</span>
				<span className="s2">
					或本服务，或因您违反法律法规或本协议而被取消或终止使用本服务，那夏有权从服务器上永久地删除您的数据。您的服务停止、终止或取消后，那夏没有义务向您返还任何数据。
				</span>
			</p>
			<p className="p2">
				<span className="s1">9.4 </span>
				<span className="s2">
					您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，那夏不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">1</span>
				<span className="s2">
					）来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息。
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">2</span>
				<span className="s2">
					）遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失。
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">3</span>
				<span className="s2">）其他因网络信息或用户行为引起的风险。</span>
			</p>
			<p className="p2">
				<span className="s1">9.5 </span>
				<span className="s2">您注意勿在使用本软件和</span>
				<span className="s1">/</span>
				<span className="s2">
					或本服务中透露自己的各类财产账户、银行卡、信用卡、第三方支付账户及对应密码等重要资料，否则由此带来的任何损失由您自行承担。您亦不应将其他个人信息通过本软件发表、上传或扩散。
				</span>
			</p>
			<p className="p2">
				<span className="s1">9.6 </span>
				<span className="s2">
					您理解并同意：为了向您提供有效的服务，本软件会利用您终端设备的处理器和带宽等资源。本软件使用过程中可能产生数据流量的费用，用户需自行向运营商了解相关资费信息，并自行承担相关费用。
				</span>
			</p>
			<p className="p2">
				<span className="s1">9.7 </span>
				<span className="s2">
					基于用户体验、本软件或相关服务的运营安全、规则要求及软件健康发展等综合因素，那夏有权选择提供服务的对象，决定功能设置，决定功能开放、数据接口和相关数据披露的对象和范围。针对以下情形，那夏有权视具体情况中止或终止提供相关服务，包括但不限于：
				</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">1</span>
				<span className="s2">）违反法律法规或本协议的；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">2</span>
				<span className="s2">）影响服务体验的；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">3</span>
				<span className="s2">）存在安全隐患的；</span>
			</p>
			<p className="p2">
				<span className="s2">（</span>
				<span className="s1">4</span>
				<span className="s2">
					）违背本软件或本服务运营原则、规范，或不符合那夏其他管理要求的。
				</span>
			</p>
			<p className="p3">
				<span className="s2"> </span>
			</p>
			<p className="p2">
				<span className="s2">十、【其他】</span>
			</p>
			<p className="p2">
				<span className="s1">10.1 </span>
				<span className="s2">
					那夏有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的协议条款。本协议条款变更后，如果您继续使用那夏提供的软件或服务，即视为您已接受变更后的协议。
				</span>
			</p>
			<p className="p2">
				<span className="s1">10.2 </span>
				<span className="s2">
					为进一步改善用户体验，我们将联合我们的关联方和
				</span>
				<span className="s1">/</span>
				<span className="s2">
					或合作方持续更新服务，包括但不限于版本升级、功能升级、技术升级、新增、更新或调整服务内容和功能，本协议可能会随之更新。我们也会根据国家法律法规的变化要求，更新本协议的相关条款。我们会以公告、客户端信息推送、发送系统消息等通知方式（统称
				</span>
				<span className="s1">“</span>
				<span className="s2">通知</span>
				<span className="s1">”</span>
				<span className="s2">
					）就协议的更新向您进行提示。除通知中另有说明外，更新的内容将于协议更新公告公布的生效之日起生效。若您不接受变更后的服务内容或功能，或不同意更新后的协议内容，您需立即停止使用相关服务。若您继续使用本协议项下相关服务的，即视为您已同意我们作出的变更，并自愿接受变更后的协议内容约束；双方协商一致，也可另行变更相关服务和对应条款内容。
				</span>
			</p>
			<p className="p2">
				<span className="s1">10.3 </span>
				<span className="s2">
					您理解并同意，出于本服务的业务特性，未经我们书面同意，您不得向第三方转让本协议项下的权利和义务。您进一步同意，在不对您使用本服务造成不利影响的前提下，我们可将在本协议项下的权利和义务全部或部分转让给我们的关联方而无需另行通知或征得您的同意。
				</span>
			</p>
			<p className="p2">
				<span className="s1">10.4 </span>
				<span className="s2">
					本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。本协议签订地为中华人民共和国浙江省杭州市余杭区。若您和那夏之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地（即浙江省杭州市余杭区）有管辖权的人民法院管辖。
				</span>
			</p>
			<p className="p2">
				<span className="s1">10.5 </span>
				<span className="s2">
					本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
				</span>
			</p>
			<p className="p2">
				<span className="s1">10.6 </span>
				<span className="s2">
					本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
				</span>
			</p>
		</div>
	)
}

export default Test1
