import React, { useState } from 'react'
import MaskWeixin from '@components/MaskWeixin'
import { useDownloadJump } from '@components/downloadApp/useDownloadJump'
import { isInWeixin } from '@utils/common'
import './index.less'
import { useHeight } from './useHeight'
import Youtangbg from '../components/youtangbg'
import GoodsSwiper from '../components/goodsSwiper'

type Props = {}

export const Main = (props: Props) => {
	const { downloadJump } = useDownloadJump()
	const [inWx, setInWx] = useState(false)
	const [height] = useHeight(1920 / 1080)

	const onDownload = () => {
		const bol = isInWeixin()
		if (bol) {
			setInWx(bol)
		} else {
			downloadJump()
		}
	}

	return (
		<div className="Main-wrap-h5">
			<div className="video">
				<video
					className="resizelistener"
					loop
					muted
					autoPlay
					playsInline={true}
					webkit-playsinline="true"
					x5-playsinline="true"
					x5-video-player-type="h5-page"
				>
					<source
						src="https://daichongtest.oss-cn-hangzhou.aliyuncs.com/marketing/file/2022/09/57ee88d2-5dc6-456f-8667-e3c61c49f57a.mp4"
						type="video/mp4"
					/>
				</video>
			</div>
			<div className="top" style={{ height }}>
				<div className="icon" />
				<div className="desc">逛柚堂，玩潮流</div>
				<div className="download" onClick={onDownload}>
					<div className="download-icon" />
					下载客户端
				</div>
			</div>
			<div className="goods-wrap">
				<Youtangbg title="热卖中" style={{ margin: '64px auto 12px' }} />
				<div className="desc">我们保证您的独一无二，不可复刻</div>

				<GoodsSwiper onClick={onDownload} />
			</div>
			<div className="other-wrap">
				<Youtangbg title="品质保证" />
				<div className="list">
					<div className="listItem">
						<div className="icon icon-1"></div>
						<div className="text">IP独家授权</div>
					</div>
					<div className="listItem">
						<div className="icon icon-2"></div>
						<div className="text">无理由退换货</div>
					</div>
					<div className="listItem">
						<div className="icon icon-3"></div>
						<div className="text">顶级工艺</div>
					</div>
					<div className="listItem">
						<div className="icon icon-4"></div>
						<div className="text">设计师包装</div>
					</div>
				</div>
			</div>
			{inWx && (
				<MaskWeixin
					onClick={() => {
						setInWx(false)
					}}
				/>
			)}
		</div>
	)
}
