import { useEffect, useRef } from 'react'
import { uDownloadAction } from '@utils/common'
import API from '@service/index'
import { CLICK_TRACK } from '@track/index'

export const useDownloadJump = () => {
	const dataRef = useRef<any>({})

	const getDownloadUrl = async () => {
		const res = await API.id55786()
		if (res) {
			dataRef.current = res
		}
	}

	const downloadJump = () => {
		CLICK_TRACK(21001)
		uDownloadAction(dataRef.current)
	}

	useEffect(() => {
		getDownloadUrl()
	}, [])

	return {
		downloadJump,
	}
}
