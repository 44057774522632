import React, { useEffect, useRef, useState } from 'react'
import {
	Button,
	Form,
	InfiniteScroll,
	Input,
	Popup,
	PullToRefresh,
	SafeArea,
} from 'antd-mobile'
import BackButton from '@components/backButton'
import { Toast } from 'antd-mobile/2x'
import './style/index.less'
import useJsBridge from '@utils/useJsBridge'
import API from '@service/index'
const initPageConfig = { pageIndex: 1, pageSize: 30, totalNumber: 0 }
export default ({ history }: any) => {
	const [info, setInfo] = useState<any>({})
	const {
		bridgePostToken,
		bridgePostToPage,
		bridgePostAppBar,
		bridgeShare,
	} = useJsBridge({
		routeBack: () => {
			routeBack()
		},
	})
	const getInfoApi = API.id50716
	const getPagesApi = API.id56362
	const getShareApi = API.id54181

	const [hasMore, setHasMore] = useState(false)
	const pageRef = useRef<{
		pageIndex: any
		pageSize: any
		totalNumber: number
	}>({ ...initPageConfig })
	const [friends, setFriends] = useState<any[]>([])
	const [popVisible, setPopVisible] = useState(false)
	const [popType, setPopType] = useState('code')
	//受邀好友 我的邀请人
	const [isSelected, setIsSelected] = useState<boolean>(true)
	useEffect(() => {
		getNativeFunc()
		// getInfo()
	}, [])
	const getNativeFunc = async () => {
		if (window['YoTown']) {
			bridgePostAppBar({ hideAppBar: true })
			try {
				const res = await bridgePostToken()
				const token = res.result?.data
				if (token) {
					localStorage.setItem('token', token)
					getInfo()
				}
			} catch (error) { }
		}
		// localStorage.setItem("token", 'eyJUeXBlIjoiSnd0IiwidHlwIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJ1SWQiOiJVTjM1OTA4MTEyOTc3Nzk0MjUyOCIsIm5iZiI6MTY1ODExMTA4NiwiaXNzIjoibHZhIiwiZXhwIjoxNjYwNzAzMDg2LCJpYXQiOjE2NTgxMTEwODZ9.sAu7_4SmTzfH72fz8gswHs3bvrnQq63xtU1Ws1XUZHQ');
		// localStorage.setItem("env",'test');
		// getInfo();
	}
	const getInfo = async () => {
		const res = await getInfoApi()
		if (res) {
			if (res.invitedUsers?.length > 5) {
				const [a, b, c, d, e] = res.invitedUsers
				res.invitedUsers = [a, b, c, d, e]
			}
			if (res.products?.length > 3) {
				const [a, b, c] = res.products
				res.products = [a, b, c]
			}
			setInfo(res)
		}
		return Promise.resolve(res)
	}
	const getPageList = async () => {
		let { pageIndex, pageSize, totalNumber } = pageRef.current
		const res = await getPagesApi({ pageIndex, pageSize })
		if (res) {
			if (pageIndex === 1) {
				setFriends(res.list)
				setHasMore(res.list?.length < res.totalNumber)
			} else {
				const list = [...friends, ...res.list]
				setFriends(list)
				setHasMore(list.length < res.totalNumber && res.list.length > 0)
			}
			pageIndex = pageIndex + 1
			pageRef.current.pageIndex = pageIndex
			pageRef.current.totalNumber = res.totalNumber
		}
		return Promise.resolve()
	}
	const onCode = () => {
		if (info.inviteCodes?.length) {
			setPopVisible(true)
			setPopType('code')
		}
	}
	const onFriends = () => {
		setPopVisible(true)
		pageRef.current.pageIndex = 1
		pageRef.current.totalNumber = 0
		getPageList()
		setPopType('firend')
	}
	const routeBack = () => {
		getInfo()
	}
	const goExchange = (productNo?: any) => {
		bridgePostToPage('/mine/exchange_center', { productNo })
	}
	const shareAction = async () => {
		Toast.show({
			icon: 'loading',
			content: '准备中',
			duration: 0,
		})
		const res = await getShareApi({ shareType: '4' })
		if (res) {
			const url =
				res.jumpUrl +
				`${res.jumpUrl?.indexOf('?') === -1 ? '?' : '&'}inviteCode=${info.inviteCode
				}`
			bridgeShare(res.title, url, res.shareDesc, res.thumb)
		}
		Toast.clear()
	}
	const getGoodsView = () => {
		return (
			<div className="goodsView">
				{info.products?.map((item: any) => {
					return (
						<div className="item" key={item.productNo}>
							<img src={item.previewImgUrl} />
							<div className="name">{item.title || '-'}</div>
							<div className="need">
								潮气 <span>{info.pointsAvailableNum || '0'}</span>/
								{item.points || ' -'}
							</div>
							<Button
								onClick={() => {
									goExchange(item.productNo)
								}}
							>
								去兑换
							</Button>
						</div>
					)
				})}
			</div>
		)
	}

	const getTable = (firends: any[], className?: string, needMore = true) => {
		return (
			<div className={`table ${className || ''}`}>
				<table>
					<tbody>
						<tr className="title">
							<th>被邀请人</th>
							<th>邀请码</th>
							<th>注册时间</th>
							<th>激活状态</th>
							<th>潮气值</th>
						</tr>

						{firends.map((item: any, index: number) => {
							return (
								<tr key={index}>
									<td>{item.nickName}</td>
									<td>{item.inviteCode}</td>
									<td>{item.registAt}</td>
									<td  >
										{item.activateStatusDesc}
									</td>
									{
										//是否能被唤醒 
										<td className={((item?.activateStatus && item?.awardIssuedStatus) || item.awakenable) && 'chaoColor'}>
											{
												item?.activateStatus ? item?.awardIssuedStatus ? `+${item.points}` : '/' :
													item.awakenable ?
														<button
															className='isAwaken'
															onClick={async () => {
																const res = await API.id60994({
																	invitedUId: item?.uId
																})
																if (res) {
																	getNativeFunc()
																	Toast.show({
																		content: <div
																			style={{
																				fontSize: '20px'
																			}}
																		>唤醒成功</div>,
																	})
																} else {
																	Toast.show({
																		content: <div
																			style={{
																				fontSize: '20px'
																			}}>唤醒失败</div>,
																	})
																}
															}}
														>唤醒</button> :
														item?.awardIssuedStatus ? `+${item.points}` : '/'

											}
										</td>
									}
									{/* <td className={item.awardIssuedStatus ? 'chaoColor' : ''}>
										{item.awardIssuedStatus ? `+${item.points}` : '/'}
									</td> */}
								</tr>
							)
						})}
					</tbody>
				</table>
				{
					needMore && (
						<InfiniteScroll loadMore={getPageList} hasMore={hasMore} />
					)
				}
			</div >
		)
	}

	const getFriends = () => {
		return (
			<div className="friendView">
				<div className="header-view">
					<div className="title1" />
					<div
						className="popclose"
						onClick={() => {
							setPopVisible(false)
						}}
					/>
				</div>
				<div className="tableview">
					{getTable(friends)}
					<SafeArea position="bottom" />
				</div>
			</div>
		)
	}
	const getMyCode = () => {
		return (
			<div className="mycodeView">
				<div className="header-view">
					<div className="title1" />
					<div className="title2">共{info.inviteCodes?.length}个</div>
					<div
						className="popclose"
						onClick={() => {
							setPopVisible(false)
						}}
					/>
				</div>
				<div className="table1">
					<table>
						<tbody>
							<tr className="title">
								<th>邀请码</th>
								<th>使用状态</th>
							</tr>
							{info.inviteCodes?.map((item: any) => {
								return (
									<tr key={item.inviteCode}>
										<td>{item.inviteCode}</td>
										<td className={item.verifyStatus && 'chaoColor'}>
											{item.verifyStatus ? '已使用' : '未使用'}
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
					<SafeArea position="bottom" />
				</div>
				<div className="floatView" aria-hidden>
					<div className="buttonview">
						<Button className="inviteButton" onClick={shareAction}>
							立即邀请
						</Button>
					</div>
				</div>
			</div>
		)
	}
	//邀请tab
	const invitedTabChange = (key: boolean) => {
		setIsSelected(key)
	}
	return (
		<PullToRefresh onRefresh={getInfo}>
			<div className="inviteView">
				<BackButton routeBack={routeBack} />
				<div className="first">
					<div
						className={`code ${info.inviteCodes?.length > 0 ? 'codes' : ''}`}
						onClick={onCode}
					>
						{info.inviteCode || '-'}
					</div>
					<div className="codeCount">
						我的邀请码{' '}
						{info.inviteCodes?.length > 0
							? `(${info.inviteCodes?.length}个)`
							: ''}
					</div>
					<Button className="inviteButton bTop" onClick={shareAction}>
						立即邀请
					</Button>
					<div className="centertitle">
						<div className="title">藏品兑换</div>
						<div
							className="purpleView"
							onClick={() => {
								goExchange()
							}}
						>
							前往兑换中心
						</div>
					</div>
					{getGoodsView()}
				</div>
				<div className="second">
					<div className='button-group-tag'>
						<div
							className={isSelected ? 'select-tab' : 'none-selected'}
							onClick={() => {
								//invited
								invitedTabChange(true)
							}}>受邀好友</div>
						<div
							className={!isSelected ? 'select-tab' : 'none-selected'}
							onClick={() => {
								//invitation
								invitedTabChange(false)
							}}>我的邀请人</div>
					</div>
					{isSelected && !info.invitedUsers?.length && (
						<div className="firendNone">
							<div></div>
							<div>空空如也，快去邀请好友吧～</div>
						</div>
					)}
					{isSelected && info.invitedUsers?.length > 0 &&
						getTable(info.invitedUsers, 'tableTop', false)}
					{isSelected && info.invitedUsers?.length >= 5 && (
						<div className="purpleView" onClick={onFriends}>
							查看全部
						</div>
					)}
					{
						!isSelected ?
							info.inviteUId ? (
								<>
									<img src={info.inviteHeadImg} className='userImg' />
									<div className="nick">{info.inviteNickName}</div>
								</>
							) : (
								<>
									<div className="imgDefault"></div>
									<div
										className="purple"
										onClick={() => {
											bridgePostToPage('/login/invite', {})
										}}
									>
										未绑定，去绑定
									</div>
								</>
							) : null
					}

				</div>
				{/* <div className="third">
					{info.inviteUId ? (
						<>
							<img src={info.inviteHeadImg} />
							<div className="nick">{info.inviteNickName}</div>
						</>
					) : (
						<>
							<div className="imgDefault"></div>
							<div
								className="purple"
								onClick={() => {
									bridgePostToPage('/login/invite', {})
								}}
							>
								未绑定，去绑定
							</div>
						</>
					)}
				</div> */}

				<Popup
					visible={popVisible}
					onMaskClick={() => {
						setPopVisible(false)
					}}
					key="code"
					getContainer={null}
					bodyStyle={{
						borderTopLeftRadius: '18px',
						borderTopRightRadius: '18px',
					}}
				>
					{popType === 'code' && getMyCode()}
					{popType === 'firend' && getFriends()}
				</Popup>
			</div>
		</PullToRefresh>
	)
}
