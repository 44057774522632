
      /**
      * 换取token
      * 
      * /app/login/refreshToken
      **/
      
    import request from '../utils/request.ts'
    import {getHost,reduceParams,querystring} from '@ywfe/utils'
    const {basePath = '', loginPath = ''} = getHost('COMMON', '455')
    export default (inputs={},config={}) => request['get'](`${basePath}/nft-biz/app/login/refreshToken?${querystring.stringify(reduceParams(inputs))}`, {loginHost: loginPath,...config})