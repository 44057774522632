import React from "react";
import useJsBridge from '@utils/useJsBridge'
import API from '@service/index'
import { Toast } from 'antd-mobile'
import './style.less'

type Props = {
  visible: boolean;
  initData: () => void;
  history: any;
  upValue: any;
  inviteCode: string;
}

const ProbabilityModel = (props: Props) => {
  const { visible, initData, history, upValue, inviteCode } = props
  const {
    bridgeShare
  } = useJsBridge()
  return visible && <div
    // onClick={() => {
    //   initData();
    // }}
    className="probabilityModel"
  >
    <div className="model-container">
      <img
        src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/successModelBackGround.png"
        alt=""
        className="modal-background"
      />
      <img
        src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/close-icon.png"
        alt=""
        className="close"
        onClick={() => {
          initData()
        }} />
      <div className="model-inner">
        <img
          src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/promotionSuccess.png"
          alt=""
          className="success"
        />
        <img src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/coloured-ribbon.png" alt="" className="coloured-ribbon" />
        <div className="up-gailv">
          <img
            src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/active-nostart.png"
            alt=""
            className="goods-img" />
          <div className="probability">
            <div className="probability-num">
              <img
                src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/up.png"
                alt=""
                className="value-up" />
              <div className="value-num">{upValue?.winAwardProbabilityUp}</div>
            </div>
            <div className="probability-inner">
              <div className="probability-text">当前中奖加成：</div>
              <div className="probability-inner-num">{upValue?.totalWinAwardProbabilityUp}</div>
            </div>
          </div>
        </div>
        <div className="active-context">
          活动期间邀请满20人可额外获得2000潮气值！
        </div>
        <img src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/more-num.png" alt="" className="has-more"
          onClick={async () => {
            initData()
            Toast.show({
              icon: 'loading',
              content: '准备中',
              duration: 0,
            })
            const res = await API.id54181({
              shareType: '6'
            })
            if (res) {
              const url =
                res.jumpUrl +
                `${res.jumpUrl?.indexOf('?') === -1 ? '?' : '&'}inviteCode=${inviteCode
                }`
              bridgeShare(res.title, url, res.shareDesc, res.thumb)
            }
            Toast.clear()
          }} />
      </div>
    </div>
  </div>
}

export default ProbabilityModel