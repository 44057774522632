import React from 'react'
import './index.less'
type Props = {
	title: string
	style?: React.CSSProperties
}

const Youtangbg = (props: Props) => {
	const { title, style } = props
	return (
		<div className="youtangbg" style={style}>
			{title}
		</div>
	)
}
export default Youtangbg
