import React from 'react'
import './style/index.less'
const Test2 = () => {
	return (
		<div className="test2">
			<p className="center">
				<strong>
					<span className="ft32">柚堂App隐私权政策</span>
				</strong>
			</p>
			<p className="justify">
				<span className="ft24">&nbsp;</span>
			</p>
			<p className="justify">
				<span className="ft24">更新日期：【 2022 】年【 9 】月【 15 】日</span>
			</p>
			<p className="justify">
				<span className="ft24">&nbsp;</span>
			</p>
			<p className="justify">
				<span className="ft24">
					本政策适用于【杭州那夏网络科技有限公司】（“我们”或“公司”）提供的【柚堂App】及相关服务（“本产品”），旨在说明在您使用本产品时，我们将如何处理您的个人信息，以及您对这些个人信息享有的权利。
				</span>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p className="justify">
				<strong className="corlor51">
					<span className="ft24">
						请仔细阅读本政策并确定理解我们对您个人信息的处理规则。阅读过程中，如对本政策内容有任何疑问、意见或建议，您可按照本政策中提供的联系方式与我们联系。如您不理解或不同意本政策中的任何条款，您应立即停止访问或使用本产品。一旦您开始使用本产品的任何模块或服务/完成注册/登录，即表示您已充分理解并同意本政策。
					</span>
				</strong>
			</p>
			<p className="justify">
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">
					我们提供的产品可能包含跳转至其他第三方产品或服务（“第三方”，包括但不限于您的交易相对方、任何第三方网站、第三方服务提供者）的链接或指引（如二维码）。
				</span>
				<strong className="corlor51">
					<span className="ft24">
						请注意，我们不控制第三方，本政策在任何情况下均不适用于第三方。第三方对您个人信息的收集与使用将遵守第三方的个人信息保护政策。我们建议您在访问第三方时仔细阅读并了解他们的个人信息政策。
					</span>
				</strong>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">本政策将帮助您了解以下内容：</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					一.&nbsp;我们如何收集和使用您的个人信息
				</span>
			</p>
			<p>
				<br />
			</p>
			<p>
				<span className="ft24 corlor51">
					二.&nbsp;我们如何提供、公开披露您的个人信息
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					三.&nbsp;我们如何存储和保护您的个人信息
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">四.&nbsp;您的权利</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					五.&nbsp;我们如何处理儿童的个人信息
				</span>
			</p>
			<p>
				<br />
			</p>
			<p>
				<span className="ft24 corlor51">
					六.&nbsp;我们如何使用Cookie和同类技术
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">七.&nbsp;本政策如何更新</span>
			</p>
			<p>
				<span className="ft24 corlor51">八.&nbsp;如何联系我们</span>
			</p>
			<p>
				<span className="ft24 corlor51">九.&nbsp;权限申请及应用场景</span>
			</p>
			<p>
				<br />
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">一.&nbsp;我们如何收集和使用您的个人信息</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">1.&nbsp;注册和登录</span>
				</strong>
			</p>
			<p>
				<br />
			</p>
			<p className="justify">
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">
					（1）当您注册本产品用户账号时，您需要至少向我们提供您的手机号码，如果您拒绝提供上述信息，您将无法注册本产品账号，仅可以使用浏览服务。为了确保我们是在为您本人提供服务，我们可能会根据您提供的上述信息校验您的身份，例如通过短信验证码等方式校验您的身份。
				</span>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">
					在您购买明星联名潮款和周边商品之前，我们会收集您的姓名、身份证件信息。此外，手机号码和身份证件信息属于敏感信息，收集此类信息是为了满足相关法律法规的网络实名制要求。
				</span>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">
					（2）为了完成账号注册，我们会收集您为账号设置的头像、昵称、生日以及您主动提供的其他资料信息。为了更好地向您提供本服务，我们可对上述昵称、头像以及在您本软件中的相关操作信息进行使用，并向您本人或其他用户展示该等信息。
				</span>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">2.&nbsp;订单管理</span>
				</strong>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">
					当您在我们的产品或服务中订购明星联名潮款或其他商品时，我们会通过系统为您生成购买该商品的订单、交易记录，并在应用中向您展示。当您购买具体实物商品时，在下单过程中，您至少需要提供您的收货人姓名、收货地址、收货人联系电话。
				</span>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">3.&nbsp;参加用户活动</span>
				</strong>
			</p>
			<p>
				<br />
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">
					我们或我们的关联公司可能会不定期组织用户活动，为使您了解相关活动信息，我们可能会使用您的手机号码，通过社交媒体、手机短信、邮件、站内推送等通讯形式，通知您相关活动信息。
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">
					我们可能对前述用户活动的全程进行照片或者视频的拍摄，照片及视频将会被用于我们的推广宣传用途。如您参加了我们的用户活动，我们将理解您许可我们采集及使用您参与活动的影像等信息并同意我们用作推广宣传用途。
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">4.&nbsp;消息通知</span>
				</strong>
			</p>
			<p>
				<br />
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">
					在您开启通知权限的前提下，我们可能向您推送订单履约信息（如：中签提醒、订单发货提醒、自动确认收货提醒）、活动信息（如：预约提醒、邀请成功提醒、好友激活提醒）等，如您不希望收到上述信息，您可以在手机系统设置中关闭柚堂通知权限。
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">5.&nbsp;提供客户服务</span>
				</strong>
			</p>
			<p>
				<br />
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">
					当您需要我们提供客户服务时，您有可能会在与我们客服人员沟通时，向我们提供并允许我们使用您的账号信息、订单信息或其他您主动提供的信息。为保障您的合法权益，保证我们的客户服务合法合规，并持续改进我们的服务能力，我们会留存您与客服之间的沟通记录。拒绝我们进行前述信息处理，会使您无法使用我们的客户服务，但这不会影响您正常使用本产品的其他功能或服务。
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">6.&nbsp;安全保障</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<br />
			</p>
			<p className="justify">
				<span className="ft24 corlor51">
					为保障您的账号安全，保障您正常使用我们的服务，维护我们服务的正常运行，并改进及优化我们的服务体验，我们会收集您的：设备型号、操作系统、唯一设备标识符（指由设备制造商编入到设备中的一串字符，可用于以独有方式标识相应设备，Android如IMEI、AndroidID、OAID、IMSI、ICCID、GAID、MEID、AAID、VAID，iOS如IDFV、IDFA）、MAC地址、登录IP地址、软件版本号、接入网络的方式、类型和状态、操作日志、服务日志、运行中的进程信息，这类信息是为提供服务必须收集的基础信息。
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">7.&nbsp;改进我们的产品或服务</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">
					我们可能会对您使用本产品相关功能或服务时产生的操作日志，进行去标识化地研究、统计分析，用于改善本产品交互界面的内容和布局，为商业决策提供支撑。同时，为了更准确高效解决您提出的问题，我们将使用您的操作日志进行问题溯源。
				</span>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">
						8.&nbsp;请您知悉，以下情形中，我们收集、使用个人信息无需征得您的授权同意：
					</span>
				</strong>
			</p>
			<p>
				<br />
			</p>
			<p>
				<span className="ft24 corlor51">
					a．&nbsp;与我们履行法律、法规规定的义务相关的；
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					b．&nbsp;与国家安全、国防安全有关的；
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					c．&nbsp;与公共安全、公共卫生、重大公共利益有关的；
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					d．&nbsp;与犯罪侦查、起诉、审判和判决执行等有关的；
				</span>
			</p>
			<p>
				<br />
			</p>
			<p>
				<span className="ft24 corlor51">
					e．&nbsp;出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您同意的；
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					f．&nbsp;所收集的个人信息是您自行向社会公众公开的；
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					g．&nbsp;从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					h．&nbsp;根据与您签订和履行相关协议或其他书面文件所必需的；
				</span>
			</p>
			<p>
				<br />
			</p>
			<p>
				<span className="ft24 corlor51">
					i．&nbsp;用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">j．&nbsp;法律法规规定的其他情形。</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">9.&nbsp;</span>
				</strong>
				<span className="ft24 corlor51">
					为了方便您快速了解我们收集您的个人信息的情况，我们准备了
					<a href="/agreement?type=userInfo">【个人信息收集清单】</a>。
				</span>
			</p>
			<p>
				<br />
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">
						二.&nbsp;我们如何提供、公开披露您的个人信息
					</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">（一）&nbsp;向第三方提供</span>
				</strong>
			</p>
			<p>
				<br />
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					1.&nbsp;为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多服务和功能，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序。与第三方合作过程中，我们将审慎评估合作方使用数据的目的，对这些合作方的安全保障能力进行综合评估，同时我们将要求合作方对其提供的会获取信息的工具开发包（SDK）、应用程序接口（API）进行严格安全审查，以保护数据安全。
					<a href="/agreement?type=third">【第三方信息共享清单】</a>
				</span>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">
						2.&nbsp;随着我们业务的持续发展，我们有可能进行合并、收购、资产转让等交易，如涉及转移个人信息，我们将按照法律法规及不低于本政策所要求的标准，要求持有您个人信息的新主体继续保护您的个人信息，否则我们将要求其重新向您征求授权同意。
					</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<br />
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">（二）&nbsp;公开披露</span>
				</strong>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">
					我们仅会在获得您明确同意的情况下，或基于您的主动选择，公开披露您的个人信息。
				</span>
			</p>
			<p className="justify">
				<span className="ft24">&nbsp;</span>
			</p>
			<p>
				<strong>
					<span className="ft24">
						（三）&nbsp;提供、公开披露个人信息时事先征得授权同意的例外
					</span>
				</strong>
			</p>
			<p>
				<strong>
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<span className="ft24">
					1.&nbsp;根据相关法律法规规定，以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
				</span>
			</p>
			<p>
				<br />
			</p>
			<p>
				<span className="ft24">&nbsp;</span>
			</p>
			<p>
				<span className="ft24">
					a．&nbsp;与我们履行法律法规规定的义务相关的；
				</span>
			</p>
			<p>
				<span className="ft24">b．&nbsp;与国家安全、国防安全直接相关的；</span>
			</p>
			<p>
				<span className="ft24">
					c．&nbsp;与公共安全、公共卫生、重大公共利益直接相关的；
				</span>
			</p>
			<p>
				<br />
			</p>
			<p>
				<span className="ft24">
					d．&nbsp;与犯罪侦查、起诉、审判和判决执行等司法或行政执法直接相关的；
				</span>
			</p>
			<p>
				<span className="ft24">
					e．&nbsp;出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
				</span>
			</p>
			<p>
				<span className="ft24">f．&nbsp;您自行向社会公众公开的个人信息；</span>
			</p>
			<p>
				<span className="ft24">
					g．&nbsp;从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
				</span>
			</p>
			<p>
				<br />
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<span className="ft24">
					2.&nbsp;请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。
				</span>
			</p>
			<p className="justify">
				<span className="ft24">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">三.&nbsp;我们如何存储和保护您的个人信息</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<br />
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">（一）&nbsp;存储您的个人信息</span>
				</strong>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">1.&nbsp;存储地域范围</span>
				</strong>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<br />
			</p>
			<p>
				<span className="ft24 corlor51">
					我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内，但以下情形除外：(1)法律法规有明确规定的；(2)依法征得您的同意。在上述除外情形中，我们会并要求数据接收方按照本政策以及其他相关的安全保密措施来处理个人信息。
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">2.&nbsp;存储期限</span>
				</strong>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<br />
			</p>
			<p>
				<span className="ft24 corlor51">
					存储地点：我们将遵守法律法规规定，在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。目前，我们不会将您的个人信息传输至中华人民共和国境外，如因业务拓展等原因需要向境外传输，我们将会遵循相关国家规定并取得您的同意。
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					存储时间：我们仅在为提供柚堂App服务之目的所必需的期间内保留您的个人信息，在您未撤回、删除或未注销账号期间，我们会保留相关信息。超出必要期限或您撤回授权、删除、注销账号后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<br />
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">（二）&nbsp;保护您个人信息的措施</span>
				</strong>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					1.&nbsp;我们非常重视个人信息安全，并采取符合行业标准的安全技术措施保护您提供的个人信息的安全。
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<br />
			</p>
			<p>
				<span className="ft24 corlor51">
					2.&nbsp;我们通过建立专门的管理制度、流程、组织等管理措施以保障信息的安全。例如，我们使用客户信息集中管理系统，我们只允许有必要接触客户信息的工作人员访问该系统查询和管理客户信息。我们严格设定访问客户信息的权限分级，同时要求可能接触到您个人信息的所有人员履行相应的保密义务。我们还不时对工作人员培训相关法律法规及隐私安全准则，强化宣导安全意识。
				</span>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					3.&nbsp;若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大。我们会及时将事件的基本情况、处置措施、降低风险建议等以电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
				</span>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					4.&nbsp;互联网环境并非绝对安全，为保障您的个人信息安全，需要您和我们的共同配合，我们强烈建议您通过安全方式、使用复杂密码，
				</span>
				<strong className="corlor51">
					<span className="ft24">
						请您不要向他人泄露登录账号和密码，如您发现自己的个人信息尤其是您的账号或密码发生泄露时，请您立即根据本政策中提供的联系方式联络我们，以便我们根据您的申请采取相应合理措施。
					</span>
				</strong>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<br />
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">四.&nbsp;您的权利</span>
				</strong>
			</p>
			<p>
				<span className="ft24">&nbsp;</span>
			</p>
			<p>
				<strong>
					<span className="ft24">查阅、更正、补充您的个人信息</span>
				</strong>
			</p>
			<p>
				<strong>
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<span className="ft24">您可以通过“【设置】</span>
				<span className="ft24 corlor51">
					”，查阅、更新或补充昵称、生日、手机号码、交易记录、收货地址等个人信息。
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					您发现我们持有的您的其他个人信息不准确或者不完整的，也可以通过本政策
				</span>
				<span className="ft24">第八节</span>
				<span className="ft24 corlor51">
					提供的联系方式，向我们提出更正、补充的请求。
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">复制您的个人信息</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">
						如果您需要复制我们收集存储的个人信息，您可以通过本政策第八节提供的联系方式，请求查阅、复制您提交给我们的个人信息。
					</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">删除您的个人信息</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<span className="ft24 corlor51">在以下情形中，您可以通过本政策</span>
				<span className="ft24">第八节</span>
				<span className="ft24 corlor51">
					提供的联系方式，向我们提出删除个人信息的请求：
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					a．&nbsp;如果我们处理个人信息的行为违反法律法规；
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					b．&nbsp;如果我们收集、使用您的个人信息，却未征得您的同意；
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					c．&nbsp;如果我们处理个人信息的行为违反了与您的约定；
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					d．&nbsp;如果您不再使用我们的产品或服务，或您注销了账号；
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					e．&nbsp;如果我们不再为您提供产品或服务。
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">
						经我们审查确认，您的删除请求符合法律规定的个人信息删除情形的，除法律法规另有规定要求保留的信息外，您的个人信息将被及时删除。但因相关法律规定或安全技术限制，您在本产品下的个人信息从业务系统中被删除后，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清除或实现匿名化。
					</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">撤回您的同意</span>
				</strong>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					您可以通过本政策第八节提供的联系方式，联系我们撤回已给出的个人信息处理相关同意。此外，您也可以通过删除信息、更改隐私设置、在浏览器或操作系统中关闭设备功能等方式改变同意范围或撤回您的授权。请您理解，当您执行上述操作后，我们将无法继续为您提供撤回同意或授权所对应的服务，但不会影响此前基于您的授权而开展的个人信息处理。
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">注销您的账号</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<span className="ft24 corlor51">
					您有权通过“【设置】-【注销账号】”注销您的账号。
				</span>
				<strong className="corlor51">
					<span className="ft24">
						请注意，在您注销账号后，我们将根据法律规定删除或匿名化处理您的个人信息，请谨慎进行账号注销操作。
					</span>
				</strong>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">响应您的上述请求</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<span className="ft24 corlor51">
					为了保障安全，我们可能需要您提供相应方式证明您的身份和请求的正当性，我们将在收到您反馈并验证您的身份后的【15】天内答复您的请求。
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					在以下情形中，按照法律法规要求，我们将无法响应您的请求：
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					a．&nbsp;与国家安全、国防安全直接相关的；
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					b．&nbsp;与公共安全、公共卫生、重大公共利益直接相关的；
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					c．&nbsp;与犯罪侦查、起诉、审判和判决执行等直接相关的；
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					d．&nbsp;有充分证据表明您存在主观恶意或者滥用权利的；
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">
					e．&nbsp;响应您的请求将导致您和其他个人、组织的合法权益受到严重损害的；
				</span>
			</p>
			<p>
				<span className="ft24 corlor51">f．&nbsp;涉及商业秘密的。</span>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">五.&nbsp;我们如何处理儿童的个人信息</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<br />
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">
						本政策中，儿童指未满十四周岁的未成年人。如您为儿童，在使用产品或服务前，请您及您的父母或监护人仔细阅读本政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
					</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">
						若您是儿童的父母或其他监护人，请您关注您监护的儿童是否是在取得您的授权同意之后使用我们的服务。如您对您所监护的儿童的个人信息有疑问，请通过
					</span>
				</strong>
				<strong>
					<span className="ft24">本政策第八节</span>
				</strong>
				<strong className="corlor51">
					<span className="ft24">提供的联系方式与我们联系。</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">六.&nbsp;我们如何使用Cookie和同类技术</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">（一）&nbsp;Cookie使用</span>
				</strong>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<br />
			</p>
			<p className="justify">
				<span className="ft24 corlor51">
					为确保网络平台正常高效运转、为您获得更轻松的访问体验，我们会在您的设备终端/系统上存储Cookie、Flash Cookie，或浏览器（或关联应用程序）提供的其他通常包含标识符、站点名称以及一些号码和字符的本地存储（统称“Cookie”）。借助Cookie，我们能够用于判断您是否处于登录状态、进行统计分析以优化产品或服务。
				</span>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">
					您可以将设备终端/系统设置为收到Cookie时立即通知您，以便您决定是否接受它。
				</span>
				<strong className="corlor51">
					<span className="ft24">
						如果您的浏览器或浏览器附加服务允许，您可通过配置浏览器修改对Cookie的接受程度或拒绝我们的Cookie，但前述操作在某些情况下可能会影响您安全访问本产品，且可能需要在您每一次访问我们的网站时更改用户设置。
					</span>
				</strong>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">（二）&nbsp;Cookie同类技术的使用</span>
				</strong>
			</p>
			<p>
				<span className="ft24 corlor51">&nbsp;</span>
			</p>
			<p className="justify">
				<span className="ft24 corlor51">
					除Cookie外，我们还会在网站上使用网络Beacon、网站信标、像素标签、ETag等其他同类技术。例如，我们可能使用网络Beacon，计算用户访问数量。
				</span>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<strong className="corlor51">
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<br />
			</p>
			<p>
				<strong>
					<span className="ft24">七.&nbsp;本政策如何更新</span>
				</strong>
			</p>
			<p>
				<strong>
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<span className="ft24">
					1.&nbsp;由于服务内容、联络方式、法律和监管政策要求等变化，我们可能会适时对本政策进行更新。
				</span>
				<strong>
					<span className="ft24">
						当本政策发生变更时，我们会在我们的官方网络平台发布，或通过包括但不限于推送、短信、或在浏览页面做特别提示方式向您展示更新后的版本。在前述情况下，若您继续使用我们的服务，即表示同意接受修订后的本政策并受之约束。
					</span>
				</strong>
			</p>
			<p>
				<span className="ft24">&nbsp;</span>
			</p>
			<p>
				<br />
			</p>
			<p>
				<strong>
					<span className="ft24">
						本政策的更新涉及处理个人信息的目的、处理的个人信息类型、个人信息的使用方式变更的，我们还会通过更为显著的通知，例如邮件、弹窗、页面提示等，向您提供更新版本，并征得您的同意。
					</span>
				</strong>
			</p>
			<p>
				<span className="ft24">&nbsp;</span>
			</p>
			<p>
				<span className="ft24">
					2.&nbsp;为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。
				</span>
			</p>
			<p>
				<span className="ft24">&nbsp;</span>
			</p>
			<p>
				<br />
			</p>
			<p>
				<span className="ft24">
					3.&nbsp;您可以通过“【设置】-【关于柚堂】-【隐私权政策】”中查看本政策的最新版本。
				</span>
			</p>
			<p className="justify">
				<span className="ft24">&nbsp;</span>
			</p>
			<p>
				<strong>
					<span className="ft24">八.&nbsp;如何联系我们</span>
				</strong>
			</p>
			<p>
				<strong>
					<span className="ft24">
						本政策提及的可能需要与我们取得联系的情况下，或您对本政策内容有任何疑问、意见或建议的，您可以通过以下方式联系我们：
					</span>
				</strong>
			</p>
			<p>
				<strong>
					<span className="ft24">1. 通过 App内客服中心联系我们</span>
				</strong>
			</p>
			<p>
				<br />
			</p>
			<p>
				<strong>
					<span className="ft24">
						2.地址：【浙江省杭州市江干区九堡九环路9号4号楼1楼109室】
					</span>
				</strong>
			</p>
			<p>
				<strong>
					<span className="ft24">3.电子邮箱：</span>
				</strong>
				<a
					target="_blank"
					href="mailto:【yotown@yowant.com】"
					className="color5"
				>
					<strong>
						<u>
							<span className="ft24">【yotown@yowant.com】</span>
						</u>
					</strong>
				</a>
			</p>
			<p>
				<strong>
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<strong>
					<span className="ft24">九．权限申请及应用场景</span>
				</strong>
			</p>
			<p>
				<br />
			</p>
			<p>
				<span className="ft24">
					我们将在您使用特定功能时，向您申请使用该功能所必须的权限，如您不同意开启该权限，请点击“不允许”，或您希望撤回授权的，您可在设备的设置功能中关闭您已授权的权限，拒绝或撤回该授权仅将影响您对特定产品功能的使用。
					<a href="/agreement?type=power">【权限申请及应用场景】</a>
				</span>
			</p>
			<p>
				<strong>
					<span className="ft24">&nbsp;</span>
				</strong>
			</p>
			<p>
				<span className="ft24">&nbsp;</span>
			</p>
		</div>
	)
}

export default Test2

