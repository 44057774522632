import React, { useEffect, useState, useRef } from 'react'
import useJsBridge from '@utils/useJsBridge'
import API from '@service/index'
import { Dialog } from 'antd-mobile'
import { LeftOutline } from 'antd-mobile-icons'
import './styles.less'
const DownLoadView = ({ history }: any) => {
	const isPublishSubmit = useRef<any>(false)
	const { bridgePostToken, bridgePostToPage, bridgePostAppBar, bridgePostBackPage } = useJsBridge()
	const [close, setClose] = useState(false)
	const getNativeFunc = async () => {
		if (window['YoTown']) {
			bridgePostAppBar({ hideAppBar: true })
			try {
				const res = await bridgePostToken()
				const token = res.result?.data
				if (token) {
					localStorage.setItem('token', token)
				}
			} catch (error) {
				console.log('nativeFunc', error)
			}
		}
		// getInfo()
	}
	useEffect(() => {
		getNativeFunc()
	}, [])

	//领取手办赠礼
	const getGoods = async () => {
		isPublishSubmit.current = true;
		const res = await API.id61082({})
		if (res.success && res) {
			setClose(true)
			Dialog.show({
				title: (<div style={{
					fontSize: '16px'
				}}>
					领取成功
				</div>),
				content: (<>
					<div style={{
						padding: '20px',
						textAlign: "center",
						fontSize: '13px'
					}}> {res?.msg}</div>
				</>),
				closeOnAction: true,
				actions: [
					[
						{
							key: 'cancel',
							text: '取消',
							onClick: () => {
								setClose(false)
								isPublishSubmit.current = false;
							}
						},
						{
							key: 'delete',
							text: '兑换',
							onClick: () => {
								bridgePostToPage('/mine/exchange_center', {})
							}
						},
					],
				],
			})


		} else {
			Dialog.show({
				title: (<div style={{
					fontSize: '16px'
				}}>
					领取失败
				</div>),
				content: (<>
					<div style={{
						padding: '20px',
						textAlign: "center",
						fontSize: '13px'
					}}>{res?.msg}</div>
				</>),
				closeOnAction: true,
				actions: [
					[
						{
							key: 'cancel',
							text: '取消',
							onClick: () => {
								setClose(false)
								isPublishSubmit.current = false;
							}
						},
						{
							key: 'delete',
							text: '购买',
							onClick: () => {
								bridgePostToPage('/index/shop', {})
							}
						},
					],
				],
			})

		}

		close && Dialog.clear()
	}

	return (
		<div className="buy-free">
			<div className='downloadView-header-bar'>
				<LeftOutline
					onClick={() => {
						bridgePostBackPage()
					}} />
			</div>

			<img src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/registration/buy-free-background.png" alt="" className='background' />
			<img
				src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/registration/buy-button.png"
				alt=""
				className='buy-free-button'
				onClick={() => {
					if (isPublishSubmit.current) {
						return;
					}
					getGoods()
					setClose(false)
				}}
			/>
		</div >
	)
}

export default DownLoadView
