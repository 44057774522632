import React, { useEffect, useState } from 'react'
import { Button } from 'antd-mobile'
import './styles.less'
import { isInWeixin } from '@utils/common'
import MaskWeixin from '@components/MaskWeixin'
import { useDownloadJump } from '@components/downloadApp/useDownloadJump'

const imgUrl =
	'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/download/download-1.png'
const DownLoadView = ({ history }: any) => {
	const { downloadJump } = useDownloadJump()

	const [inWx, setInWx] = useState(false)
	useEffect(() => {
		document.title = '柚堂——顶流明星联名潮物潮品社区'
	}, [])

	const downloadAction = () => {
		if (isInWeixin()) {
			setInWx(true)
		} else {
			downloadJump()
		}
	}
	return (
		<div className="downloadView">
			<img className="bgimg" src={imgUrl} alt="背景图" />
			<Button className="downloadButton" fill="solid" onClick={downloadAction}>
				下载柚堂APP
			</Button>

			{inWx && (
				<MaskWeixin
					onClick={() => {
						setInWx(false)
					}}
				/>
			)}
		</div>
	)
}

export default DownLoadView
