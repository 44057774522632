import React, { useEffect } from 'react'
import useJsBridge from '@utils/useJsBridge'
import BackButton from '@components/backButton'
import { useTrackView } from '@track/index'
import './style/index.less'

import bg1 from '../../assets/launchCalendar/发售日历-测试期_01.png'
import bg2 from '../../assets/launchCalendar/发售日历-测试期_02.png'
import bg3 from '../../assets/launchCalendar/发售日历-测试期_03.png'
import bg4 from '../../assets/launchCalendar/发售日历-测试期_04.png'
import bg5 from '../../assets/launchCalendar/发售日历-测试期_05.png'
import bg6 from '../../assets/launchCalendar/发售日历-测试期_06.png'
import bg7 from '../../assets/launchCalendar/发售日历-测试期_07.png'
import bg8 from '../../assets/launchCalendar/发售日历-测试期_08.png'
import bg9 from '../../assets/launchCalendar/发售日历-测试期_09.png'

type Props = {}
const LaunchCalendar = (props: Props) => {
	const { bridgePostAppBar } = useJsBridge()

	const imgs = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9]
	useEffect(() => {
		bridgePostAppBar({ hideAppBar: true })
	}, [])
	useTrackView()

	return (
		<div className="launchCalendarStyle">
			<BackButton />
			{imgs.map((src) => {
				return <img className="bgImg" key={src} src={src} alt="发售日历-图片" />
			})}
		</div>
	)
}
export default LaunchCalendar
