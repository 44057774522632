
      /**
      * h5验证短信验证码
      * 
      * /h5/app/login/verify/sms/auth/code
      **/
      
    import request from '../utils/request.ts'
    import {getHost,reduceParams} from '@ywfe/utils'
    const {basePath = '', loginPath = ''} = getHost('COMMON', '455')
    export default (inputs = {},config={}) => request['post'](`${basePath}/nft-biz/h5/app/login/verify/sms/auth/code`, reduceParams(inputs),{loginHost: loginPath,...config})