
      /**
      * 藏品碎片合成
      * 
      * /app/activity/card/compose
      **/
      
    import request from '../utils/request.ts'
    import {getHost,reduceParams} from '@ywfe/utils'
    const {basePath = '', loginPath = ''} = getHost('COMMON', '455')
    export default (inputs = {},config={}) => request['post'](`${basePath}/nft-biz/app/activity/card/compose`, reduceParams(inputs),{loginHost: loginPath,...config})