import React, { useEffect, useState } from 'react'
import { Toast } from 'antd-mobile/2x'
import { getQueries } from '@ywfe/utils'
import API from '@service/index'
import DownloadApp from '@components/downloadApp/download_white'
import './styles.less'
import YTBradge from '@components/bradge'
import Clipboard from 'clipboard'

type Props = {
	history: any
}

const Index = (props: Props) => {
	const { userNftNo } = getQueries()
	const getUserNftAPI = API.id54433
	const [data, setData] = useState<any>(undefined)
	const [isNative, setIsNative] = useState(false)

	const getData = async () => {
		if (userNftNo) {
			const res = await getUserNftAPI({
				userNftNo: userNftNo,
			})
			if (res) {
				document.title = res.title || '柚堂——顶流明星联名潮物潮品社区'
				setData(res)
			}
		} else {
			Toast.show({
				content: '分享地址出错',
				position: 'top',
			})
		}
	}

	const onCopy = () => {
		console.log('开始')

		const copy = new Clipboard('.copy-btn')
		copy.on('success', (e) => {
			Toast.show({
				content: <div>复制成功！</div>,
				position: 'top',
			})
		})
		copy.on('error', function(e) {
			console.log('复制出错', e)
		})
	}

	useEffect(() => {
		onCopy()
		getData()
		setIsNative(!!window['YoTown'])
	}, [])

	return (
		<div className={`goodsDetail2 ${!isNative && 'outerPadding'}`}>
			{data ? (
				<>
					<div className="headerView">
						{data.videoUrl ? (
							<video
								width={'100%'}
								src={data.videoUrl}
								muted
								loop
								autoPlay
								controls={true}
								poster={data.headImgUrl}
								playsInline={true}
								webkit-playsinline="true"
								x5-playsinline="true"
								x5-video-player-type="h5-page"
							></video>
						) : (
							<img src={data.headImgUrl} />
						)}
					</div>
					<div className="details">
						<div className="name">
							<div className="brand">
								<YTBradge {...data} />
							</div>
							<div className="name">{data.title ?? '-'}</div>
						</div>
						<div className="bottom">
							<div className="block">
								<div className="title">商品编号:</div>
								<div className="val">{data.userNftCode ?? '-'}</div>
							</div>
							<div className="block">
								<div className="title">限量发售:</div>
								<div className="val">{data.sellQuantity ?? '-'}</div>
							</div>
						</div>
					</div>

					<div className="block5">
						<div className="title">商品信息</div>
						<div className="listItem">
							<div className="name">至信链Hash</div>
							<div className="val">
								<div className="txHash">{data.txHash ?? '-'}</div>
								<div className="img-btn"></div>
								<button
									data-clipboard-text={data.txHash}
									className="copy-btn"
									type="button"
								></button>
							</div>
						</div>
						<div className="listItem">
							<div className="name">铸造时间</div>
							<div className="val">{data.tradeAt ?? '-'}</div>
						</div>
						<div className="listItem">
							<div className="name">持有人</div>
							{/* nickName	,userHeadImg */}
							<div className="val">{data.nickName ?? '-'}</div>
						</div>
					</div>

					<div className="block3">
						<div className="title">作品故事</div>
						<div className="imgList">
							{Array.isArray(data.descriptionPics) &&
								data.descriptionPics.map((item, index) => {
									return <img src={item} key={index} />
								})}
						</div>
					</div>
				</>
			) : null}
			{!isNative && <DownloadApp />}
		</div>
	)
}
export default Index
