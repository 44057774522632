export class YWH5Track {
	static router2trackIds = new Map([
		// 邀请好友
		['/activity/invite', 30001],
		// 分享注册页
		['/activity/shareInvite', 30002],
		// 原住民
		['/activity/badge', 30003],
		// 集卡
		['/activity/compound', 30004],
		// nfc鉴定结果
		['/result', 30005],
		// 官网 materials
		['/materials', 30006],
		// home
		['/home', 30007],
		// 协议
		['/agreement/service', 30008],
		// 隐私
		['/agreement/privacy', 30009],
		// 下载
		['/download', 30010],
		// 商详
		['/goodsDetail', 30011],
		// 直播
		['/live', 30012],
		// 发售日历
		['/launchCalendar', 30013],
	])

	static badge2trackIds = new Map([
		// 原住民-查看「活动规则」
		['rule_clk', 20001],
		// 原住民-点击「增加次数」
		['addTimes_clk', 20002],
		// 原住民-点击「签到」
		['Sign_clk', 20003],
		// 原住民-点击「分享」
		['share_clk', 20004],
		// 原住民-点击「每日登陆」
		['visit_clk', 20005],
		// 原住民-点击「邀请」
		['invit_clk', 20006],
		// 原住民-统计抽卡总次数
		['luckDraw_clk', 20007],
		// 原住民-合成徽章
		['compose_clk', 20008],
		// 原住民-原住民徽章藏品详情页
		['goodDetail_clk', 20009],
	])
	// 点击下载按钮
	static download_clk = 21001
	// 点击注册按钮
	static register_clk = 21002
}
