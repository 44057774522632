import React from 'react'
import useJsBridge from '@utils/useJsBridge'
import { Button } from 'antd-mobile'
import './styles.less'

type Props = {
	routeBack?: () => void
}

const BackButton = (props: Props) => {
	const { routeBack = () => {} } = props
	const backAction = () => {
		bridgePostBackPage()
	}
	const { bridgePostBackPage, isNative } = useJsBridge({ routeBack })
	return (
		<div hidden={!isNative} className="backView" onClick={backAction}>
			<Button></Button>
		</div>
	)
}
export default BackButton
