import React, { useEffect, useRef, useState } from 'react'
import { Toast } from 'antd-mobile/2x'
import { InformationCircleOutline } from 'antd-mobile-icons'
import { getQueries } from '@ywfe/utils'
import API from '@service/index'
import Clipboard from 'clipboard'
import DownloadApp from '@components/downloadApp/download_white'
import './styles.less'
import YTBradge from '@components/bradge'

type Props = {
	history: any
}

const Index = (props: Props) => {
	const { productNo, userNftNo } = getQueries()
	const getProductAPI = API.id54419
	const [data, setData] = useState<any>(undefined)
	const [isNative, setIsNative] = useState(false)

	const getData = async () => {
		if (productNo) {
			const res = await getProductAPI({
				productNo: productNo,
				userNftNo: userNftNo,
			})
			if (res) {
				document.title = res.title || '柚堂——顶流明星联名潮物潮品社区'
				setData(res)
			}
		} else {
			Toast.show({
				content: '分享地址出错',
				position: 'top',
			})
		}
	}

	const onCopy = () => {
		console.log('开始')

		const copy = new Clipboard('.copy-btn')
		copy.on('success', (e) => {
			Toast.show({
				content: <div>复制成功！</div>,
				position: 'top',
			})
		})
		copy.on('error', function(e) {
			console.log('复制出错', e)
		})
	}

	useEffect(() => {
		onCopy()
		getData()
		setIsNative(!!window['YoTown'])
	}, [])

	return (
		<div className={`goodsDetail ${!isNative && 'outerPadding'}`}>
			{data ? (
				<>
					<div className="headerView">
						{data.videoUrl ? (
							<video
								width={'100%'}
								src={data.videoUrl}
								muted
								loop
								autoPlay
								controls={true}
								poster={data.headImgUrl}
								playsInline={true}
								webkit-playsinline="true"
								x5-playsinline="true"
								x5-video-player-type="h5-page"
							></video>
						) : (
							<img src={data.headImgUrl} />
						)}
					</div>
					<div className="details">
						<div className="top">
							<div className="money">￥{data.price ?? '-'}</div>
							{data.salesDesc && <div className="people">{data.salesDesc}</div>}
						</div>
						<div className="name">
							<div className="brand">
								<YTBradge {...data} />
							</div>
							<div className="name">{data.title}</div>
						</div>
						<div className="bottom">
							<div className="block">
								<div className="title">限量发售:</div>
								<div className="val">{data.sellQuantity ?? '-'}</div>
							</div>
							{data.limitPerBuyer ? (
								<div className="block">
									<div className="title1">
										<InformationCircleOutline />
									</div>
									<div className="val">{data.limitPerBuyerDesc ?? '-'}</div>
								</div>
							) : null}
							<div></div>
						</div>
					</div>
					{data.bannerImg ? (
						<div className="block2">
							<img src={data.bannerImg} alt="" />
						</div>
					) : (
						<></>
					)}

					<div className="block3">
						<div className="title">作品故事</div>
						<div className="imgList">
							{data.descriptionPics?.length &&
								data.descriptionPics.map((item, index) => {
									return <img src={item} key={index} />
								})}
						</div>
					</div>

					{Array.isArray(data.labels) && (
						<div className="block4">
							<div className="top">
								<div className="title">柚堂明星限定潮品</div>
								<div className="icon">
									<img
										src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/app-logo.png"
										alt=""
									/>
								</div>
							</div>
							<div className="content">
								{data.labels.map((item, index) => {
									return (
										<div className="item" key={index}>
											<div className="icon"></div>
											{item}
										</div>
									)
								})}
							</div>
						</div>
					)}
					<div className="block5">
						<div className="title">商品参数</div>
						<div className="listItem">
							<div className="name">品牌方</div>
							<div className="val">{data.seller ?? '-'}</div>
						</div>
						{data?.productType !== 1 ? (
							<div className="listItem">
								<div className="name">藏品地址</div>
								<div className="val">
									<div className="nfcIp">{data.nftAddress ?? '-'}</div>
									<div className="img-btn"></div>
									<button
										data-clipboard-text={data.nftAddress}
										className="copy-btn"
										type="button"
									></button>
								</div>
							</div>
						) : null}
						<div className="listItem">
							<div className="name">发行时间</div>
							<div className="val">{data.sellTime ?? '-'}</div>
						</div>
					</div>

					{data?.noticeContent && (
						<div className="block6">
							<div dangerouslySetInnerHTML={{ __html: data.noticeContent }} />
						</div>
					)}
				</>
			) : null}
			{!isNative && <DownloadApp />}
		</div>
	)
}
export default Index
