import React, { useEffect, useRef, useState } from "react";
interface JsBridgeData {
  type?: 'back' | 'toName' | 'appBar' | 'share'; // route为操作页面路由，结合GetX使用 back 返回上级页面，toName 前往某个页面等
  pageName?: string; //页面名字，与本地路由表对应
  params?: { [p: string]: any }; // 参数
  showLoading?: boolean; // 接口loading
  dataName?: 'token' | 'userInfo' //获取的数据类型，如token为获取token
  att?: {
    useSafeArea?: boolean;
    hideAppBar?: boolean;
    actions?: {
      icon: string;// 找app端要 如 ic_my_share.png
      onTap: string;//功能 如 share
      shareTitle?: string; //分享带的字段 其他的也一样 如果是type 为分享直接调用 则写到 att外面
      shareUrl?: string;
      shareThumb?: string;
      shareDesc?: string;
    }[]
    [p: string]: any
  }; //
  [p: string]: any
}
interface JsBridgeReceive {
  method: 'getData' | 'network' | 'route' | 'ui' | 'common';
  arguments: JsBridgeData;
  jid?: string;
  result?: { [p: string]: any, method: string, data: any, jid: string };
}
interface JsBridgeFunc {
  routeBack?: any
}
const useJsBridge = (func?: JsBridgeFunc) => {
  const { routeBack } = func || {};
  const YoTown = useRef<{ postMessage: any, receiveMessage: any }>({ postMessage: null, receiveMessage: null });
  const receive = useRef<Map<string, any>>(new Map());
  const [isNative, setIsNative] = useState(false);
  useEffect(() => {
    return () => {
      YoTown.current = { postMessage: null, receiveMessage: null };
      receive.current = new Map()
    }
  }, [])
  useEffect(() => {
    const flutterYoTown = window['YoTown']
    if (flutterYoTown) {
      setIsNative(true);
      YoTown.current = flutterYoTown;
      YoTown.current.receiveMessage = receiveMessage;
    } else {
      YoTown.current = { postMessage: null, receiveMessage: null };
    }
  }, [window['YoTown']]);

  const receiveMessage = (res: JsBridgeReceive) => {
    // const method = res.method;
    const jid = res.jid;
    // const data = res.result;
    // console.log(res, 'receiveMessage');
    // if (res.arguments.pageName === '/mine/locations') {
    //   localStorage.setItem('address', JSON.stringify(res.result.data) ?? null)
    // }
    if (receive.current.has(jid)) {
      const item = receive.current.get(jid);
      item.resolve?.(res);
      receive.current.delete(jid);
    } else if (!jid && res.method === 'route') {
      routeBack && routeBack(res)
    }

  };
  // needBack  需要async 返回值 则需要needBack 传 true；
  const postMessage = async (method: JsBridgeReceive['method'], data: JsBridgeData, needBack?: boolean) => {
    if (needBack) {
      return new Promise<JsBridgeReceive>((resolve, reject) => {
        const jid = Date.now() + ''
        receive.current.set(jid, { resolve, reject });
        const params = { method: method, arguments: data, jid: jid }
        YoTown.current.postMessage?.(JSON.stringify(params))
        setTimeout(() => {
          if (receive.current.has(jid)) {
            receive.current.delete(jid);
            reject(params);
          }
        }, 10000);
      });
    } else {
      YoTown.current.postMessage?.(JSON.stringify({ method: method, arguments: data }))
    }
  }

  const postToken = async () => {
    return postMessage('getData', { dataName: 'token' }, true);
  }

  const postUserInfo = async () => {
    return postMessage('getData', { dataName: 'userInfo' }, true);
  }
  const postToPage = (path: string, params: any) => {
    postMessage('route', { type: 'toName', pageName: path, params: params })

  }
  const postBackPage = () => {
    postMessage('route', { type: 'back' })
  }

  const postAppBar = (data: JsBridgeData["att"]) => {
    postMessage('ui', { type: 'appBar', att: data })

  }
  const shareView = (title: string, url: string, desc: string, thumb?: string) => {
    postMessage('common', {
      type: 'share', shareTitle: title,
      shareUrl: url, shareDesc: desc, shareThumb: thumb
    })

  }

  return {
    bridgePostToken: postToken,
    postMessage,
    bridgePostUserInfo: postUserInfo,
    bridgePostToPage: postToPage,
    bridgePostBackPage: postBackPage,
    bridgePostAppBar: postAppBar,
    bridgeShare: shareView,
    isNative
  }

}

export default useJsBridge;