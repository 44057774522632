import { Toast } from 'antd-mobile/2x'

/**
 * 获取地址栏参数
 * @export
 * @param {*} name
 * @returns
 */
export function GetQueryString(name) {
	const url = window.location.href
	const reg = new RegExp(`(\\?|&)${name}=([^&]*)(&|$)`, 'i')
	const r = url.match(reg)

	if (r !== null) {
		return unescape(r[2])
	}
	return null
}

/**
 * transformShortNum
 * 转换为带单位的短数据
 * @param num 需要转换的数字（非数字 或是 - 将直接返回 -）
 * @param decimal 返回的小数位
 * @param addUnit 返回值是否添加单位:(万或亿)
 * @param retInt 当数据小于1000万时，是否返回无小数位的整数
 * @param units[] 自定义单位
 **/
export const transformShortNum = (
	num: any,
	decimal = 0,
	addUnit = true,
	retInt = false,
	// customizeUnit: false,
	units = ['万', '亿'],
	afterUnit = ''
) => {
	if (num === 0 || Number(num) === 0) {
		return 0 + afterUnit
	}
	if (num === '-' || isNaN(num) || !Number(num)) {
		return '-'
	}
	let isNegativeNumber = false
	let ret = ''
	if (num < 0) {
		isNegativeNumber = true
		// eslint-disable-next-line no-param-reassign
		num = Math.abs(num)
	}

	if (num >= 100000000) {
		ret = (num / 100000000).toFixed(decimal) + (addUnit ? units[1] : '')
	} else if (num >= 10000) {
		ret = (num / 10000).toFixed(decimal) + (addUnit ? units[0] : '')
	} else {
		ret = retInt ? num.toFixed(0) : decimal ? num.toFixed(decimal) : num
	}
	return isNegativeNumber ? `-${ret}${afterUnit}` : `${ret}${afterUnit}`
}

/**
 * 格式化数字为金额格式
 * @param  {string} s 原数字串
 * @param  {int} n 小数位
 * @return {string} 格式化后的字符串
 */
export const FormatNumbertoRMB = (s: string | number, n: number) => {
	if (s === '-') {
		return s
	}
	if (s === null) {
		return '-'
	}
	n = n >= 0 && n <= 20 ? n : 0
	// eslint-disable-next-line no-useless-escape
	s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + ''
	var l = s
			.split('.')[0]
			.split('')
			.reverse(),
		r = s.split('.')[1],
		t = ''

	for (let i = 0; i < l.length; i++) {
		t += l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? ',' : '')
	}
	let ret = ''
	if (n) {
		ret =
			t
				.split('')
				.reverse()
				.join('') +
			'.' +
			r
	} else {
		ret = t
			.split('')
			.reverse()
			.join('')
	}
	return ret
}

/**
 * saveParam - 设置localStorage
 * loadParam - 获取localStorage
 * removeParam - 删除localStorage
 * @author yanan.wu
 * @date 2018-08-07
 * @export
 * @param {String} name
 * @param {String} value
 */
export const saveParam = (name, value) => {
	if (window.localStorage) {
		window.localStorage.setItem(name, value)
	}
}
export const loadParam = (name) => {
	if (window.localStorage) {
		return window.localStorage.getItem(name)
	}
	return null
}
export const removeParam = (name) => {
	if (window.localStorage) {
		window.localStorage.removeItem(name)
	}
}

export const delQueryString = (paramKey) => {
	let url = window.location.href // 页面url
	const urlParam = window.location.search.substr(1) // 页面参数
	const beforeUrl = url.substr(0, url.indexOf('?')) // 页面主地址（参数之前地址）
	let nextUrl = ''

	/* eslint-disable */
	const arr = new Array()
	if (urlParam != '') {
		const urlParamArr = urlParam.split('&') // 将参数按照&符分成数组
		for (let i = 0; i < urlParamArr.length; i += 1) {
			const paramArr = urlParamArr[i].split('=') // 将参数键，值拆开
			// 如果键雨要删除的不一致，则加入到参数中
			if (paramArr[0] != paramKey) {
				arr.push(urlParamArr[i])
			}
		}
	}
	if (arr.length > 0) {
		nextUrl = `?${arr.join('&')}`
	}
	url = beforeUrl + nextUrl
	return url
	/* eslint-enable */
}

export const getPhoneType = () => {
	let ran = navigator.userAgent
	let isAndroid = ran.indexOf('Android') > -1 || ran.indexOf('Linux') > -1
	let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
	if (isAndroid) {
		return 'android'
	} else if (isIOS) {
		return 'ios'
	} else {
		return undefined
	}
}

const myMatch = (userAgent: string, match: any) => {
	return userAgent.match(match) as any
}
export const getWebSystem = () => {
	var sUserAgent = navigator.userAgent.toLowerCase()
	var bIsIphoneOs = myMatch(sUserAgent, /iphone os/i) == 'iphone os'
	var bIsMidp = myMatch(sUserAgent, /midp/i) == 'midp'
	var bIsUc7 = myMatch(sUserAgent, /rv:1.2.3.4/i) == 'rv:1.2.3.4'
	var bIsUc = myMatch(sUserAgent, /ucweb/i) == 'ucweb'
	var bIsAndroid = myMatch(sUserAgent, /android/i) == 'android'
	var bIsCE = myMatch(sUserAgent, /windows ce/i) == 'windows ce'
	var bIsWM = myMatch(sUserAgent, /windows mobile/i) == 'windows mobile'
	if (bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE) {
		// 手机
		return 'is-phone'
	} else if (
		myMatch(sUserAgent, /MicroMessenger/i) == 'micromessenger' &&
		myMatch(sUserAgent, /wxwork/i) == 'wxwork'
	) {
		return 'wx-work'
	} else if (myMatch(sUserAgent, /micromessenger/i) == 'micromessenger') {
		return 'wx'
	}
	return 'pc'
}

export const isInWeixin = () => {
	var sUserAgent = navigator.userAgent.toLowerCase()
	if (
		myMatch(sUserAgent, /MicroMessenger/i) == 'micromessenger' &&
		myMatch(sUserAgent, /wxwork/i) == 'wxwork'
	) {
		return true
	} else if (myMatch(sUserAgent, /micromessenger/i) == 'micromessenger') {
		return true
	} else {
		return false
	}
}

export const uDownloadAction = (data: any) => {
	const res = getPhoneType()
	const {
		androidEvokeLink,
		androidDownloadLink,
		iosEvokeLink,
		iosDownloadLink,
	} = data ?? {}
	if (res === 'android' || res === 'ios') {
		const evokeLink = res === 'android' ? androidEvokeLink : iosEvokeLink
		const downloadLink =
			res === 'android' ? androidDownloadLink : iosDownloadLink
		if (!evokeLink && !downloadLink) {
			Toast.show({
				content: '开发中，敬请期待',
				afterClose: () => {},
			})
		} else if (!evokeLink && downloadLink) {
			window.open(downloadLink)
		} else if (evokeLink && !downloadLink) {
			window.location.href = evokeLink
		} else {
			window.location.href = evokeLink
			setTimeout(() => {
				window.open(downloadLink)
			}, 1000)
		}
	} else {
		Toast.show({
			content: '目前app仅支持iOS和Android的设备',
			afterClose: () => {},
		})
	}
	// intent://scan/#Intent;scheme=kwai://;package=com.smile.gifmaker;end"
	// window.open("kwai://home")
}

export function sleep(duration: number) {
	return new Promise(function(resolve, reject) {
		setTimeout(resolve, duration)
	})
}
