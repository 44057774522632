/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { WechatOutlined, WeiboOutlined } from '@ant-design/icons'
import './style/index.less'

export default () => {
	const jumpAgreement = (type) => {
		// window.open(`/agreement?type=${type}`, '_blank');
		if (type === 'service') {
			window.open(`/agreement/service`, '_blank')
		} else {
			window.open(`/agreement/privacy`, '_blank')
		}
	}

	return (
		<div className="page_footer_h5">
			<div className="page_footer-content">
				<div className="page_footer-content-items">
					<ul className="page_footer-content-item">
						<li className="tit">关注我们</li>
						<li>
							<WechatOutlined
								style={{
									fontSize: '14px',
									marginRight: '7px',
								}}
							/>
							柚堂公众号
						</li>
						<li>
							<WeiboOutlined
								style={{
									fontSize: '14px',
									marginRight: '7px',
								}}
							/>
							柚堂微博
						</li>
					</ul>
					<ul className="page_footer-content-item">
						<li className="tit">协议政策</li>
						<li onClick={() => jumpAgreement('service')}>服务协议</li>
						<li onClick={() => jumpAgreement('other')}>隐私政策</li>
					</ul>
					<ul className="page_footer-content-item">
						<li className="tit">服务与支持</li>
						<li>工单支持：yotown@yowant.com</li>
						<li>商务合作：yotown@yowant.com</li>
						<li>工作时间：9:00 - 18:00（工作日）</li>
					</ul>
					<ul className="page_footer-content-item">
						<li className="tit">电话咨询</li>
						<li>0571-88515356</li>
					</ul>
				</div>
			</div>
			<div className="page_footer-foot">
				www.naxiawl.com 杭州那夏网络科技有限公司©️
				<br />
				<div
					style={{ display: 'inline-block' }}
					onClick={() => {
						window.open('https://beian.miit.gov.cn/')
					}}
				>
					2022 浙ICP备19017711号-1
				</div>
			</div>
		</div>
	)
}
