
      /**
      * 查询活动详情
      * 
      * /app/activity/draw/info
      **/
      
    import request from '../utils/request.ts'
    import {getHost,reduceParams,querystring} from '@ywfe/utils'
    const {basePath = '', loginPath = ''} = getHost('COMMON', '455')
    export default (inputs={},config={}) => request['get'](`${basePath}/nft-biz/app/activity/draw/info?${querystring.stringify(reduceParams(inputs))}`, {loginHost: loginPath,...config})