import React from 'react'
import Youtangbg from '../components/youtangbg'
import './index.less'

type Props = {}

export const Business = (props: Props) => {
	return (
		<div className="home_business_h5">
			<div className="bg">
				<img
					src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/bn_1_h5.png"
					alt=""
				/>
			</div>
			<div className="bl1">
				<Youtangbg title="我们的优势" />
				<div className="list">
					<div className="listItem">
						<div className="icon icon1"></div>
						<div className="name">时尚IP赋能</div>
						<div className="desc">
							全面拢合设计师/设计师品牌、艺术家IP、明星主理人、意见领袖资源，以原创设计能力、别具一格的潮流基因、对市场的强大影响力赋能潮流行业
						</div>
					</div>
					<div className="listItem">
						<div className="icon icon3"></div>
						<div className="name">品质供应链</div>
						<div className="desc">
							与50+国际一线品牌工厂合作，构建完整有效的供应链体系，确保持续稳定的产品供应与质保能力
						</div>
					</div>
					<div className="listItem">
						<div className="icon icon5"></div>
						<div className="name">明星种草营销</div>
						<div className="desc">
							提供基于明星特质的集创意、内容、媒介于一体的种草营销服务，拓宽内容种草场景的广度和种草深度，助力IP寻求品牌长效增长点
						</div>
					</div>
				</div>
			</div>
			<div className="bl2">
				<div className="title">
					柚堂APP作为专属Z时代的潮物电商平台和新一代潮流社区阵地，诚邀诸众艺术家入驻共同开启时尚新潮流。
				</div>
				<div className="desc">入驻合作：tianwen@yowant.com</div>
			</div>
		</div>
	)
}
