import React, { useEffect, useState } from 'react'
import './styles.less'
import { translateMapToArray } from '@ywfe/utils'
import { useHeight } from '@page/home/pc/Main/useHeight'

const NAV_LIST = new Map([
	['main', '首页'],
	['business', '商务合作'],
	['us', '关于我们'],
])

const logo =
	'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/head-1.png'
const logo_black =
	'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/logo-black.png'

export default (props) => {
	const { className, pageIndex, changgeIndex, history } = props
	const navList = translateMapToArray(NAV_LIST)
	const [bannerActive, setbannerActive] = useState(false)

	const [height] = useHeight(1920 / 1080)

	useEffect(() => {
		window.addEventListener('scroll', onscroll)
		return () => {
			window.removeEventListener('scroll', onscroll)
		}
	}, [height])

	const onscroll = () => {
		var t = document.documentElement.scrollTop || document.body.scrollTop
		if (height < t + 88) {
			setbannerActive(true)
		} else {
			setbannerActive(false)
		}
	}

	return (
		<div
			className={`header-view-pc ${className} ${pageIndex} ${bannerActive &&
				'active'}`}
		>
			<div className="header-container">
				<img
					src={pageIndex === 'main' ? logo : logo_black}
					alt=""
					onClick={() => {
						history.go('/')
					}}
					className="header-container-logo"
				/>
				<div className="nav-list">
					{navList.map((item) => {
						return (
							<div
								key={item.value}
								className={`nav-list-item ${pageIndex === item.value &&
									'active'}`}
								onClick={() => {
									changgeIndex(item.value)
								}}
							>
								<span className="name">{item.label}</span>
							</div>
						)
					})}
				</div>
				<div className="noContent"></div>
			</div>
		</div>
	)
}
