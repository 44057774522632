import React from 'react'
import './index.less'
import Youtangbg from '../components/youtangbg'
import YTMap from '../components/map'

type Props = {}

export const Us = (props: Props) => {
	return (
		<div className="Us_h5">
			<div className="bg">
				<img
					src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/bn_2_h5.png"
					alt=""
				/>
			</div>
			<div className="bl1">
				<Youtangbg title="平台介绍" />
				<div className="desc">
					柚堂App作为数字潮流新电商平台，旨在为明星忠实粉丝提供由明星共创的先锋潮品，NFC绑定溯源鉴伪与限量实物商品结合的方式，加持数字藏品的拓展赋能，打造有品质保障的数字购物新体验。
				</div>
				<div className="desc">
					依托遥望网络的技术优势，柚堂App将在数字内容领域持续发力，为明星定制专属潮流潮品共创、潮流发布、潮品营销的全链路整体解决方案，并通过构筑数字潮流元宇宙街区，不断革新明星与粉丝互动方式，打造非同质化拥有的沉浸式粉丝追星新体验。在深耕粉丝运营的同时，强化平台内容优势、流量优势，大力推广技术革新和业务形态的创新研发。
				</div>
			</div>
			<div className="bl1">
				<Youtangbg title="联系我们" />
				<div className="concat">
					<div className="other">
						<div className="otherItem">
							<div className="name">
								<img
									src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/place.png"
									alt=""
								/>
								地址
							</div>
							<div className="text">
								浙江省杭州市余杭区仓前街道鼎创财富中心1幢遥望直播电商产业园
							</div>
						</div>
						<div className="otherItem">
							<div className="name">
								<img
									src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/email.png"
									alt=""
								/>
								邮箱
							</div>
							<div className="text">yotown@yowant.com</div>
						</div>
						<div className="otherItem">
							<div className="name">
								<img
									src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/phone.png"
									alt=""
								/>
								电话
							</div>
							<div className="text">0571-88515356</div>
						</div>
					</div>
					<div className="map">
						<YTMap />
					</div>
				</div>
			</div>
		</div>
	)
}
