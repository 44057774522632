import React from 'react'
import img from '../../assets/mobile/shareWexin.png'
import './styles.less'

type MaskWeixinProps = {
	onClick?: () => void
}

const MaskWeixin = (props: MaskWeixinProps) => {
	const onMaskClick = () => {
		if (props.onClick && typeof props.onClick === 'function') {
			props.onClick()
		}
	}

	return (
		<div className="maskWeixinView" onClick={onMaskClick}>
			<img src={img} />
		</div>
	)
}

export default MaskWeixin
