
      /**
      * app分享首页
      * 
      * /h5/app/share/home
      **/
      
    import request from '../utils/request.ts'
    import {getHost,reduceParams,querystring} from '@ywfe/utils'
    const {basePath = '', loginPath = ''} = getHost('COMMON', '455')
    export default (inputs={},config={}) => request['get'](`${basePath}/nft-biz/h5/app/share/home?${querystring.stringify(reduceParams(inputs))}`, {loginHost: loginPath,...config})