import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import { Toast } from 'antd-mobile/2x'
import { querystring as qs, getMD5 } from '@ywfe/utils'
import { GO_TO_LOGIN, NO_MESSAGE } from './constants'

export interface YWRequestConfig extends AxiosRequestConfig {
	loginHost?: string
}

export interface YWResponse extends AxiosResponse {
	config: YWRequestConfig
}

const instance = axios.create({
	timeout: 30 * 1000,
})

instance.interceptors.request.use(
	(config) => {
		const configData = config

		let actionId = ''
		let pathUrlStart = config.url?.indexOf('ywwl.com') || 0
		let pathUrlEnd = config.url?.indexOf('?') || 0
		pathUrlEnd = pathUrlEnd === -1 ? 0 : pathUrlEnd

		let md5Url = getMD5(config.url?.slice(pathUrlStart + 8, pathUrlEnd)) || ''
		let aPageUrl = window.location.pathname.split('/')
		let pageUrl = aPageUrl[aPageUrl.length - 1]

		const pageActions = (window as any).actionRelData
			? (window as any).actionRelData[md5Url]
			: void 0
		if (typeof pageActions !== 'undefined') {
			actionId = pageActions[pageUrl] || ''
		}

		if (typeof window !== 'undefined') {
			const token = localStorage.getItem('token') || ''
			const isGray = localStorage.getItem('isGray') === 'true' || false
			// const result = JSON.parse(navigator.userAgent)?.osVersion;
			// console.log(navigator.userAgent, 'versionName1')
			// console.log(JSON.parse(JSON.stringify(navigator.userAgent))?.userAgent, 'versionName2')
			// console.log(JSON.parse(JSON.stringify(navigator.userAgent)), 'versionName3')
			configData.headers = {
				...configData.headers,
				'X-TOKEN': token,
				'x-gray': isGray,
				// 'versionName': JSON.parse(navigator.userAgent)?.osVersion,
				actionId: actionId,
				fingerprint: window.localStorage.getItem('fp'),
				extendParams: JSON.stringify({}),
			}
		}
		return configData
	},
	(error) => {
		console.log('API error', error)
		return Promise.reject(error)
	}
)

instance.interceptors.response.use(
	(res: YWResponse): any => {
		const { data, config } = res
		const { success, code } = data
		const isFormData = typeof data === 'string'
		const isArraybuffer = res.config.responseType === 'arraybuffer'
		if (isFormData || isArraybuffer) {
			if (data) {
				return data
			} else {
				if (typeof window !== 'undefined') {
					Toast.show(isFormData ? '图片上传失败' : '下载失败')
				}

				return Promise.reject()
			}
		}
		if (success) {
			return data.data || success
		} else {
			if (
				GO_TO_LOGIN.indexOf(Number(code)) >= 0 &&
				typeof window !== 'undefined'
			) {
				const projectId = window.localStorage.getItem('projectId')
				const { loginHost = '' } = config
				let pathname = window.location.pathname
				let search = window.location.search
				search = search.replace('?', '')
				let query: any = qs.parse(search)
				query = {
					...query,
					projectId: projectId || query.projectId,
				}

				window.top.location.href = `${loginHost}/login?${qs.stringify(
					query
				)}&pathname=${encodeURIComponent(
					pathname
				)}&targetUrl=${encodeURIComponent(
					window.location.pathname + window.location.search
				)}`
			}
			if (typeof window !== 'undefined' && NO_MESSAGE.indexOf(code) < 0) {
				Toast.show(data.msg || data.message)
			}

			return Promise.reject(data)
		}
	},
	(error: any) => {
		if (typeof window !== 'undefined') {
			Toast.show('网络繁忙, 休息一会儿再来吧~ ^_^！')
		}

		return Promise.reject(error)
	}
)

export default class Request {
	static get<T>(url: string, config?: YWRequestConfig): Promise<T> {
		return instance.get(url, config)
	}

	static post<T>(
		url: string,
		data?: any,
		config?: YWRequestConfig
	): Promise<T> {
		return instance.post(url, data, config)
	}
}
