import API from '@service/index'
import BackButton from '@components/backButton'
import useJsBridge from '@utils/useJsBridge'
import { Button, Popup, PullToRefresh, SafeArea } from 'antd-mobile'
import { Toast } from 'antd-mobile/2x'
import { CLICK_TRACK } from '@track/index'

import React, { useEffect, useState } from 'react'
import './style/index.less'

const BadgeActivity = ({ history }: any) => {
	const { bridgePostToken, bridgePostToPage, bridgePostAppBar } = useJsBridge({
		routeBack: () => {
			routeBack()
		},
	})
	const [rulesVisible, setRulesVisible] = useState(false)
	const [avtivityVisible, setAvtivityVisible] = useState(false)
	const [info, setInfo] = useState<any>({})
	const [showGetSui, setShowGetSui] = useState(false)
	// 合成动画
	const [showComposeBadge, setShowComposeBadge] = useState<boolean>(false)
	const [suiInfo, setSuiInfo] = useState<any>({})
	useEffect(() => {
		getNativeFunc()
	}, [])

	const getNativeFunc = async () => {
		console.log('hello', window['YoTown'])
		if (window['YoTown']) {
			bridgePostAppBar({ hideAppBar: true })
			try {
				const res = await bridgePostToken()
				const token = res.result?.data
				if (token) {
					localStorage.setItem('token', token)
					getInfo()
				}
			} catch (error) {
				console.log('nativeFunc', error)
			}
		}
		// getInfo()
	}
	const routeBack = () => {
		getInfo()
	}

	const getInfo = async () => {
		const res: any = await API.id54720({})
		if (res) {
			setInfo(res)
		}
	}
	const choukaClick = () => {
		if (info.userActivityStatus === 8) {
			Toast.show({
				content: '您已经合成徽章了哦~',
			})
		} else if (info.userActivityStatus === 2) {
			getHuizhangNet()
		} else if (info.userActivityStatus === 1) {
			getSuipianNet()
		}
	}
	const getHuizhangNet = async () => {
		Toast.show({
			icon: 'loading',
			content: '合成中',
			duration: 0,
		})
		const res = await API.id54748({})
		Toast.clear()
		if (res) {
			// 原住民-合成徽章
			CLICK_TRACK(20008)
			setShowComposeBadge(true)
			//播放动画 、拉info 接口
			getInfo()
		}
	}

	const getSuipianNet = async () => {
		if (info.drawPackage?.packageNum === 0) {
			Toast.show({
				content: '做任务可加次数哦～',
			})
			return
		}
		Toast.show({
			icon: 'loading',
			content: '抽取中',
			duration: 0,
		})
		const res = await API.id54741({})
		Toast.clear()
		if (res) {
			// 原住民-统计抽卡总次数
			CLICK_TRACK(20007)
			const sui: any = res.userAwards?.[0] || {}
			if (sui.awardNum > 0) {
				setSuiInfo(sui)
				setShowGetSui(true)
			} else {
				Toast.show({
					content: '很遗憾什么都没抽到',
				})
			}
		}
		getInfo()
	}
	const saveSui = () => {
		setShowGetSui(false)
		setSuiInfo({})
	}
	const taskAction = (item: any) => {
		const { eventType } = item
		// 事件类型 10: 签到 11. 邀请 15:每日登录
		if (eventType === 10) {
			// 原住民-点击「签到」
			CLICK_TRACK(20003)
		} else if (eventType === 11) {
			// 原住民-点击「邀请」
			CLICK_TRACK(20006)
		} else if (eventType === 15) {
			// 原住民-点击「每日登陆」
			CLICK_TRACK(20005)
		}
		bridgePostToPage(item.jumpUrl, {})
	}
	const go2houseGiftDetail = () => {
		// 原住民-原住民徽章藏品详情页
		CLICK_TRACK(20009)
		bridgePostToPage('/house/giftDetail', {
			...info?.userNft,
		})
	}
	return (
		<PullToRefresh onRefresh={getInfo}>
			<div className="activity-badge-view">
				<BackButton routeBack={routeBack} />
				<div className="header-view">
					<div className="title4"></div>
				</div>
				<div className="machine">
					<div className="title3">
						<div className="suipians"></div>
						<div className="name">累计获得碎片:</div>
						<div className="count">x{info.flagPackage?.packageNum || 0}</div>
					</div>
					<div className="topDesc">{info.activityDesc || '-'}</div>
					{/* 徽章展示动画 S */}
					{showComposeBadge ? (
						<div className="composeBadge">
							<div
								className="badgeContent"
								onClick={() => {
									setShowComposeBadge(false)
								}}
							></div>
							<div className="badgeBg"></div>
						</div>
					) : (
						<div
							onClick={() =>
								info.userActivityStatus === 8 && go2houseGiftDetail()
							}
							className={
								info.userActivityStatus === 8 ? 'huizhangs' : 'huizhang'
							}
						></div>
					)}
					{/* 徽章展示动画 E */}
					<div className="button-view">
						<div
							className="button1"
							onClick={() => {
								// 原住民-查看「活动规则」
								CLICK_TRACK(20001)
								setRulesVisible(true)
							}}
						></div>
						{/* 按钮S */}
						<div
							className={
								info.userActivityStatus === 2
									? 'button2'
									: info.userActivityStatus === 8
									? 'button2b'
									: ''
							}
							onClick={choukaClick}
						></div>
						{/* 抽卡 */}
						{![2, 8].includes(info.userActivityStatus) && (
							<div className="button2s-wrap" onClick={choukaClick}>
								<div className="button2s"></div>
								<div className="downButton"></div>
							</div>
						)}
						{/* 按钮E */}

						<div
							className="button3"
							onClick={() => {
								// 原住民-点击「增加次数」
								CLICK_TRACK(20002)
								setAvtivityVisible(true)
							}}
						></div>
						{info.userActivityStatus === 1 && (
							<div className="hasCounts">
								<div>剩余{info.drawPackage?.packageNum || 0}次</div>
							</div>
						)}
					</div>
					<div className="desc"></div>
				</div>
				<Popup
					visible={rulesVisible}
					onMaskClick={() => {}}
					getContainer={null}
					key="rules"
					bodyStyle={{
						borderTopLeftRadius: '18px',
						borderTopRightRadius: '18px',
					}}
				>
					<div className="rulesView">
						<div className="popHeader">
							活动规则
							<div
								className="close"
								onClick={() => {
									setRulesVisible(false)
								}}
							></div>
						</div>
						<div className="pop-desc">
							<p className="firstText">
								1、活动时间：2022.6.30 10:00 - 2022.07.04 24:00，持续5天；
								<br />
							</p>
							<p>
								2、发放徽章数量：限量2万枚，每日限量推出，每日合成徽章上限分别为：1000、3000、5000、5000、6000枚；
								<br />
							</p>
							<p>
								3、注册用户活动期间内每日登录即可参与原住民抽卡活动，完成指定任务可额外获得抽卡机会：
								<br />
							</p>
							<p className="garyText">
								a. 每次抽卡有几率获得原住民徽章碎片和潮气值加成；
								<br />
								b. 每种任务每天可完成一次，每完成一次增加一次抽卡机会；
								<br />
								c.
								除首日（5个或以上碎片）外，累计获得10个或以上碎片，可合成原住民徽章，无需再进行抽卡。
								<br />
							</p>
							<p>
								4、每日获得原住民徽章达到当日上限时，无法再合成徽章，先抢先得哦；
								<br />
							</p>
							<p>
								5、用户成为原住民后可享受额外权益，具体见徽章详情说明；
								<br />{' '}
							</p>
							<p>
								6、原望望龙陪伴系列数字手办持有者，可直接获得对应数量的碎片。
							</p>
							<SafeArea position="bottom" />
						</div>
					</div>
				</Popup>
				<Popup
					visible={avtivityVisible}
					onMaskClick={() => {}}
					key="task"
					getContainer={null}
					bodyStyle={{
						borderTopLeftRadius: '18px',
						borderTopRightRadius: '18px',
					}}
				>
					<div className="taskview">
						<div className="header-view">
							<div className="task-header" />
							<div
								className="close"
								onClick={() => {
									setAvtivityVisible(false)
								}}
							/>
							<div className="sub-title">每日首次完成可增加1次抽奖机会</div>
						</div>
						<div className="task-list">
							{info.tasks?.map((item: any, index: number) => {
								return (
									<div className="task-item" key={index}>
										<img className="task-img" src={item.taskImg} />
										<div className="task-detail">
											<div>{item.taskName}</div>
											<div>
												{item.activityTaskAward.activityTaskDesc || '-'}
											</div>
										</div>
										<Button
											style={{
												opacity: item.taskStatus === 0 ? 1 : 0.3,
												padding: 0,
											}}
											onClick={() => item.taskStatus === 0 && taskAction(item)}
										>
											{item.taskStatusDesc}
										</Button>
									</div>
								)
							})}
						</div>
						<SafeArea position="bottom" />
					</div>
				</Popup>
				{showGetSui && (
					<div className="getSuipianView">
						<div className="centerView">
							<div className="title">徽章碎片x{suiInfo.awardNum}</div>
							<div className="suipian"></div>
							<Button onClick={saveSui}></Button>
						</div>
					</div>
				)}
			</div>
		</PullToRefresh>
	)
}

export default BadgeActivity
