import React from "react";
import './style.less'

type Props = {
  visibale: boolean;
  initData: () => void;
  signNo: string;
  lotteryTime: string;

}
const Modal = (props: Props) => {
  const { visibale, initData, signNo, lotteryTime } = props;
  return visibale ? <div
    className="modal"
  // onClick={(e) => {
  //   initData() 
  // }}
  >
    <div className="model-container">
      <img
        src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/successModelBackGround.png"
        alt=""
        className="modal-background"
      />
      <img
        src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/close-icon.png"
        alt=""
        className="close"
        onClick={() => {
          initData()
        }} />
      <div className="model-inner">
        <img
          src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/participate-success.png"
          alt=""
          className="success"
        />
        <div className="nameplate">
          <img src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/nameplate.png" alt="" className="nameplate-img" />
          <div className="nameplate-text">
            <div className="nameplate-title">专属签号</div>
            <div className="nameplate-num">{signNo}</div>
          </div>
        </div>
        <div className="time">{lotteryTime}公布结果</div>
        <img src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/i-know.png" alt="" className="iKnow-button"
          onClick={() => {
            initData();
          }} />
      </div>
    </div>
  </div > : null
}

export default Modal;