
      /**
      * nfc芯片查询
      * 
      * /api/antifake/nfc
      **/
      
    import request from '../utils/request.ts'
    import {getHost,reduceParams,querystring} from '@ywfe/utils'
    const {basePath = '', loginPath = ''} = getHost('COMMON', '455')
    export default (inputs={},config={}) => request['get'](`${basePath}/nft-biz/api/antifake/nfc?${querystring.stringify(reduceParams(inputs))}`, {loginHost: loginPath,...config})