import React, { useState } from 'react'
import { LeftOutline, RightOutline } from 'antd-mobile-icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
import GoodsItemCard from '../goodsItemCard'

// import styles from './index.less'
import './index.less'

SwiperCore.use([Autoplay, Navigation, Pagination])

/***
 * 
 * YKYB&嘎拉兔(GoLepus)联名黑色印花棒球帽

YKYB&嘎拉兔(GoLepus)联名白色印花棒球帽

YKYB&嘎拉兔(GoLepus)联名灰色印花棒球帽

YKYB&嘎拉兔(GoLepus)联名黑色潮T-shirt

YKYB&嘎拉兔(GoLepus)联名白色潮T-shirt

YKYB&嘎拉兔(GoLepus)潮流手办
 * */

const GoodsSwiper: React.FC = () => {
	const partnerLogo: Array<any> = [
		{
			url:
				'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/goods/hat_black.png',
			title: 'YKYB&嘎拉兔(GoLepus)联名黑色印花棒球帽',
		},
		{
			url:
				'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/goods/hat_white.png',
			title: 'YKYB&嘎拉兔(GoLepus)联名白色印花棒球帽',
		},
		{
			url:
				'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/goods/hat_grey.png',
			title: 'YKYB&嘎拉兔(GoLepus)联名灰色印花棒球帽',
		},
		{
			url:
				'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/goods/T_shirt_black.png',
			title: 'YKYB&嘎拉兔(GoLepus)联名黑色潮T-shirt',
		},
		{
			url:
				'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/goods/T_shirt_white.png',
			title: 'YKYB&嘎拉兔(GoLepus)联名白色潮T-shirt',
		},
		{
			url:
				'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/goods/rabbit.png',
			title: 'YKYB&嘎拉兔(GoLepus)潮流手办',
		},
	]

	return (
		<div className="goodsSwiperStyle_pc">
			<div className="prev">
				<LeftOutline className="icon" fontSize={20} />
			</div>
			<div className="next">
				<RightOutline className="icon" fontSize={20} />
			</div>
			<Swiper
				spaceBetween={20}
				slidesPerView={4}
				speed={1000}
				loop
				autoplay
				navigation={{ prevEl: '.prev', nextEl: '.next' }}
				touchMoveStopPropagation
				pagination={{
					el: '.swiper-pagination',
					type: 'bullets',
					clickable: true,
				}}
			>
				{partnerLogo.map(({ url, title }, index) => {
					return (
						<SwiperSlide key={index}>
							<GoodsItemCard img={url} name={title} />
						</SwiperSlide>
					)
				})}
				<div className="swiper-pagination"></div>
			</Swiper>
		</div>
	)
}

export default GoodsSwiper
