import API from '@service/index'
import { getPhoneType } from '@utils/common'
import React from 'react'
import { useEffect, useRef, useState } from 'react'

declare const window: { [p: string]: any }
const appId = '9zm93l'
const appKey = '75fa6ad5ac081c0d46f6c6edbb320c37'
interface EventNameEnumProp {
	PaaSRoomEnter: string //有用户进入或离开直播间
	PaaSChatReceiveComment: string //收到弹幕
	PaaSChatCustomMessage: string //收到自定义消息
	PaaSLiveCreate: string //创建直播
	PaaSLivePublish: string //直播开始
	PaaSLiveStop: string //直播结束
	PaaSLiveStreamStart: string //直播推流事件（1.8.0新增
	PaaSLiveStreamStop: string //直播断流事件 1.8.0新增
	PaaSPlayerEvent: string //播放器事件  https://help.aliyun.com/document_detail/125572.htm?spm=a2c4g.11186623.0.0.754f5398dncn2C#task-1997027/section-f9f-p36-vlb
}
export interface ChatItem {
	creatorNick: string
	creatorId: string
	createAt: number
	content: string
	commentId: string
	topicId: string
	type: number
	extension: any
}

const useLiveRoom = () => {
	const roomChannel = useRef<any>()
	const RoomPaasSdk = useRef<{
		RoomEngine: any
		EventNameEnum?: EventNameEnumProp
	}>({ RoomEngine: {} })
	const getTokenApi = API.id56026

	const myRef = useRef<{ refreshTime: number; roomId: any }>({
		refreshTime: 0,
		roomId: undefined,
	})
	const [chatList, setChatList] = useState<ChatItem[]>([])
	const [canTalk, setCanTalk] = useState(false)
	const mgsListRef = React.useRef<HTMLDivElement>(null)
	useEffect(() => {
		return () => {
			roomChannel.current?.leaveRoom()
			const roomEngine = getInstance()
			roomEngine?.logout()
		}
	}, [])
	useEffect(() => {
		if (window.RoomPaasSdk) {
			const { RoomEngine, EventNameEnum } = window.RoomPaasSdk
			RoomPaasSdk.current = { RoomEngine, EventNameEnum }
			initAction()
			// joinRoom('123456','小天才手彪')
		}
	}, [window.RoomPaasSdk])

	const scrollToBottom = React.useCallback(() => {
		setTimeout(() => {
			const $msgList = mgsListRef.current
			if ($msgList && $msgList.firstElementChild) {
				// $msgList.firstElementChild.scrollTop = $msgList.firstElementChild.scrollHeight;
				$msgList.scrollTo(0, $msgList.firstElementChild.scrollHeight)
			}
		}, 200)
	}, [])

	const channelWatch = () => {
		const cService = getChatService()
		cService.remove(RoomPaasSdk.current.EventNameEnum.PaaSChatReceiveComment)
		cService.on(
			RoomPaasSdk.current.EventNameEnum.PaaSChatReceiveComment,
			(data) => {
				const value = new Date().valueOf() - myRef.current.refreshTime
				if (value > 999) {
					getChatList()
				}
			}
		)
	}

	const getInstance = () => {
		return RoomPaasSdk.current.RoomEngine?.getInstance()
	}
	const initAction = () => {
		// 获取RoomEngine实例
		const roomEngine = getInstance()
		// 获取设备号deviceId，唯一标识当前设备
		// 在web端，不同的tab页代表不同设备  如果场景确保一个设备只有一个页面，需要使用getSingleDeviceId
		const phoneType = getPhoneType()
		const deviceId = phoneType
			? roomEngine.getSingleDeviceId()
			: roomEngine.getDeviceId()
		// 配置参数
		const config = {
			appKey, // 创建应用时分配的appKey
			appId, // 创建应用时分配的appId
			deviceId, // 设备号
			authTokenCallback: () => authTokenCallback(deviceId), // 获取登录token的回调函数，需要返回Promise
		}
		// 传入配置
		roomEngine.init(config)
	}
	const authTokenCallback = (deviceId: any) => {
		return getTokenApi(
			{ deviceId },
			{ headers: !!window['YoTown'] ? {} : { 'visitor-mode': 1 } }
		).then((res: any) => {
			if (res) {
				const { roomId, ...authToken } = res
				myRef.current.roomId = roomId
				return authToken
			} else {
				throw new Error('token is null')
			}
		})
	}

	const joinRoom = (uid: any, nickName: any) => {
		const roomEngine = getInstance()
		roomEngine.auth(uid).then(
			(res: any) => {
				// 在这里获取RoomChannel实例进行房间操作
				getRoomChannel(roomEngine, nickName, myRef.current.roomId)
			},
			(err: any) => {
				console.log('eeeee', err)
			}
		)
	}
	// const testJoinRoom = async () => {
	// 	const roomEngine = getInstance()
	// 	// roomId标识房间号，方法调用结果获取当前房间roomChannel实例
	// 	console.log('ppp', roomEngine)
	// 	roomEngine.auth('634934').then(
	// 		(res: any) => {
	// 			// 在这里获取RoomChannel实例进行房间操作
	// 			getRoomChannel(
	// 				roomEngine,
	// 				'大美丽',
	// 				'3846c1f7-f2bc-455d-9946-d646720f9543'
	// 			)
	// 		},
	// 		(err: any) => {
	// 			console.log('eeeee', err)
	// 		}
	// 	)
	// }

	// 监听退出全屏事件
  const cancelFullScreen = (liveService: any) => {
		const reOpenPlay = () => {
			console.log('reOpenPlay')
			try {
				liveService.play()
			} catch (error) {
				console.log(error)
			}
		}
		// liveService.remove('cancelFullScreen', reOpenPlay)
		liveService.on('cancelFullScreen', reOpenPlay)
	}

	const getRoomChannel = async (
		roomEngine: any,
		nickName: string,
		roomId: string
	) => {
		roomChannel.current = roomEngine.getRoomChannel(roomId)
		channelWatch()

		await roomChannel.current.enterRoom(nickName)
		setCanTalk(true)

		// 获取Live插件实例
		const liveService = getLiveService()
		getChatList()
		liveService.setPlayerConfig({
			container: '#youtangplayer', // DOM容器id
		})
		try {
      await liveService.tryPlayLive()
			liveService.getLiveDetail().then((res) => {
        console.log('直播详情', res)
      })
      console.log('liveService', liveService)
      
      cancelFullScreen(liveService)
		} catch (error) {
			console.log('我错哪了', error)
		}
		// liveService.tryPlayLive().then(
		//   (res: any) => {
		//     console.log("我对哪了", res);
		//     liveService.getLiveDetail().then((res) => {
		//       console.log("直播详情", res);
		//     });
		//   },
		//   (error) => {
		//     console.log("我错哪了", error);
		//     liveService.switchPlaySource('http://yotownt3.yowant.net/live/7b7c62d4-ac85-461b-858a-141603bc9d54.flv?uid=yowant')
		//   }
		// );
	}
	const getChatService = () => {
		return roomChannel.current?.getPluginService('chat')
	}
	const getLiveService = () => {
		return roomChannel.current?.getPluginService('live')
	}
	const getChatList = async () => {
		const res = await getChatService().listComment(1, 1, 200)
		if (res?.commentModelList) {
			setChatList(res.commentModelList)
			scrollToBottom()
		}
	}
	const sendMsg = (msg: string) => {
		getChatService()?.sendComment(msg)
	}
	return {
		chatList,
		canTalk,
		sendMsg,
		mgsListRef,
		joinRoom,
	}
}

export default useLiveRoom
