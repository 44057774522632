
      /**
      * 提升中奖概率
      * 
      * /app/activity/draw/ratio/promote
      **/
      
    import request from '../utils/request.ts'
    import {getHost,reduceParams,querystring} from '@ywfe/utils'
    const {basePath = '', loginPath = ''} = getHost('COMMON', '455')
    export default (inputs={},config={}) => request['get'](`${basePath}/nft-biz/app/activity/draw/ratio/promote?${querystring.stringify(reduceParams(inputs))}`, {loginHost: loginPath,...config})