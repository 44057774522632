import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Input } from 'antd-mobile'
import { Toast } from 'antd-mobile/2x'
import Clipboard from 'clipboard'
import './style/index.less'
import API from '@service/index'
import { getQueries } from '@ywfe/utils'
import MaskWeixin from '@components/MaskWeixin'
import { isInWeixin } from '@utils/common'
import { useDownloadJump } from '@components/downloadApp/useDownloadJump'
import { CLICK_TRACK } from '@track/index'

export default ({ history }: any) => {
	const { downloadJump } = useDownloadJump()
	const { inviteCode, registerStatus } = getQueries()
	const [form] = Form.useForm()
	const sendCodeApi = API.id56178
	const registerApi = API.id56186
	const downloadInfo = API.id55786
	const [agree, setAgree] = useState(false)
	const timeHandler = useRef({ time: undefined, count: 60 })
	const [timeStr, setTimeStr] = useState('')
	const [isWeixin, setIsWeixin] = useState(false)
	const [status, setStatus] = useState<0 | 1 | 2 | 3 | 4>(0) // 0 等待接口 1 点击领取邀请码 2 注册 3 注册成功 去下载  4已注册去下载
	const [info, setInfo] = useState<any>({})
	const inputsRef = useRef<any>({})
	useEffect(() => {
		onCopy()
		initAction()
		document.title = '集潮气，兑潮礼'
		return () => {
			clearInterval(timeHandler.current.time)
		}
	}, [])
	const initAction = async () => {
		const res = await getInfo()
		if (+registerStatus > 0) {
			setStatus(+registerStatus === 1 ? 3 : 4)
		} else {
			if (res.isBeta) {
				setStatus(1)
			} else {
				setStatus(2)
				form.setFieldsValue({ inviteCode: inviteCode ?? '' })
			}
		}
	}
	const onCopy = () => {
		const copy = new Clipboard('.copy-btn')
		copy.on('success', (e) => {
			Toast.show({
				content: <div>复制成功！</div>,
				position: 'top',
			})
		})
		copy.on('error', function (e) { })
	}
	const jumpAgreement = (type) => {
		// window.open(`/agreement?type=${type}`, '_blank');
		if (type === 'service') {
			window.open(`/agreement/service`, '_blank')
		} else {
			window.open(`/agreement/privacy`, '_blank')
		}
	}
	const sendCode = async () => {
		const phone = form.getFieldValue('phone')
		console.log(phone, /^1\d{10}$/.test(phone))
		if (!/^1\d{10}$/.test(phone)) {
			Toast.show({
				content: '请输入正确的手机号',
			})
		} else if (!timeStr) {
			Toast.show({
				icon: 'loading',
				content: '发送中...',
			})
			const res: any = await sendCodeApi({ phone: phone })
			if (res) {
				const registed = +res.code === 101002
				if (registed) {
					goDownLoadStatus(true)
					Toast.clear()
				} else if (+res.code !== 0) {
					Toast.show({
						icon: 'fail',
						content: res.errorMsg || '服务器正忙~',
					})
				} else {
					timeStart()
					setTimeStr(timeHandler.current.count + '')
					Toast.clear()
				}
			}
		}
	}
	const goDownLoadStatus = (registed: boolean) => {
		// setStatus(registed ? 4 : 3);
		const url = window.location.href
		window.location.href =
			url +
			`${url.indexOf('?') === -1 ? '?' : '&'}registerStatus=${registed ? 2 : 1}`
	}

	const timeStart = () => {
		timeHandler.current.time = setInterval(() => {
			let count = timeHandler.current.count
			count = count - 1
			if (count >= 0) {
				const str = (count >= 10 ? '' : '0') + count
				console.log(str)
				setTimeStr(str)
				timeHandler.current.count = count
			} else {
				cleanTime()
			}
		}, 1000)
	}
	const cleanTime = () => {
		clearInterval(timeHandler.current.time)
		timeHandler.current.count = 60
		setTimeStr('')
	}
	const onRegister = async () => {
		cleanTime()
		const values = form.getFieldsValue()
		if (!/^1\d{10}$/.test(values.phone || '')) {
			Toast.show({
				content: '请输入正确的手机号',
				icon: 'fail',
			})
		} else if (!values.code) {
			Toast.show({
				content: '请输入短信验证码',
				icon: 'fail',
			})
		} else if (!values.inviteCode && info.needInviteCode) {
			Toast.show({
				content: '请输入邀请码（选填）',
				icon: 'fail',
			})
		} else if (!agree) {
			Toast.show({
				content: '请阅读并同意相关条款',
				icon: 'fail',
			})
		} else {
			Toast.show({
				icon: 'loading',
				content: '注册中…',
				duration: 0,
			})
			const res: any = await registerApi(values)
			if (res) {
				// 点击下载按钮
				CLICK_TRACK(21002)
				const registed = +res.code === 101002
				if (+res.code === 0 || registed) {
					goDownLoadStatus(registed)
					Toast.clear()
				} else {
					Toast.show({
						icon: 'fail',
						content: res.errorMsg || '服务器正忙~',
					})
				}
			}
		}
	}
	const onService = () => {
		jumpAgreement('service')
	}
	const onPrivacy = () => {
		jumpAgreement('other')
	}
	const betaGetCode = () => {
		window.open(`/download`, '_blank')
	}
	const onAgreement = () => {
		setAgree(!agree)
	}
	const getInfo = async () => {
		const res = await downloadInfo()
		if (res) {
			setInfo(res)
		}
		return Promise.resolve(res)
	}
	const downloadApp = async () => {
		if (isInWeixin()) {
			setIsWeixin(true)
		} else {
			downloadJump()
		}
	}
	const getShareaView = () => {
		return (
			<div className="shareinviteView">
				<div className="codeView">
					<div className="cardbg">
						<div className="title">专属邀请码</div>
						<div className="item">
							<div className="code">{inviteCode}</div>
							<button
								data-clipboard-text={inviteCode}
								className="copy-btn"
								type="button"
							>
								<div className="copy"></div>
							</button>
						</div>
					</div>
					<button
						data-clipboard-text={inviteCode}
						className="copy-btn"
						type="button"
					>
						<Button className="inviteButton" onClick={betaGetCode}>
							点击领取邀请码
						</Button>
					</button>
				</div>
			</div>
		)
	}
	const handleClickDom = (type: string) => {
		inputsRef.current[type].focus()
	}
	const getRegisterView = () => {
		return (
			<div className="registerView">
				<Form form={form} layout="horizontal" className="formClass">
					<Form.Item name="phone" >
						<Input
							placeholder="请输入手机号码"
							ref={(r) => {
								inputsRef.current.phone = r
							}}
							onClick={() => {
								handleClickDom('phone')
							}}
							maxLength={11}
						/>
					</Form.Item>
					<Form.Item
						name="code"
						extra={
							<div className={`sendcode ${timeStr ? 'sendTime' : ''}`}>
								<div onClick={sendCode}>
									{timeStr ? timeStr : '发送验证码'}{' '}
								</div>
							</div>
						}
					>
						<Input
							placeholder="请输入短信验证码"
							ref={(r) => {
								inputsRef.current.code = r
							}}
							maxLength={6}
							onClick={() => {
								handleClickDom('code')
							}}
						/>
					</Form.Item>
					<Form.Item name="inviteCode">
						<Input
							placeholder={`请输入邀请码${info.needInviteCode ? '（必填）' : ''
								}`}
							ref={(r) => {
								inputsRef.current.inviteCode = r
							}}
							onClick={() => {
								handleClickDom('inviteCode')
							}}
						/>
					</Form.Item>
				</Form>
				<Button className="registerButton" onClick={onRegister}>
					注册
				</Button>
				<div className="agreementView">
					<div className="acview" onClick={onAgreement}>
						<div className="agreeNormal">
							{agree && <div className="agree"></div>}
						</div>
					</div>
					<div className="desc">
						我已同意 <span onClick={onService}>《柚堂用户服务协议》</span> 及
						<span onClick={onPrivacy}>《隐私政策》</span>
					</div>
				</div>
			</div>
		)
	}
	const getDownloadView = () => {
		return (
			<div className="floatView">
				<div className="centerView">
					<div className={status === 3 ? 'icon' : 'icon1'}></div>
					<div className="title">{status === 3 ? '注册成功' : '已注册'} </div>
					<div className="desc">去柚堂APP抢限量明星限量潮玩</div>
					<Button onClick={downloadApp}>下载</Button>
				</div>
			</div>
		)
	}
	return (
		<div className="shareinviteView">
			<div className='header-message-imfor'>
				<img src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/registration/logo.png" alt="" className='logo-img' />
				<div className='header-title'>
					<div className='welcome'>欢迎来到柚堂</div>
					<div className='header-context'>识明星潮物，赢限量潮玩</div>
				</div>
			</div>
			{status === 1 && getShareaView()}
			{status > 1 && getRegisterView()}
			{(status === 3 || status === 4) && getDownloadView()}
			{isWeixin && (
				<MaskWeixin
					onClick={() => {
						setIsWeixin(false)
					}}
				/>
			)}
		</div>
	)
}
