import React from "react";

const ItemCard = (props) => {
  const { item } = props;
  return <div style={{
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }}>
    <img
      src={item?.awardImg}
      alt=""
      className='goods-img'
    />
    <div className='goods-title'>{item.awardName}</div>
    <div className="price-goods">
      <div className="label">市场价 &nbsp;</div>
      <div className="symbol">¥</div>
      <div className="pricenum">{item?.awardDesc}</div>
    </div>
  </div>
}

export default ItemCard