import Vconsole from 'vconsole'

import { getEnv } from '@ywfe/utils'
const inAPP = () => {
	return !!window['YoTown']
}
const env = getEnv()
if (env !== 'prod' && inAPP()) {
	new Vconsole()
}
