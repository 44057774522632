import React, { useEffect, useRef, useState } from 'react'
import code from '../../../assets/result/result-code.png'
import play from '../../../assets/result/result-play.png'
import logo from '../../../assets/result/result-logo.png'
import rightLogo from '../../../assets/result/result-right-logo.png'
import tengxun from '../../../assets/result/tengxun.png'
import noGoods from '../../../assets/result/no-good.png'
import BackButton from '@components/backButton'

import DownloadApp from '@components/downloadApp/download_white'

import { getWebSystem, isInWeixin } from '@utils/common'
import useJsBridge from '@utils/useJsBridge'
import { useDownloadJump } from '@components/downloadApp/useDownloadJump'
import './style/index.less'
import API from '@service/index'
import { getQueries } from '@ywfe/utils'
import { Button, DotLoading, Popup, SpinLoading } from 'antd-mobile'
import { Toast } from 'antd-mobile/2x'
import { CloseOutline, CheckCircleFill } from 'antd-mobile-icons'
import MaskWeixin from '@components/MaskWeixin'
import { Player } from 'video-react'
import 'video-react/dist/video-react.css'

const homeQcode =
	'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/result/homeQcode.png'
const wxQcode =
	'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/result/wxgzh.png'

const SecretKeyIcon =
	'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/result/key.png'

const ResultNew = ({ history }: any) => {
	const inputsRef = useRef({
		pre: null,
	})
	const isNative = !!window['YoTown']
	const { downloadJump } = useDownloadJump()
	const { bridgePostAppBar, bridgePostToken, bridgePostToPage } = useJsBridge()
	const system = getWebSystem()
	const params: any = getQueries()
	const [hasToken, setHasToken] = useState(false)
	const [inWx, setInWx] = useState(false)
	const [openVideo, setOpenVideo] = useState(false)
	const [loading, setLoading] = useState(false)
	// pop弹窗
	const [popupVisible, setPopupVisible] = useState(false)
	// 是否绑定成功
	const [isSuccessBind, setIsSuccessBind] = useState(false)
	// 密钥输入框
	const [secretKey, setSecretKey] = useState('')
	// 绑定按钮loading
	const [bindLoad, setbindLoad] = useState(false)

	const [goodInfos, setGoodInfos] = useState({
		productImgUrl: '',
		productName: '',
		userNftCode: '',
		secretKey: false,
		canBinding: false,
	})
	const player = useRef<any>({})

	const changeSecretKey = (e) => {
		setSecretKey(e.target.value)
	}
	/* Loaded */
	useEffect(() => {
		getGoodInfos()
	}, [])
	const lookAction = () => {
		// TODO 测试跳转
		// bridgePostToPage('/cabinet/index', {
		// 	index: 1,
		// })
		setOpenVideo(true)
	}
	const doSecretKeybind = () => {
		if (isNative) {
			if (hasToken) {
				setPopupVisible(true)
			} else {
				// 跳去app登陆
				bridgePostToPage('/login/main', {
					needLogin: true,
				})
			}
		} else {
			const bol = isInWeixin()
			if (bol) {
				setInWx(bol)
			} else {
				downloadJump()
			}
		}
	}

	// 绑定接口
	const bindSecret = async () => {
		if (bindLoad) {
			return
		}
		if (goodInfos.secretKey && !secretKey) {
			Toast.show({
				content: '请输入密钥',
			})
			return
		}
		const req = {
			...params,
			secretKey,
		}
		setbindLoad(true)
		const res = await API.id58682(req).finally(() => {
			setbindLoad(false)
		})

		setbindLoad(false)
		if (res) {
			setIsSuccessBind(true)
		}
	}

	// 跳去展柜
	const go2MyStoreRoom = () => {
		bridgePostToPage('/cabinet/index', {
			index: 1,
		})
	}

	// 跳去app登陆
	const testLogin = () => {
		bridgePostToPage('/login/main', {
			needLogin: true,
			needReload: true,
		})
	}

	// 获得nfc商详
	const getGoodInfos = async () => {
		if (isNative) {
			bridgePostAppBar({ hideAppBar: true })
			const tokenRes = await bridgePostToken()
			const token = tokenRes.result?.data
			localStorage.setItem('token', token)
			if (token) {
				setHasToken(token)
			}
		}
		setLoading(true)
		const res: any = await API.id50310(params)
		if (res && res.userNftCode) {
			setGoodInfos(res)
			setLoading(false)
		}
		setLoading(false)
	}

	const getNoneView = () => {
		return (
			<div className={`none-content ${isNative ? '' : 'haveDownload'}`}>
				<img src={noGoods} alt="" />
				<div className="title">未查到对应产品</div>
				<div className="desc">请重新用手机靠近识别区域</div>
				<ActionButton />
			</div>
		)
	}

	// 操作按钮
	const ActionButton = () => (
		<div
			className={`flex-betwen ${isNative &&
				goodInfos.canBinding &&
				'isbetween'}`}
		>
			<div className="button" onClick={lookAction}>
				<img src={play} alt="" />
				<div>观看APP宣传片</div>
			</div>
			{isNative && goodInfos.canBinding ? (
				<div className="button secretKey" onClick={doSecretKeybind}>
					<img src={SecretKeyIcon} alt="" />
					<div>密钥校验绑定</div>
				</div>
			) : null}
		</div>
	)

	const getHaveDataView = () => {
		return (
			<div className={`content ${isNative ? '' : 'haveDownload'}`}>
				<div className="headerView">
					<img src={goodInfos.productImgUrl} alt="" />
					<div className="goods-code">
						<img src={code} alt="" />
						<div>商品编码：{goodInfos.userNftCode ?? '-'}</div>
					</div>
				</div>
				<div className="desc">{goodInfos.productName}</div>
				<div className="tengxun">
					<img src={tengxun} alt="" />
					<div>Yotwon提供技术鉴定支持</div>
				</div>
				<ActionButton />
			</div>
		)
	}
	return (
		<div className="resultView">
			<div
				style={system === 'pc' ? { maxWidth: 750 } : undefined}
				className="resultView_first_child"
			>
				<BackButton />
				{goodInfos.productImgUrl && (
					<img className="bg-current" src={goodInfos.productImgUrl} alt="" />
				)}
				<img className="logo" src={logo} alt="" />
				<img className="left-logo" src={rightLogo} alt="" />
				<img className="right-logo" src={rightLogo} alt="" />
				{loading ? (
					<div className="main-result-m-header-load">
						获取数据中
						<span style={{ fontSize: 18 }}>
							<DotLoading color={'#6c6aeb'} />
						</span>
					</div>
				) : (
					<>
						{goodInfos.productImgUrl && goodInfos.userNftCode
							? getHaveDataView()
							: getNoneView()}
					</>
				)}
				{!isNative ? (
					<div className="QcodeGroup">
						<div className="QcodeWrap">
							<img className="QcodeImg" src={homeQcode} alt="" />
							<span className="QcodeName" onClick={testLogin}>
								柚堂官网
							</span>
						</div>
						<div className="QcodeWrap">
							<img className="QcodeImg" src={wxQcode} alt="" />
							<span className="QcodeName">柚堂公众号</span>
						</div>
					</div>
				) : (
					<div className="bindingDescription">
						<div className="title"> 绑定说明</div>
						<ul className="list">
							<li className="listItem"> 通过密钥绑定后可获取该未铸造商品；</li>
							<li className="listItem">
								商品转移成功后，将从被转移用户展柜移除；
							</li>
							<li className="listItem"> 单个商品的转移次数不设限。</li>
						</ul>
					</div>
				)}
			</div>
			{!isNative && <DownloadApp />}
			{inWx && (
				<MaskWeixin
					onClick={() => {
						setInWx(false)
					}}
				/>
			)}

			{/* 输入密钥 S */}
			<Popup
				visible={popupVisible}
				onMaskClick={() => {
					setPopupVisible(false)
				}}
				getContainer={null}
				key="rules"
				bodyStyle={{
					borderTopLeftRadius: '8px',
					borderTopRightRadius: '8px',
					// minHeight: '40vh',
				}}
			>
				{isSuccessBind ? (
					<div className="bingsuccess">
						<div
							className="close"
							onClick={() => {
								setPopupVisible(false)
							}}
						>
							<CloseOutline fontSize={20} color="#ADAAAD" />
						</div>
						<div className="okIcon">
							<CheckCircleFill fontSize={90} color="#07c160" />
						</div>
						<div className="title">绑定成功</div>
						<div className="text">前往 我的展柜-未铸造 列表查看</div>
						<Button className="jumpBtn" onClick={go2MyStoreRoom}>
							去查看
						</Button>
					</div>
				) : (
					<div className="secretContent">
						<div className="top">
							<div className="title">校验绑定</div>
							<div
								className="close"
								onClick={() => {
									setPopupVisible(false)
								}}
							>
								<CloseOutline fontSize={20} color="#ADAAAD" />
							</div>
						</div>
						<img className="img" src={goodInfos.productImgUrl} alt="" />
						{/* 判断是否需要输入 */}
						{goodInfos.secretKey ? (
							<div className="inputContent">
								<input
									className="myinput"
									type="text"
									ref={(r) => {
										inputsRef.current.pre = r
									}}
									disabled={bindLoad}
									value={secretKey}
									onChange={changeSecretKey}
									onClick={() => {
										inputsRef.current.pre.focus()
									}}
								/>
							</div>
						) : (
							<div className="noInputText">该商品未被绑定过，可直接绑定。</div>
						)}

						<div className="bottomGropu">
							<div
								className="btn close"
								onClick={() => {
									setPopupVisible(false)
								}}
							>
								取消
							</div>
							<div
								className={`btn bind ${bindLoad && 'bindLoad'} `}
								onClick={bindSecret}
							>
								{bindLoad ? (
									<div className="flex-center">
										<SpinLoading />
										<div className="text">绑定中</div>
									</div>
								) : (
									'绑定'
								)}
							</div>
						</div>
					</div>
				)}
			</Popup>
			{/* 输入密钥 E */}
			<div className="palyerView" hidden={!openVideo}>
				<div>
					<Player
						ref={(c) => {
							player.current = c
						}}
						autoPlay={false}
						loop={true}
						playsInline={false}
						src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/video/guide.mp4"
						poster="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/scren-1.png"
					></Player>
				</div>
				<Button
					className="closeButton"
					fill="solid"
					onClick={() => {
						setOpenVideo(false)
						player.current?.load()
					}}
				>
					关闭
				</Button>
			</div>
		</div>
	)
}

export default ResultNew
