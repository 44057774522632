import DownloadApp from '@components/downloadApp'
import API from '@service/index'
import { getPhoneType, sleep } from '@utils/index'
import useJsBridge from '@utils/useJsBridge'
import { Button, Divider, Form, Input, Tabs, Tag } from 'antd-mobile'
import { Toast } from 'antd-mobile/2x'
import React, { useEffect, useRef, useState } from 'react'
import useLiveRoom from './liveRoom'
import './styles.less'
import TalkList from './talkList'
const LiveRoom = ({ history }: any) => {
	const { chatList, sendMsg, canTalk, mgsListRef, joinRoom } = useLiveRoom()
	const {
		bridgePostToken,
		bridgePostUserInfo,
		bridgePostToPage,
		bridgePostAppBar,
		isNative,
	} = useJsBridge()
	const getCodeApi = API.id55623

	// 发布会领取空投的icon，在发布会开始之前和完全结束之后，不再展
	const [showIcon, setShowIcon] = useState<boolean>(false)

	const [liveInfo, setLiveInfo] = useState<any>({})
	const [tabKey, setTabKey] = useState('talk')
	const [currentTime, setCurrentTime] = useState('0:00')
	const [currentGoods, setCurrentGoods] = useState<any>({})
	const [activityEnd, setActivityEnd] = useState(false)
	const [codeInfo, setCodeInfo] = useState<any>({})
	const timeRef = useRef({
		timer: null,
		count: 0,
		everyTime: 0,
		time: 0,
		userReceiveStatus: 0,
	})
	const colors = {
		'10': '#666666',
		'20': '#14E176',
		'30': '#6622FF',
		'40': '#FFAA00',
	}
	useEffect(() => {
		getNativeFunc()
		return () => {
			clearInterval(timeRef.current.timer)
		}
	}, [])
	const inAPP = () => {
		return !!window['YoTown']
	}
	const getNativeFunc = async () => {
		if (inAPP()) {
			try {
				const res = await bridgePostToken()
				const token = res.result?.data
				if (token) {
					localStorage.setItem('token', token)
					getInfo(true)
				}
				const info = await bridgePostUserInfo()
				const uId = info.result?.data.uId || ''
				const nick = info.result?.data.nick || ''
				joinRoom(uId, nick)
			} catch (error) {}
		} else {
			getInfo(true)
		}
	}
	const getInfo = async (isFirst = false) => {
		const res: any = await API.id55616()
		if (res) {
			setLiveInfo(res)
			getWithTime(res)
			if (isFirst) {
				if (res.activityName && document.title !== res.activityName) {
					document.title = res.activityName
				}
				bridgePostAppBar({
					actions: [
						{
							icon: 'ic_my_share.png',
							onTap: 'share',
							shareUrl: window.location.href,
							shareTitle: res.activityName,
							shareDesc: res.activityDesc,
						},
					],
				})
				if (!inAPP()) {
					joinRoom(res?.visitorUId || '123456', '游客')
				}
			}
		}
	}

	const getWithTime = async (res: any) => {
		clearInterval(timeRef.current.timer)
		timeRef.current.userReceiveStatus = res.userReceiveStatus
		if (res.userReceiveStatus === 2) {
			setCurrentTime('已领完')
			setActivityEnd(true)
			return
		}
		if (res.userReceiveStatus === 1) {
			setCurrentTime('领取')
		}
		const endTime = +res.endAt
		const startTime = +res.startAt
		const cTime = +res.currentTime
		const activityStartTime = +res.activityStartTime
		const count = res.products?.length || 0
		console.log('整啊', endTime > cTime, count)
		setShowIcon(false)
		if (activityStartTime > cTime) {
			console.time('s')
			await sleep(activityStartTime - cTime)
			console.timeEnd('s')
		}
		setShowIcon(true)
		if (count > 0 && endTime > cTime) {
			const everyTime = Math.floor((endTime - startTime) / count)
			const trueCount = (endTime - cTime) / everyTime
			let currentCount = Math.floor(trueCount)
			let firstTime = everyTime
			if (trueCount > currentCount) {
				firstTime = endTime - currentCount * everyTime - cTime
				currentCount += 1
			}
			timeRef.current.count = currentCount
			timeRef.current.time = Math.floor(firstTime / 1000)
			timeRef.current.everyTime = Math.floor(everyTime / 1000)
			setCurrentGoods(res.products[count - currentCount])
			timeStart()
		} else if (endTime <= cTime) {
			setActivityEnd(true)
		}
	}

	const timeStart = () => {
		timeRef.current.timer = setInterval(() => {
			const time = timeRef.current.time
			if (time > 0) {
				const minutes = Math.floor(time / 60)
				const seconds = time - minutes * 60
				const timeStr = `${minutes > 9 ? minutes : '0' + minutes}:${
					seconds > 9 ? seconds : '0' + seconds
				}`
				if (timeRef.current.userReceiveStatus === 0) {
					setCurrentTime(timeStr)
				}
				timeRef.current.time = time - 1
			} else {
				timeRef.current.count -= 1
				if (timeRef.current.count > 0) {
					timeRef.current.time = timeRef.current.everyTime
					const info = { ...liveInfo }
					info.userReceiveStatus = 1
					timeRef.current.userReceiveStatus = 1
					setLiveInfo(info)
					setCurrentTime('领取')
					if (
						Array.isArray(liveInfo.products) &&
						liveInfo.products.length - timeRef.current.count >= 0
					) {
						setCurrentGoods(
							liveInfo.products[
								liveInfo.products.length - timeRef.current.count
							]
						)
					}
				} else {
					if (timeRef.current.userReceiveStatus === 0) {
						setCurrentTime('00:00')
					}
					clearInterval(timeRef.current.timer)
					getInfo()
				}
			}
		}, 1000)
	}

	const tabsChange = (value: any) => {
		setTabKey(value)
	}

	const getBuyView = () => {
		return (
			<div
				className="floatGoods"
				hidden={tabKey !== 'talk'}
				onClick={() => {
					goNativeGoodsDetail(currentGoods?.productNo)
				}}
			>
				<img src={currentGoods?.headImgUrl} />
				<div className="title">{currentGoods?.title}</div>
				<div className="moneyV">
					<div className="money">￥{currentGoods?.price ?? '-'}</div>
					<div className="buy">买</div>
				</div>
			</div>
		)
	}
	const showCodeView = () => {
		return (
			<div className="codeView">
				<div className="cenerView">
					<div className="title">
						{codeInfo.userGetStatus ? 'AMAZING!' : '提示'}
					</div>
					{codeInfo.userGetStatus && (
						<>
							<img src={codeInfo.product?.headImgUrl} />
							<div className="gTitleView">
								<div className="gTitle">
									{getGoodsLevel(codeInfo.product)}
									{codeInfo.product?.title || '-'}
								</div>
								<div className="gCodeT">
									兑换码
									<span className="gCode">{codeInfo.couponCode || '-'}</span>
								</div>
							</div>
							<div className="desc">{codeInfo.product?.desc || '-'}</div>
						</>
					)}
					{!codeInfo.userGetStatus && (
						<div className="codeNoneView">
							<div className="codeNone"></div>
							<div className="none-title">来晚了，空投已抢完</div>
						</div>
					)}
					<div
						className="bottomView"
						style={{
							justifyContent:
								codeInfo.userGetStatus === 1 ? 'space-between' : 'center',
						}}
					>
						<Button
							className={codeInfo.userGetStatus ? 'shortButton' : 'longButton'}
							onClick={() => {
								setCodeInfo({ showCode: false })
							}}
						>
							{codeInfo.userGetStatus ? '开心收下' : '继续观看发布会'}
						</Button>
						{codeInfo.userGetStatus && (
							<Button
								onClick={() => {
									setCodeInfo({ showCode: false })
									goNativeCodeView()
								}}
							>
								我的兑换码
							</Button>
						)}
					</div>
				</div>
			</div>
		)
	}
	const getCodeNetwork = async () => {
		if (!inAPP()) {
			Toast.show('请下载app参与互动')
			return
		}
		if (liveInfo.userReceiveStatus !== 1) {
			return
		}
		Toast.show({
			icon: 'loading',
			content: '领取中…',
			duration: 0,
		})
		const res: any = await getCodeApi()
		Toast.clear()
		if (res) {
			setCodeInfo({ ...res, showCode: true })
			const info = { ...liveInfo }
			info.userReceiveStatus = res.userReceiveStatus
			timeRef.current.userReceiveStatus = res.userReceiveStatus
			setLiveInfo(info)
			if (res.userReceiveStatus === 2) {
				setCurrentTime('已领完')
				setActivityEnd(true)
				clearInterval(timeRef.current.timer)
			} else if (res.userReceiveStatus === 1) {
				setCurrentTime('领取')
			}
		}
	}
	const getTimeView = () => {
		return (
			<div
				className={`timeView ${
					liveInfo.userReceiveStatus === 2 ? 'tiemEnd' : ''
				}`}
				hidden={tabKey !== 'talk'}
				onClick={getCodeNetwork}
			>
				<div className="time"></div>
				<div className="timeN">{currentTime}</div>
			</div>
		)
	}
	const goNativeGoodsDetail = (productNo: any) => {
		if (inAPP()) {
			bridgePostToPage('/shop/detail', { productNo: productNo })
		} else {
			Toast.show('请下载app查看哦')
		}
	}
	const goNativeCodeView = () => {
		if (inAPP()) {
			bridgePostToPage('/mine/discount_records', {})
		} else {
			Toast.show('请下载app查看哦')
		}
	}
	const getGoodsView = () => {
		return (
			<div className="goodsList" hidden={tabKey !== 'goods'}>
				{liveInfo.products?.length > 0 &&
					liveInfo.products.map((item: any) => {
						return (
							<div
								className="goodsItem"
								key={item.productNo}
								onClick={() => {
									goNativeGoodsDetail(item.productNo)
								}}
							>
								<img src={item?.headImgUrl} />
								<div className="goodsTitle">
									{getGoodsLevel(item)}

									{item.title || '-'}
								</div>
								<div className="goodsMoney">￥{item.price || '-'}</div>
							</div>
						)
					})}
			</div>
		)
	}
	const getGoodsLevel = (item: any) => {
		return (
			!!item.rarityLevelDesc && (
				<Tag
					color={colors[item.rarityLevel + '']}
					style={{ marginRight: '6px' }}
				>
					{item.rarityLevelDesc}
				</Tag>
			)
		)
	}
	const getLiveDescView = () => {
		return (
			<div className="liveDesc" hidden={tabKey !== 'desc'}>
				{liveInfo?.aboutRelease?.map?.((item: any, index: number) => {
					return <img src={item} key={index} />
				})}
			</div>
		)
	}
	return (
		<div className="liveRoomView">
			{/* <div className='floatView'></div> */}
			<div className="liveView">
				<div className="liveScreen" id="youtangplayer"></div>
			</div>
			<div className="body">
				<Tabs
					className="tabs"
					activeLineMode="fixed"
					defaultActiveKey="talk"
					activeKey={tabKey}
					onChange={tabsChange}
				>
					<Tabs.Tab title="评论互动" key="talk"></Tabs.Tab>
					<Tabs.Tab title="联名产品" key="goods"></Tabs.Tab>
					<Tabs.Tab title="关于发布会" key="desc"></Tabs.Tab>
				</Tabs>
				<TalkList
					hidden={tabKey !== 'talk'}
					list={chatList}
					sendMsg={(msg: string) => {
						sendMsg(msg)
					}}
					canTalk={canTalk && inAPP()}
					mgsListRef={mgsListRef}
					changeGoods={() => {
						setTabKey('goods')
					}}
				/>
				{getGoodsView()}
				{getLiveDescView()}
			</div>
			{!inAPP() && tabKey !== 'desc' && <DownloadApp />}
			{!activityEnd && getBuyView()}
			{showIcon && !activityEnd && getTimeView()}
			{codeInfo.showCode && showCodeView()}
		</div>
	)
}
export default LiveRoom
