import React, { useCallback, useEffect, useState } from 'react'

type Props = {}

export function useHeight(rate): [number] {
	const [height, setHeight] = useState(0)

	const onResize = useCallback(() => {
		if (typeof window !== 'undefined') {
			setHeight(window.document.documentElement.clientWidth / rate)
		}
	}, [])

	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.addEventListener('resize', onResize, false)
			setHeight(window.document.documentElement.clientWidth / rate)
			return (): void => {
				window.removeEventListener('resize', onResize, false)
			}
		}
	}, [])

	return [height]
}
