import React from 'react'
import './index.less'
type Props = {
	img: string
	name: string
	onClick: () => void
}

const GoodsItemCard = (props: Props) => {
	const { name, img, onClick } = props
	return (
		<div className="goodsItemCard-h5">
			<div className="mainpng">
				<img src={img} alt="" />
			</div>
			<div className="name">{name}</div>
			<div className="download" onClick={onClick}>
				APP购买
			</div>
		</div>
	)
}

export default GoodsItemCard
