import React from 'react'
import ReactDOM from 'react-dom'
// 埋点
import '@track/track_init'
// 监控
// import '@utils/fundebug'
// 引入v-console调试器
import '@utils/vconsole'

import './index.css'
import App from './App'
import 'lib-flexible/flexible.js'

import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App />, document.getElementById('ywAppRoot'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
