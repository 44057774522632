import { Button, Form, Input, List } from 'antd-mobile'
import React, { useRef, useState } from 'react'
import { ChatItem } from './liveRoom'
import { getPhoneType } from '@utils/common'
interface TalkProp {
	list: ChatItem[]
	sendMsg: any
	canTalk: boolean
	mgsListRef: any
	hidden: boolean
	changeGoods: any
}
const isIOS = getPhoneType() === 'ios'
const TalkList = (props: TalkProp) => {
	const { list, sendMsg, canTalk, mgsListRef, hidden, changeGoods } = props
	const [form] = Form.useForm()
	const inputRef = useRef<{ input: any }>({ input: {} })
	const [kbOpen, setKbOpen] = useState(false)
	// const [num, setNum] = useState<number>(0)
	const getKeyboardView = () => {
		return (
			<div className="keyboardView">
				<div className="keyboard">
					<div className="iconView">
						<div className="icon" />
					</div>

					{/* {num} */}
					<Form form={form} layout="horizontal" className="formClass">
						<Form.Item name="msg">
							{isIOS ? (
								<Input
									placeholder="参与互动…"
									ref={(e) => {
										inputRef.current.input = e
									}}
									onClick={() => {
										setKbOpen(true)
										// setNum(num + 1)
										inputRef.current.input.focus()
									}}
									onBlur={() => {
										setTimeout(() => {
											setKbOpen(false)
										}, 100)
									}}
								/>
							) : (
								<Input
									placeholder="参与互动…"
									ref={(e) => {
										inputRef.current.input = e
									}}
									onFocus={() => {
										setKbOpen(true)
										// setNum(num + 1)
									}}
									onBlur={() => {
										setTimeout(() => {
											setKbOpen(false)
										}, 100)
									}}
								/>
							)}
						</Form.Item>
					</Form>
				</div>
				<Button
					className={kbOpen ? 'sendButton' : 'buyButton'}
					onClick={() => {
						if (kbOpen) {
							const value = form.getFieldValue('msg')
							if (value) {
								sendMsg(value)
								form.resetFields()
							}
						} else {
							changeGoods()
						}
					}}
				>
					{kbOpen && '发送'}
				</Button>
			</div>
		)
	}

	return (
		<>
			<div className="talkList" ref={mgsListRef} hidden={hidden}>
				<List>
					{list.map((item) => {
						return (
							<List.Item key={item.commentId}>
								<div className="talkItem">
									<div className="talkName">{item.creatorNick}</div>
									<div className="talkDetail">{item.content}</div>
								</div>
							</List.Item>
						)
					})}
				</List>
			</div>
			{canTalk && !hidden && getKeyboardView()}
		</>
	)
}

export default TalkList
