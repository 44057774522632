import React from 'react'
import { Context } from '../../context'
import Mobile from './mobile/Index'
import { useTrackView } from '@track/index'

export default function(props) {
	useTrackView()
	return (
		<Context.Consumer>
			{(value) =>
				value === 'pc' ? (
					<Mobile history={props.history} />
				) : (
					<Mobile history={props.history} />
				)
			}
		</Context.Consumer>
	)
}
