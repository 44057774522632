import { useEffect } from 'react'
import { routerView } from '@track/track_utils'

export const useTrackView = () => {
	useEffect(() => {
		routerView.open()
		const timeStart = new Date().getTime()
		return () => {
			console.log(123)

			if (timeStart) {
				const timestamp = new Date().getTime() - timeStart
				routerView.close(timestamp)
			}
		}
	}, [])
}
