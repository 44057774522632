import React, { useEffect } from 'react'
import './index.less'

import AMapLoader from '@amap/amap-jsapi-loader'

type Props = {}

const YTMap = (props: Props) => {
	useEffect(() => {
		AMapLoader.load({
			key: '6d5603b610ce54207fc07a35b6f21df4', // 申请好的Web端开发者Key，首次调用 load 时必填
			version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
			plugins: ['AMap.Geolocation'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
		})
			.then((AMap) => {
				window['map'] = new AMap.Map('YT-MAP-container', {
					zoom: 13, //级别
					center: [120.007646, 30.282226], //中心点坐标
					viewMode: '3D', //使用3D视图
				})

				var marker = new AMap.Marker({
					position: [120.007646, 30.282226], //位置
				})
				window['map'].add(marker) //添加到地图
			})
			.catch((e) => {
				console.log('地图加载错误', e)
			})
	}, [])
	return <div id="YT-MAP-container" />
}
export default YTMap
