import React, { useState } from 'react'
import MaskWeixin from '@components/MaskWeixin'
import { isInWeixin } from '@utils/common'
import { Button } from 'antd-mobile'
import logoImg from './../../assets/app-logo.png'
import { useDownloadJump } from './useDownloadJump'

import './styles.less'

const DownloadApp = () => {
	const { downloadJump } = useDownloadJump()
	const [inWx, setInWx] = useState(false)
	return (
		<>
			<div className="download-app-View">
				<div>
					<img src={logoImg} alt="" />
					<div>专属明星限量潮品平台</div>
				</div>

				<Button
					className="downloadButton"
					fill="solid"
					onClick={() => {
						if (isInWeixin()) {
							setInWx(true)
						} else {
							downloadJump()
						}
					}}
				>
					下载柚堂APP
				</Button>
			</div>
			{inWx && (
				<MaskWeixin
					onClick={() => {
						setInWx(false)
					}}
				/>
			)}
		</>
	)
}

export default DownloadApp
