import React, { useEffect, useState } from 'react'
import useJsBridge from '@utils/useJsBridge'
import './styles.less'
import { Divider, Popup, Toast } from 'antd-mobile'
import Popop from './components/Popup'
import { RightOutline, SendOutline, LeftOutline } from 'antd-mobile-icons'
import API from '@service/index'
import Modal from './components/Modal';
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import ProbabilityModel from './components/probabilityModel'
import AddressModal from './components/addressModal'
import 'swiper/swiper-bundle.css'
import { userInfo } from 'os'
import ItemCard from './components/itemGoods'

const DownLoadView = ({ history }: any) => {
	const {
		bridgePostToken,
		bridgePostToPage,
		bridgePostAppBar,
		bridgePostBackPage,
		bridgeShare
	} = useJsBridge({
		routeBack: (res) => {
			routeBack(res.result.data)
		}
	})

	//提升概率和抽奖次数弹窗
	const [visibale, setvisibale] = useState<boolean>(false);
	/*
	 * 抽奖次数还是中奖概率 
	 * 获得次数 false
	 * 提升概率 true
	*/
	const [numberProbability, setNumberProbability] = useState<boolean>(false);

	//中奖名单列表弹窗
	const [winningList, setWinningList] = useState<boolean>(false)
	//参与抽奖弹窗
	const [participateModel, setParticipateModel] = useState<boolean>(false);
	const [inviteCode, setInviteCode] = useState<string>('')
	// 概率提升弹窗
	const [probabilityModel, setProbabilityModel] = useState<boolean>(false);
	// 活动规则弹窗
	const [activeRules, setActiveRules] = useState<boolean>(false);
	//填写地址弹窗
	const [addressVisibel, setAddressVis] = useState<boolean>(false);
	//活动详情数据
	const [activeInfo, setActiveInfo] = useState<any>({});
	//抽签编号
	const [lotteryNo, setLotteryNo] = useState<any>({});
	//提升概率valvue
	const [upValue, setUpValue] = useState<any>({})
	/*
	 * 渲染活动状态按钮
	 * 1 已开始
	 * 2 已结束
	 * 3 未开始
	 * 4 已开奖 已中奖
	 * 5 已开奖 未抽中
	 * 6 已抽取 待开奖
	*/


	//关闭弹窗
	const initData = () => {
		setParticipateModel(false);
		setNumberProbability(false);
		setvisibale(false);
		setWinningList(false);
		setProbabilityModel(false);
		setActiveRules(false);
	}
	const routeBack = async (address) => {
		// const address = JSON.parse(localStorage.getItem('address')) ?? {}
		if (Object.keys(address).length) {
			const res = await API.id63026({
				receiver: address?.name,
				phone: address?.phone,
				address: address?.area,
				regionInfo: address?.address
			})
			if (res) {
				getNativeFunc()
				activeDetails()
			}
		}


	}
	const renderActiviteStateButton = (state) => {
		switch (state) {
			case 2: //2 已开始
				return <div className='active-start'>
					<div className='remainingTimes-button'
						onClick={async () => {
							if (activeInfo.probabilityUpValidNum > 0) {
								upProbability()
							} else {
								Toast.show({
									icon: 'loading',
									content: '准备中',
									duration: 0,
								})
								const res = await API.id54181({
									shareType: '6'
								})
								if (res) {
									const url =
										res.jumpUrl +
										`${res.jumpUrl?.indexOf('?') === -1 ? '?' : '&'}inviteCode=${activeInfo?.inviteCode}`
									bridgeShare(res.title, url, res.shareDesc, res.thumb)
								}
								Toast.clear()
							}
							getNativeFunc();
						}}
					>
						<img
							src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/improve-gai.png"
							alt=""
						/>
						<div className='remainingTimes'>
							{activeInfo.probabilityUpValidNum > 0 ? `剩余${activeInfo.probabilityUpValidNum ?? 0}次` : '邀请新人注册次数+1'}
						</div>
					</div>
					{
						activeInfo.signNo ? <div className='hasSignNo'>
							<img src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/signature.png" alt="" className='signNo-img' />
							<div className='signNo-text'>专属签号:{activeInfo?.signNo}</div>
						</div> : <img
							src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/participate-lottery.png"
							alt=""
							onClick={joinGetCard}
							style={{
								width: "204px"
							}}
						/>
					}

				</div>;
			case 1: //1 已结束
				return <div className='active-end'>
					<img src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/active-end.png" alt="" />
				</div>;
			case 3: //3 未开始
				return <div className='active-end'>
					<img src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/active-no-start.png" alt="" />
				</div>;

			default: return null
		}
	}
	//是否开奖
	const isKaijiang = () => {
		//是否中奖 
		//activeInfo?.userWinStatus
		if (activeInfo?.userWinStatus) {
			return <div className='active-end'>

				{
					activeInfo?.userActivityStatus === 7 || activeInfo?.userActivityStatus === 8 ? <img
						src={
							"https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/send-goods-state.png"
						} alt=""
						onClick={() => {
							bridgePostToPage('/order/list', {})
						}}
					/> : <img
						src={"https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/sendAddress.png"} alt=""
						onClick={() => {
							// setAddressVis(true);  
							// if (activeInfo?.version) {
							bridgePostToPage('/mine/locations', 'getItem')
							// } else {
							// 	Toast.show({
							// 		content: '当前版本过低，请升级App版本!',
							// 	})
							// }

						}}
					/>
				}

			</div>;
		} else {
			return <div className='active-end'>
				<img src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/missed.png" alt="" />
			</div>;
		}

	}
	//活动开始状态
	const activeStateRender = (state) => {
		switch (state) {
			case 1://未开始
				return <div className='active-end'>
					<img src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/active-no-start.png" alt="" />
				</div>;
			case 2: //进行中
				return hasQualifications()
			case 3: //已结束
				return <div className='active-end'>
					<img src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/active-end.png" alt="" />
				</div>;
			default: return null;
		}
	}
	//判断是否有资格
	const hasQualifications = () => {
		// activeInfo?.canDraw
		if (activeInfo?.canDraw) {
			if (activeInfo?.lotteryOver) { //是否开奖 activeInfo?.lotteryOver
				return isKaijiang()  //activeInfo.activityStatus
			} else {
				return renderActiviteStateButton(activeInfo.activityStatus);
			}
		} else {
			return <div className='active-start'>
				<div className='remainingTimes-button'>
					<img
						src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/Ineligibility.png"
						alt=""
					// onClick={() => {
					// 	setProbabilityModel(true)
					// }}
					/>
					<div className='remainingTimes-qualifications' >剩余0次</div>
				</div>
				{/* 无资格 去下单 */}
				<img
					src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/Ineligibility-toBuy.png"
					alt=""
					className='none-canbuy'
					onClick={() => {
						bridgePostToPage('/index/shop', {})

					}}
				// style={{
				// 	width: '205px'
				// }}
				/>
			</div>;
		}
	}
	//获取活动详情接口
	const activeDetails = async () => {
		const res = await API.id61098()
		console.log(res, 'res')
		if (res) {
			setActiveInfo(res)
		}
	}
	//参与抽签
	const joinGetCard = async () => {
		const res = await API.id61106({})
		if (res) {
			setParticipateModel(true);
			activeDetails()
			setLotteryNo(res)
			// localStorage.setItem('lotteryNo', JSON.stringify(res))
		}
	}
	//提升中奖概率
	const upProbability = async () => {
		const res = await API.id61114()
		if (res) {
			setProbabilityModel(true)
			setUpValue(res)
		}
	}
	//发布测试解开注释
	const getNativeFunc = async () => {
		if (window['YoTown']) {
			bridgePostAppBar({ hideAppBar: true })
			try {
				const res = await bridgePostToken()
				const token = res.result?.data
				if (token) {
					localStorage.setItem('token', token)
					activeDetails()
				}
			} catch (error) {
				console.log('nativeFunc', error)
			}
		} else {
			localStorage.clear();
			activeDetails()
			// console.log(window.location.href.split('?')?.[1]?.split('=')?.[1], 'inviteCode')
			setInviteCode(window.location.href.split('?')?.[1]?.split('=')?.[1])
		}
		// getInfo()
	}

	useEffect(() => {
		getNativeFunc();
		return () => {
			localStorage.clear()
		}
		// console.log(navigator.userAgent.split(' '))
		// setLotteryNo(JSON.parse(localStorage.getItem('lotteryNo')) ?? {});
	}, [])
	useEffect(() => {
		if (!probabilityModel) {
			getNativeFunc();
			activeDetails()
		}
	}, [probabilityModel])
	return (
		<div className="downloadView">
			{
				!activeInfo?.h5Page && <div className='downloadView-header-bar'>
					<LeftOutline
						onClick={() => {
							bridgePostBackPage()
						}} />
					<img
						className='share-active'
						src='https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/share.png'
						alt=''
						onClick={async () => {
							Toast.show({
								icon: 'loading',
								content: '准备中',
								duration: 0,
							})
							const res = await API.id54181({
								shareType: '6'
							})
							if (res) {
								const url =
									res.jumpUrl +
									`${res.jumpUrl?.indexOf('?') === -1 ? '?' : '&'}inviteCode=${activeInfo?.inviteCode
									}`
								bridgeShare(res.title, url, res.shareDesc, res.thumb)
							}
							Toast.clear()
						}} />
				</div>
			}

			<img src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/active-rules-button.png" alt="" className='active-rules-button'
				onClick={() => {
					setActiveRules(true)
				}}
			/>
			<div className='lottery-title'>
				<div className='active-time'>活动时间：{activeInfo?.activityTimeZones ?? "--"}</div>
				<img src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/lottery-title.png" alt="" />
				<div className='title-content'>成功购买商品即可参与</div>
			</div>
			<div className='active-lottery-container'>
				{
					!activeInfo?.h5Page ? <div className='winning-probability'>
						<div className='title-text'>中奖概率获得</div>
						<div className='probability-num'>{activeInfo?.totalWinAwardProbabilityUp ? activeInfo?.totalWinAwardProbabilityUp : '0.0%'}</div>
						<div className='title-text'>加成</div>
					</div> : <div className='winning-probability'>
						<div className='title-text'>中奖概率可加成</div>

					</div>
				}

				<img
					src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/turn-left.png"
					alt=""
					className='prev'
				// onClick={() => {
				// 	setActiveState(val => val -= 1)
				// }}
				/>
				<img
					src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/background-modal.png"
					alt=""
					className='model-img'
				/>
				<div
					style={{
						width: '53%',
						top: "15%",
						position: 'absolute',
						zIndex: 0,
						left: '50%',
						transform: 'translate(-50%)'
					}}>
					<Swiper
						slidesPerView={1}
						speed={1000}
						loop
						autoplay
						navigation={{ prevEl: '.prev', nextEl: '.next' }}
						touchMoveStopPropagation
						pagination={{
							el: '.swiper-pagination',
							type: 'bullets',
							clickable: true,
						}}
					>
						<SwiperSlide
							style={{
								width: '30%',
								display: 'flex',
								alignItems: 'center',
								flexDirection: 'column',
							}}>
							<img
								src={"https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/my-prize.png"}
								alt=""
								className='my-prize'
							// onClick={(e) => {
							// 	history.push('/activity/buyFree');
							// 	e.preventDefault()
							// }}
							/>
							<img
								src={
									activeInfo?.activityStatus === 1 && !activeInfo?.lotteryOver && activeInfo?.signNo ?
										'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/active-nostart.png' :
										activeInfo?.userWinStatus ?
											activeInfo?.userWinAward?.awardImg :
											'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/yigongbu.png'
								}
								alt=""
								className='goods-img'
								style={{
									width: "48%",
									height: '18%'
								}}
							/>
							<div
								className='click-check-list'
								onClick={() => {
									if (!activeInfo?.h5Page) {
										if (activeInfo?.lotteryOver) {
											setWinningList(true)
										}
									}
									else {
										// console.log(inviteCode, 'inviteCode')
										inviteCode?.length ? history.push(`/activity/shareInvite?inviteCode=${inviteCode}`) : history.push(`/activity/shareInvite`)
									}
								}}
							>
								{
									/***
								*
								*
								* ***/
									activeInfo?.activityStatus === 2 ?
										!activeInfo?.lotteryOver ?
											"等待开奖（任意一枚）" :
											activeInfo?.userWinStatus ? '恭喜您已中奖，查看名单' :
												'中奖结果已公布，查看名单' :
										activeInfo?.activityStatus === 1 ?
											'未开始,等待抽奖' :
											'活动已结束'
								}
								<img src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/arrow.png" alt="" className='right-line' />
							</div>
							<div className='update-time'>
								{activeInfo?.lotteryTime}
							</div>
							<div className='tooltip-content'
							// onClick={(e) => {
							// 	history.push('/activity/registration');
							// 	e.preventDefault()
							// }}
							>左右切换查看活动奖品</div>
						</SwiperSlide>

						{
							activeInfo?.awards?.map((item, index) => {

								return <SwiperSlide
									key={index}
									style={{
										width: '30%'
									}}>
									<ItemCard item={item} />
								</SwiperSlide>
							})
						}
						{
							!activeInfo?.lotteryOver
						}

					</Swiper>
				</div>

				<img
					className='next'
					src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/turnRight.png"
					alt=""
				// onClick={() => {
				// 	setActiveState(val => val += 1)
				// }}
				/>

			</div>
			{/* 渲染活动按钮 */}
			{
				!activeInfo?.h5Page ? activeStateRender(activeInfo?.activityStatus) :
					<div className='active-end'
						onClick={() => {
							inviteCode?.length ? history.push(`/activity/shareInvite?inviteCode=${inviteCode}`) : history.push(`/activity/shareInvite`)
						}}>
						<img src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/go-to-active.png" alt="" />
					</div>
			}
			{
				!activeInfo?.h5Page && <div className='how-get'>
					<div className='how-up'
						onClick={() => {
							setvisibale(true);
							setNumberProbability(true);
						}}
					>如何提升中奖概率?</div>
					<Divider direction='vertical' />
					<div className='how-get-text'
						onClick={() => {
							setNumberProbability(false);
							setvisibale(true);
						}}
					>如何获得抽奖次数?</div>
					{/* 获得抽奖次数和概率 */}
					<Popop
						history={history}
						isVisibale={visibale}
						inviteCode={activeInfo?.inviteCode}
						setVisibale={(value: boolean) => {
							setvisibale(value)
						}}
						numberProbability={numberProbability}
					/>
				</div>
			}

			{/* 中奖名单 */}
			<Popup
				visible={winningList}
				onMaskClick={() => {
					setWinningList(false)
				}}
				bodyStyle={{
					borderTopLeftRadius: '18px',
					borderTopRightRadius: '18px',
					minHeight: '60vh',
				}}
			>
				<div className='activete-nameinner'>
					<div className='head-title'>
						<img
							src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/activete-name-list.png"
							alt=""
							className='activete-name-title'
						/>
					</div>
					<img
						src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/close-icon.png"
						alt=""
						className='close'
						onClick={() => {
							initData()
						}}
					/>
					<div className='name-list'>
						{/* {map} */}
						{
							activeInfo?.userAwards?.map((item, index) => {
								return <div className='item-card' key={index}>
									<div className='goods-img-box'>
										<img src={item?.awardImg} alt="" className='goods-img' />
									</div>
									<div className='card-info'>
										<div className='card-title'>{item?.awardName}</div>
										<div className='get-user'>
											<div className='label'>获奖者：</div>
											<div className='user-infor'>
												<img src={item?.headImg} alt="" className='user-img' />
												<div className='user-name'>{item?.nickName}</div>
											</div>
										</div>
									</div>
								</div>
							})
						}

					</div>
				</div>
			</Popup>
			{/* 抽签成功 */}
			<Modal
				visibale={participateModel}
				initData={initData}
				signNo={activeInfo?.signNo ?? lotteryNo?.signNo}
				lotteryTime={activeInfo?.lotteryTime ?? lotteryNo?.lotteryTime}
			/>

			{/* 概率提升 */}
			<ProbabilityModel
				visible={probabilityModel}
				initData={initData}
				history={history}
				upValue={upValue}
				inviteCode={activeInfo?.inviteCode}
			/>
			{/* 活动规则 */}
			<Popup
				visible={activeRules}
				onMaskClick={() => {
					setWinningList(false)
				}}
				bodyStyle={{
					borderTopLeftRadius: '18px',
					borderTopRightRadius: '18px',
					maxHeight: '60vh',

				}}
			>
				<div className='activete-rules'>
					<div className='head-title'>
						<img
							src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/active-rules.png"
							alt=""
							className='activete-name-title'
						/>
					</div>
					<img
						src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/close-icon.png"
						alt=""
						className='close'
						onClick={() => {
							initData()
						}}
					/>
					<div className='rules-context'>
						<div className='active-time'>1. 活动时间</div>
						活动期间：2022年9月24日00:00:00 - 2022年10月18日18:00:00
						<br /> 开奖时间：2022年10月18日20:00:00（自动开奖）
						<div className='active-time'>2. 活动规则</div>
						（1）参与门槛：用户在活动期间成功购买YKYB&嘎拉兔联名商品，即可参与抽奖。
						<br />*成功购买的定义：
						<br />A.客户端内用户，在活动期间成功下单且不产生退款退货。
						<br />B.客户端外用户，在活动期间登录柚堂APP完成商品绑定，且不产生退款退货。
						<br />（2）抽奖次数：每名用户仅有1次参与抽奖的机会。
						<br />（3）开奖：开奖时间自动开奖，用户可点击“立即开奖”查看是否中奖，以及中奖名单。
						<br />（4）中奖概率：每名参与抽奖的用户初始中奖概率相同，中奖概率会随着奖池中参与抽奖人数的变化而产生波动。
						<br />（5）中奖概率加成：在活动期间，每成功邀请1名好友，可获得1次提升中奖概率的机会（单次加成10%），最多10次提升机会，即邀请超过10人，仍按10人计算。
						<br />*成功邀请的定义：被邀请用户在活动期间下载并登录柚堂APP（激活），视为邀请成功。
						<div className='active-time'>3. 奖项设置</div>
						本次活动奖项设置为：又燃又兔 ROBBi ART 超玩手办4款实物奖品，数量共计4个，具体为：
						<br />（1）ROBBi x ASTON MARTIN 星驰玩家1000% ，数量1个；
						<br />（2）ROBBi x 3CE 深得我心 情人节限定1000% 1，数量1个；
						<br />（3）ROBBi x YKYB YOUNG BOSS 1000% ，数量1个；
						<br />（4）ROBBi 微观世界 彩虹诱惑 1000% ，数量1个。
						<br />	*说明：4款奖品随机开奖，用户不可指定奖品款式。
						<div className='active-time'>4. 领奖规则</div>
						（1）中奖者请在1周内正确填写/确认收件信息（姓名、电话及地址），主办方将安排相应奖品根据中奖者填写信息邮寄，即视为主办方交付完成。逾期未正确填写相关收件信息的，视为自动放弃。
						<br />（2）中奖用户若在下单后30天内发生退款退货情形，则不满足活动参与条件，将取消中奖资格，已经发放奖品的，主办方有权追索相应奖品、追究相应法律责任。
						<div className='active-time'>5.  主办方如发现中奖者有作弊行为的，一经发现可无条件取消其中奖资格并追索相应奖品、追究相应法律责任。</div>
						<div className='active-time'>6. 本活动最终解释权归柚堂所有。如有疑问，可致电主办方官方客服电话：0571-22930850</div>
					</div>
				</div>
			</Popup>
			{/* <button onClick={async () => {
				if (window['YoTown']) {
					try {
						const res = await bridgePostToken()
						console.log(res)
					} catch (error) {

					}
				}

			}} >get Token</button>

			<button onClick={() => {
				if (window['YoTown']) {
					try {
						bridgePostToPage('/mine/exchange_center', {})
					} catch (error) {

					}
				}
			}} >jump to page</button> */}
		</div >
	)
}

export default DownLoadView
