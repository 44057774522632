// @ts-nocheck
import React from 'react'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom'
import FastClick from 'fastclick'
import { Context } from './context'

import MainHome from './page/home/index'
import MainResult from './page/result/Index'
import MainActivity from './page/activity/Index'
import MainAgreement from './page/agreement/Index'
import MainDownload from './page/download/index'
import MainGoodsDetail from './page/goodsDetail/index'
import CollectionDetail from './page/collectionDetail/index'
import MainActivityBadge from './page/badge/Index'
import MainLive from './page/live/Index'
import MainActivityInvite from './page/invite/Index'
import MainShareInvite from './page/shareInvite/Index'

import AgreementService from './page/agreement/mobile/service'
import AgreementPrivacy from './page/agreement/mobile/privacy'
import Materials from './page/home/index'
import LaunchCalendar from './page/launchCalendar'
import Lottery from "./page/lottery"
import BuyFree from "./page/buyFree"
import RecommendShare from "./page/recommendShare"

import './styles/antd.css'
import './styles/common.css'
import 'antd-mobile/es/global'

FastClick.attach(document.body)
const App = () => {
	const platform = /Android|webOS|iPhone|iPad|BlackBerry/i.test(
		navigator.userAgent
	)
		? 'mobile'
		: 'pc'

	return (
		<Context.Provider value={platform}>
			<Router>
				<Switch>
					<Route exact path="/" component={MainHome} />
					{/* 首页 移动+pc */}
					<Route exact path="/home" component={MainHome} />
					{/* materials 产品起的名字 :官网的移动端页面，可以独立复制出来一套，放在www.naxiawl.com下面的一个分支？比如www.naxiawl.com/xxx，这个域名UED那边需要生成一个二维码，贴到实体物料上面*/}
					<Route exact path="/materials" component={Materials} />
					{/* nfc鉴定结果 */}
					<Route exact path="/result" component={MainResult} />
					{/* 集卡 */}
					<Route exact path="/activity/compound" component={MainActivity} />
					{/* 原住民 */}
					<Route exact path="/activity/badge" component={MainActivityBadge} />
					{/* 邀请好友 */}
					<Route exact path="/activity/invite" component={MainActivityInvite} />
					{/* 分享注册页 */}
					<Route
						exact
						path="/activity/shareInvite"
						component={MainShareInvite}
					/>
					{/* 结果 */}
					<Route exact path="/agreement" component={MainAgreement} />
					{/* 协议 */}
					<Route exact path="/agreement/service" component={AgreementService} />
					{/* 隐私 */}
					<Route exact path="/agreement/privacy" component={AgreementPrivacy} />
					{/* 下载 */}
					<Route exact path="/download" component={MainDownload} />
					{/* 商详 */}
					<Route exact path="/goodsDetail" component={MainGoodsDetail} />
					{/* 藏详 */}
					<Route exact path="/collectionDetail" component={CollectionDetail} />
					{/* 直播 */}
					<Route exact path="/live" component={MainLive} />
					{/* 发售日历 */}
					<Route exact path="/launchCalendar" component={LaunchCalendar} />
					{/* 抽奖 */}
					<Route exact path='/activity/lottery' component={Lottery} />
					{/* 买赠 */}
					<Route exact path='/activity/buyFree' component={BuyFree} />
					{/* 买赠 */}
					<Route exact path='/recommend/share' component={RecommendShare} />
					{/* 
						/grass/planting 种草穿搭
					*/}
					<Redirect push to="/" />
				</Switch>
			</Router>
		</Context.Provider>
	)
}

export default App
