import React, { useEffect, useState } from 'react'
import 'video-react/dist/video-react.css'

import './style/index.less'

import PageHead from './components/PageHead'
import PageFooter from './components/PageFooter'
import { Main } from './Main/Main'
import { Business } from './Business/Business'
import { Us } from './Us/Us'

export default ({ history }: any) => {
	const [pageIndex, setPageIndex] = useState<'main' | 'business' | 'us'>('main')
	/* Loaded */
	useEffect(() => {}, [])

	return (
		<div className="pc-home-page">
			<PageHead
				className="pc-head"
				pageIndex={pageIndex}
				changgeIndex={setPageIndex}
				history={history}
			/>
			<div className="wp-container">
				{/* 首页 */}
				{pageIndex === 'main' && <Main />}
				{/* 商务合作 */}
				{pageIndex === 'business' && <Business />}
				{/* 关于我们 */}
				{pageIndex === 'us' && <Us />}
			</div>
			<PageFooter />
		</div>
	)
}
