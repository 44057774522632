import React, { useCallback, useEffect, useState } from 'react'
import { AndroidFilled, AppleFilled } from '@ant-design/icons'
import './index.less'
import { useHeight } from './useHeight'
import GoodsSwiper from '../components/goodsSwiper'
import Youtangbg from '../components/youtangbg'

type Props = {}

const iosUrl =
	'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/download.png'
const androidUrl =
	'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/download.png'

export const Main = (props: Props) => {
	const [qcurl, setQcurl] = useState<string>(iosUrl)

	const [height] = useHeight(1920 / 1080)

	const changeOrigin = (type = 'android') => {
		if (type === 'android') {
			setQcurl(androidUrl)
		} else {
			setQcurl(iosUrl)
		}
	}

	return (
		<div className="main_wrap_pc">
			<div className="video">
				<video
					className="resizelistener"
					loop
					muted
					autoPlay
					playsInline={true}
					webkit-playsinline="true"
					x5-playsinline="true"
					x5-video-player-type="h5-page"
				>
					<source
						src="https://daichongtest.oss-cn-hangzhou.aliyuncs.com/marketing/file/2022/09/57ee88d2-5dc6-456f-8667-e3c61c49f57a.mp4"
						type="video/mp4"
					/>
				</video>
			</div>
			<div className="top" style={{ height }}>
				<div className="iphone">
					<div className="inner" />
					<video
						className="iphone-guide"
						loop
						muted
						autoPlay
						playsInline={true}
						poster="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/scren-1.png"
						webkit-playsinline="true"
						x5-playsinline="true"
						x5-video-player-type="h5-page"
					>
						<source
							src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/video/guide.mp4"
							type="video/mp4"
						/>
					</video>
				</div>
				<div className="other">
					<div className="bl1">
						<div className="icon" />
						<div className="title">
							<div className="name" />
							<div className="desc">逛柚堂，玩潮流</div>
						</div>
					</div>
					<div className="bl2">
						<div className="left">
							<div
								className="apple btn"
								onClick={() => {
									changeOrigin('ios')
								}}
							>
								<AppleFilled
									style={{
										fontSize: '16px',
										color: '#fff',
										marginRight: '8px',
									}}
								/>
								iOS版下载
							</div>
							<div
								className="android btn"
								onClick={() => {
									changeOrigin('android')
								}}
							>
								<AndroidFilled
									style={{
										fontSize: '16px',
										color: '#fff',
										marginRight: '8px',
									}}
								/>
								Android版下载
							</div>
						</div>
						<div className="right">
							<img src={qcurl} alt="" />
						</div>
					</div>
				</div>
			</div>
			<div className="goods-wrap">
				<Youtangbg title="热卖中" />
				<GoodsSwiper />
			</div>
			<div className="other-wrap">
				<Youtangbg title="品质保证" style={{ marginTop: 0 }} />
				<div className="list">
					<div className="listItem">
						<div className="icon icon-1"></div>
						<div className="text">IP独家授权</div>
					</div>
					<div className="listItem">
						<div className="icon icon-2"></div>
						<div className="text">无理由退换货</div>
					</div>
					<div className="listItem">
						<div className="icon icon-3"></div>
						<div className="text">顶级工艺</div>
					</div>
					<div className="listItem">
						<div className="icon icon-4"></div>
						<div className="text">设计师包装</div>
					</div>
				</div>
			</div>
		</div>
	)
}
