// @ts-nocheck
import React from 'react'

import './style/index.less'

const OtherAgreement = ({ history, type }: any) => {
	// 第三方信息共享清单
	const thridInfo = () => {
		return (
			<div>
				<div className="title">第三方信息共享清单</div>
				<p>
					为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多服务和功能，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序。与第三方合作过程中，我们将审慎评估合作方使用数据的目的，对这些合作方的安全保障能力进行综合评估，同时我们将要求合作方对其提供的会获取信息的工具开发包（SDK）、应用程序接口（API）进行严格安全审查，以保护数据安全。
				</p>
				<table className="thridInfo">
					<tbody>
						<tr>
							<td colSpan={4}>第三方信息共享清单</td>
						</tr>
						<tr>
							<td>第三方SDK/API名称</td>
							<td>使用场景</td>
							<td>共享的个人信息类型</td>
							<td>个人信息保护政策地址</td>
						</tr>
						<tr>
							<td>阿里云一键登录SDK</td>
							<td>用于号码的用户快速注册、登录账号</td>
							<td>
								设备标识信息、电话号码、IP地址、网络运营商、网络类型、设备平台、设备厂商、设备型号
							</td>
							<td>
								<a href="https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112211045_86198.html?spm=a2c4g.11186623.0.0.7270ad6bFZlS5o">
									https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112211045_86198.html?spm=a2c4g.11186623.0.0.7270ad6bFZlS5o
								</a>
							</td>
						</tr>
						<tr>
							<td>Ping++ 聚合支付SDK</td>
							<td>用于微信、支付宝等聚合支付</td>
							<td>
								采集常用设备信息（如IMEI/IMSI、SIM卡序列号/MAC地址）、网络状态、剪贴板
							</td>
							<td>
								<a href="https://www.pingxx.com/terms">
									https://www.pingxx.com/terms
								</a>
							</td>
						</tr>
						<tr>
							<td>微信分享SDK</td>
							<td>用于朋友圈、好友分享功能</td>
							<td>
								采集常用设备信息（如IMEI/IMSI、SIM卡序列号/MAC地址）、网络状态、剪贴板
							</td>
							<td>
								<a href="https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8">
									https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8
								</a>
							</td>
						</tr>
						<tr>
							<td>友盟SDK</td>
							<td>用于App页面、用户统计以及个性化埋点分析、渠道化打包</td>
							<td>
								采集常用设备信息（Android如IMEI、AndroidID、OAID、IMSI、ICCID、GAID、MEID，iOS如IDFV、IDFA）、MAC地址、网络状态、剪贴板、设备序列号
							</td>
							<td>
								<a href="https://www.umeng.com/policy">
									https://www.umeng.com/policy
								</a>
							</td>
						</tr>
						<tr>
							<td>Bugly异常统计SDK</td>
							<td>用于统计App运行中出现的异常信息收集</td>
							<td>
								采集常用设备信息（如IMEI/IMSI、SIM卡序列号/MAC地址）、网络状态、剪贴板
							</td>
							<td>
								<a href="http://bugly.qq.com/v2/contract">
									http://bugly.qq.com/v2/contract
								</a>
								<br />
								<a href="http://privacy.qq.com/">http://privacy.qq.com/</a>
							</td>
						</tr>
						<tr>
							<td>个推SDK</td>
							<td>用于消息推送，辅助用户按需关注App以及体验性提升。</td>
							<td>
								采集常用设备信息（如IMEI/IMSI、SIM卡序列号/MAC地址）、网络状态、剪贴板、已安装APP信息
							</td>
							<td>
								<a href="https://docs.getui.com/privacy/">
									https://docs.getui.com/privacy/
								</a>
							</td>
						</tr>
						<tr>
							<td>网易七鱼SDK</td>
							<td>用于客服中心，辅助用户在软件使用过程中出现的问题咨询。</td>
							<td>
								采集常用设备信息（Android如IMEI、AndroidID、OAID、IMSI、ICCID、GAID、MEID，iOS如IDFV、IDFA）、MAC地址
							</td>
							<td>
								<a href="https://qiyukf.com/termsService">
									https://qiyukf.com/termsService
								</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
	// 权限信息表
	const powerListView = () => {
		return (
			<div>
				<div className="title">权限信息表</div>
				<p>
					此处仅为权限相关说明，我们将在您使用特定功能时，向您申请使用该功能所必须的权限，如您不同意开启该权限，请点击“不允许”，或您希望撤回授权的，您可在设备的设置功能中关闭您已授权的权限，拒绝或撤回该授权仅将影响您对特定产品功能的使用。
				</p>
				<table className="power">
					<tbody>
						<tr>
							<td colSpan={4}>权限信息表</td>
						</tr>
						<tr>
							<td></td>
							<td>收集的权限</td>
							<td>权限与获取信息描述</td>
							<td>收集权限的目的/用途</td>
						</tr>
						<tr>
							<td rowSpan={6}>Android</td>
							<td>读取存储空间/照片权限</td>
							<td>
								1、读取SD卡中的内容
								<br />
								2、提供读取手机储存空间内数据的功能 <br />
								3、android.permission.READ_EXTERNAL_STORAGE{' '}
							</td>
							<td>
								允许App读取存储中的图片、视频文件等内容，主要用于帮助您在使用客服服务过程中发送图片、视频等信息
							</td>
						</tr>
						<tr>
							<td>网络访问权限</td>
							<td>访问网络信息</td>
							<td>用于下载和运行App</td>
						</tr>
						<tr>
							<td>写入/删除/存储空间/照片权限</td>
							<td>
								1、写入/删除SD卡中的内容
								<br />
								2、提供写入外部储存功能
								<br />
								3、android.permission.WRITE_EXTERNAL_STORAGE
							</td>
							<td>
								允许App写入/下载/保存/缓存/修改/删除图片、文件、崩溃日志等信息
							</td>
						</tr>
						<tr>
							<td>大致地理位置信息权限</td>
							<td>
								1、访问大致地理位置信息
								<br />
								2、通过网络位置信息（例如基站和WLAN）获取大致地理位置信息
								<br />
								3、android.permission.ACCESS_COARSE_LOCATION
							</td>
							<td>用于完成安全保障服务及基于地理位置的服务（LBS）</td>
						</tr>
						<tr>
							<td>精确地理位置信息权限</td>
							<td>
								1、访问精确地理位置信息
								<br />
								2、通过全球定位系统（GPS）和网络位置信息（例如基站和WLAN）获取精准地理位置信息
								<br />
								3、android.permission.ACCESS_FINE_LOCATION
							</td>
							<td>用于完成安全保障服务及基于地理位置的服务（LBS）</td>
						</tr>
						<tr>
							<td>录音权限</td>
							<td>
								1、录音
								<br />
								2、允许用户录制声音到本地
								<br />
								3、android.permission.RECORD AUDIO
							</td>
							<td>
								用于客服中心于客服发送语音消息时使用。
							</td>
						</tr>
						<tr>
							<td>相机权限</td>
							<td>
								1、拍摄照片或视频
								<br />
								2、android.permission.CAMERA
							</td>
							<td>用于帮助您在使用客户服务过程中，拍摄图片、视频等</td>
						</tr>
						<tr>
							<td>通知权限</td>
							<td>向用户发送通知</td>
							<td>向用户推送订单履约进程、活动信息等</td>
						</tr>
						<tr>
							<td>日历权限</td>
							<td>获取用户日历权限，写入用户的日程</td>
							<td>
								当用户参加线上活动（如预售活动）并允许提醒时，将触发日历权限以提醒用户在特定时间参与相关活动
							</td>
						</tr>
						<tr>
							<td rowSpan={7}>iOS</td>
							<td>写入相册权限</td>
							<td>
								1、向相册中添加内容
								<br /> 2、NSPhotoLibraryAddUsageDescription
							</td>
							<td>允许App写入/下载/保存/修改/删除图片、文件、崩溃日志等信息</td>
						</tr>
						<tr>
							<td>网络访问权限</td>
							<td>访问网络信息</td>
							<td>用于下载和运行App</td>
						</tr>
						<tr>
							<td>读取相册权限</td>
							<td>
								1、读取相册中内容
								<br />
								2、NSPhotoLibraryUsageDescription
							</td>
							<td>
								允许App读取存储中的图片、文件等内容，主要用于帮助您发布信息等功能
							</td>
						</tr>
						<tr>
							<td>相机权限</td>
							<td>
								1、使用摄像头，拍摄照片或视频
								<br />
								2、NSCameraUsageDescription
							</td>
							<td>用于帮助您在使用客户服务过程中，拍摄图片、视频等</td>
						</tr>
						<tr>
							<td>获取地理位置权限</td>
							<td>
								1、仅App被使用时获取地理位置
								<br />
								2、NSLocationWhenInUseUsageDescription
							</td>
							<td>
								用于完成安全保障、推荐信息以及基于地理位置的服务（LBS）等相关功能
							</td>
						</tr>
						<tr>
							<td>读取设备信息权限</td>
							<td>
								1、获取设备标识，以识别设备信息
								<br />
								2、NSUserTrackingUsageDescription
							</td>
							<td>仅用于标识设备并保障服务安全与提升浏览体验</td>
						</tr>
						<tr>
							<td>麦克风权限</td>
							<td>
								1、麦克风
								<br />
								2、允许用户录制声音到本地
								<br />
								3、Privacy - Microphone Usage Description
							</td>
							<td>
								用于客服中心于客服发送语音消息时使用
							</td>
						</tr>
						<tr>
							<td>临时精确地理位置权限</td>
							<td>
								1、允许临时访问精确地理位置信息
								<br />
								2、NSLocationTemporaryUsageDescriptionDictionary
							</td>
							<td>
								用于完成安全保障、推荐信息以及基于地理位置服务（LBS）等相关功能
							</td>
						</tr>
						<tr>
							<td>通知权限</td>
							<td>向用户发送通知</td>
							<td>向用户推送订单履约进程、活动信息等</td>
						</tr>
						<tr>
							<td>日历权限</td>
							<td>获取用户日历权限，写入用户的日程</td>
							<td>
								当用户参加线上活动（如预售活动）并允许提醒时，将触发日历权限以提醒用户在特定时间参与相关活动
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
	// 个人信息收集清单
	const getUserInfoView = () => {
		return (
			<div>
				<div className="title">个人信息收集清单</div>
				<p>
					为了让您正常使用柚堂服务，我们会按照合法、正当、必要的原则收集您在使用柚堂时主动提供的，以及通过自动化手段收集您在使用柚堂功能或接受柚堂服务过程中产生的个人信息。在收集您的个人信息后，我们会严格依照法律法规规定保护您的信息安全。为了方便您快速了解我们收集您的个人信息的情况，我们准备了本清单。
				</p>

				<table className="userInfo">
					<tbody>
						<tr>
							<td colSpan={3}>个人信息收集清单</td>
						</tr>
						<tr>
							<td>收集的个人信息</td>
							<td>功能/服务名称</td>
							<td>收集的目的/用途</td>
						</tr>
						<tr>
							<td>手机号码</td>
							<td>注册、登录及使用柚堂账号</td>
							<td>用于用户注册账号及登录使用</td>
						</tr>
						<tr>
							<td>真实姓名</td>
							<td>实名认证</td>
							<td>购买潮流单品、明星应援社区任务、用户认证等</td>
						</tr>

						<tr>
							<td>身份证号</td>
							<td>实名认证</td>
							<td>购买潮流单品、明星应援社区任务、用户认证等</td>
						</tr>
						<tr>
							<td>生日</td>
							<td>生日</td>
							<td>账号基础信息完善</td>
						</tr>
						<tr>
							<td>性别</td>
							<td>性别</td>
							<td>账号基础信息完善</td>
						</tr>
						<tr>
							<td>
								个人常用设备信息（设备型号、操作系统、唯一设备标识符、登录IP地址、软件版本号）
							</td>
							<td>网络安全与运营安全保障</td>
							<td>
								读取设备识别码，识别设备ID，保证运营商网络免流服务，用于完成信息展示、账号登录、安全保障等主要功能
							</td>
						</tr>

						<tr>
							<td>收货信息（收货人姓名、收货地址、收货人手机号码）</td>
							<td>商品交易（实物）</td>
							<td>交易过程中，向订购方发货</td>
						</tr>
						<tr>
							<td>操作日志（使用、点击、访问等）</td>
							<td>/</td>
							<td>改善本产品交互界面的内容和布局，同时方便用户问题溯源</td>
						</tr>
						<tr>
							<td>照片、视频</td>
							<td>客户服务</td>
							<td>
								用户使用客户服务过程中，主动发送图片、视频以协助解决用户问题
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
	return (
		<>
			{type === 'third' && thridInfo()}
			{type === 'power' && powerListView()}
			{type === 'userInfo' && getUserInfoView()}
		</>
	)
}

export default OtherAgreement

