import React, { useState } from "react";
import { Popup, Toast } from 'antd-mobile'
import API from '@service/index'
import useJsBridge from '@utils/useJsBridge'
import './style.less'

type PropsParmas = {
  history: any;
  isVisibale: boolean;
  inviteCode: string;
  numberProbability: boolean;
  setVisibale: (value: boolean) => void
}

/*****
 * `1活动起见每成功邀请一位好友注册柚堂，就能在活动页面提升本次抽奖的中奖概率；<br />
            2.成功邀请条件：好友需通过您的邀请码注册，或注册后在【我的】二【设置】一【好友邀请】底部绑定邀请人即可；<br />
            3.每次提升概率中奖加成概率至多不超过1%；<br />
            4.中奖加成达10%以上，这中平台限量手办礼品；<br />`
 * 
*/
const Popop = (props: PropsParmas) => {
  const { isVisibale, numberProbability, setVisibale, history, inviteCode } = props
  const { bridgePostToken, bridgePostToPage, bridgeShare } = useJsBridge()
  return <Popup
    visible={isVisibale}
    onMaskClick={() => {
      setVisibale(!isVisibale)
    }}
    bodyStyle={{
      borderTopLeftRadius: '18px',
      borderTopRightRadius: '18px',
      minHeight: '40vh',
      padding: '8px'
    }}
  >
    <img
      src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/close-icon.png"
      alt=""
      onClick={() => { setVisibale(!isVisibale) }}
      style={{
        width: '40px',
        height: '40px',
        position: 'absolute',
        right: '0'
      }}
    />
    <div className='how-up-modal-title'>
      <img src={!numberProbability ?
        "https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/how-get-opportunity.png" :
        'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/how-up-modal-title.png'} alt="" style={{
          width: '187px',
          height: '20px',
          margin: '20px auto'
        }} />
    </div>
    {
      numberProbability ? <div className="get-num">
        <div className="get-num-content">
          在活动期间，用户每成功邀请1人，可获得1次提升中奖概率的机会（单次加成10%），最多10次机会。
          <br />举例：当前中奖概率为y，成功邀请8人后，中奖概率为y + y*80%
          <br />说明：被邀请的好友下载并登录柚堂APP，视为邀请成功
        </div>
        <img
          src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/invitation-button.png"
          alt=""
          className="to-buy-button"
          onClick={async () => {
            Toast.show({
              icon: 'loading',
              content: '准备中',
              duration: 0,
            })
            const res = await API.id54181({
              shareType: '6'
            })
            if (res) {
              const url =
                res.jumpUrl +
                `${res.jumpUrl?.indexOf('?') === -1 ? '?' : '&'}inviteCode=${inviteCode
                }`
              bridgeShare(res.title, url, res.shareDesc, res.thumb)
            }
            Toast.clear()
          }}
        />
      </div> : <div className="get-num">
        <div className="get-num-content">活动期间任意购买一款平台内的商品即可获得抽万元ROBBI手办资格</div>
        <img
          src="https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/lottery/newImg/toBuy.png"
          alt=""
          className="to-buy-button"
          onClick={() => {
            bridgePostToPage('/index/shop', {})
          }}
        />
      </div>
    }
  </Popup>
}

export default Popop;