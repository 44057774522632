import React from 'react'
import './index.less'
type Props = {
	img: string
	name: string
}

const androidUrl =
	'https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/home/download.png'
const GoodsItemCard = (props: Props) => {
	const { name, img } = props
	return (
		<div className="goodsItemCard_pc">
			<div className="mainpng">
				<img src={img} alt="" />
			</div>
			<div className="name">{name}</div>
			<div className='download_container'>
				<div className="download">
					<div className="icon"></div>
					下载客户端购买
					<div className="qcurl">
						<img src={androidUrl} alt="" />
						<div className="name">客户端下载</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default GoodsItemCard
