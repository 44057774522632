import React, { useEffect, useState } from 'react'
import {
	Button,
	Form,
	Input,
	Popup,
	PullToRefresh,
	TextArea,
} from 'antd-mobile'
import { Toast } from 'antd-mobile/2x'
import './style/index.less'
import losing from '../../../assets/activity/losing.png'
import API from '@service/index'
import useJsBridge from '@utils/useJsBridge'
import BackButton from '@components/backButton'

export default ({ history }: any) => {
	const getInfoApi = API.id50443
	const doPrizeApi = API.id50450
	const remixCardApi = API.id54706

	const [saveLocationLoading, setsaveLocationLoading] = useState(false)

	const doSaveLocationApi = API.id50457

	const { bridgePostToken, bridgePostAppBar } = useJsBridge()
	const [goodInfos, setGoodInfos] = useState<any>({
		// userActivityStatus: 5,
		// userWinStatus: 1
	})
	const [addressVisible, setAddressVisible] = useState(false)
	const [syntheticVis, setSyntheticVis] = useState(false)
	const [runBoxVis, setRunBoxVis] = useState(false)
	const [winningVis, setWinningVis] = useState(false)
	const [giftLevel, setGiftLevel] = useState(1)
	const [form] = Form.useForm()
	/* Loaded */
	useEffect(() => {
		// localStorage.setItem("token", 'eyJUeXBlIjoiSnd0IiwidHlwIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJ1SWQiOiJVTjM1OTA4MTEyOTc3Nzk0MjUyOCIsIm5iZiI6MTY1NjM4NjM2OSwiaXNzIjoibHZhIiwiZXhwIjoxNjU2OTkxMTY5LCJpYXQiOjE2NTYzODYzNjl9.aITJyu2_nbkbkdFrADCK76WbtdZsO6v6unHM4JASkvo');
		//getInfo()
		getNativeFunc()
	}, [])

	const getNativeFunc = async () => {
		if (window['YoTown']) {
			bridgePostAppBar({ hideAppBar: true })
			try {
				const res = await bridgePostToken()
				const token = res.result?.data
				if (token) {
					localStorage.setItem('token', token)
					getInfo()
				}
			} catch (error) {}
		}
		//测试用
		// getInfo()
	}
	const getInfo = async () => {
		const res = await getInfoApi({})
		if (res) {
			console.log(333, res)

			setGoodInfos(res)
		}
	}
	const getDefaultDiv = () => {
		return (
			<div className="price-table-content">
				<div className="price-table-content-body imgBody">
					{goodInfos.flagments?.map?.((item: any, index: number) => {
						return (
							<img
								key={index}
								className={`contentImg ${item.targetStatus ? '' : 'grayImg'}`}
								src={item.flagImgUrl}
							/>
						)
					})}
				</div>
			</div>
		)
	}

	const getWaitOPenDiv = () => {
		return (
			<div className="price-table-content price-table-content-surprise">
				<div className="price-table-content-body">
					<div className="goodsBox"></div>
					<div className="bottomTable"></div>
				</div>
			</div>
		)
	}

	const getResultsGoods = () => {
		return (
			<div className="price-table-content price-table-content-surprise">
				<div className="price-table-content-body">
					<div className="rahmen">
						{goodInfos.userWinStatus === 1 ? (
							<div className="winView">
								<img src={goodInfos?.userWinAward?.awardImg || ''} alt="" />
								<div className="text">{goodInfos?.userWinAward?.awardName}</div>
							</div>
						) : (
							<div className="losingView">
								<img src={losing} alt="" />
								<div className="text">很遗憾，未中奖~</div>
							</div>
						)}
					</div>
					<div className="bottomTable"></div>
				</div>
			</div>
		)
	}
	// 打开/关闭合成
	const openSyntheticVis = () => {
		setSyntheticVis(!syntheticVis)
		setTimeout(() => {
			setGoodInfos({ ...goodInfos, userActivityStatus: 3 })
			doRemix()
		}, 2000)
	}
	const doRemix = async () => {
		const res = await remixCardApi({ activityNo: goodInfos.activityNo } as any)
		if (res) {
			getInfo()
		}
	}

	// 打开/关闭 中奖
	const openBoxVis = () => {
		setRunBoxVis(true)
		setTimeout(() => {
			openWinningVis()
		}, 500)
	}
	const openWinningVis = () => {
		setWinningVis(!winningVis)
		setRunBoxVis(false)
	}

	const getRadioListView = () => {
		return (
			<div className="price-table-content-body">
				<div className="radiolist">
					<div className="radioview">
						<div className="div1">
							<div
								className="div2"
								style={{
									animation: `anis ${goodInfos.userAwards.length *
										2}s linear infinite`,
								}}
							>
								{goodInfos.userAwards.map((item: any, index: number) => {
									return (
										<p
											key={index}
										>{`${item.nickName} 获得${item.awardName}`}</p>
									)
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	const openAction = () => {
		const status = goodInfos.userActivityStatus
		switch (status) {
			case 2:
				openSyntheticVis()
				break
			case 4:
				openPrize()
				break
			default:
				break
		}
	}
	const openPrize = async () => {
		Toast.show({
			icon: 'loading',
			content: '抽取中…',
			duration: 0,
		})
		const resP: any = await doPrizeApi({ recordNo: goodInfos.activityNo })
		if (resP) {
			Toast.clear()
			const gi = { ...goodInfos }
			// awardReceiveType领奖状态 0-未中奖 1-未领奖 2-已领奖
			if (resP.awardReceiveType) {
				setGiftLevel(resP.awardLevel)
				openBoxVis()
				setTimeout(() => {
					gi.userWinAward = {
						awardName: resP.productName,
						awardImg: resP.previewImgUrl,
					}
					gi.userActivityStatus = 6 // 新的状态 5未中奖 6 中奖
					gi.userWinStatus = 1
					setGoodInfos(gi)
					getInfo()
				}, 2000)
			} else {
				gi.userActivityStatus = 5
				gi.userWinStatus = 0
				setGoodInfos(gi)
				getInfo()
			}
		} else {
			Toast.clear()
		}
	}
	const submitAction = async () => {
		const values = await form.validateFields()
		setsaveLocationLoading(true)
		const res = await doSaveLocationApi({
			...values,
			recordNo: goodInfos.activityNo,
		})
		setsaveLocationLoading(false)
		if (res) {
			setGoodInfos({ ...goodInfos, userActivityStatus: 8 })
			getInfo()
			setAddressVisible(false)
		}
	}
	const openAddressView = () => {
		setAddressVisible(true)
	}
	const getClassWithButton = () => {
		/* no-all: 未集齐 all-in: 已集齐，请合成 open:等开启 opened:已开启 wait： 等开奖 */
		let cName = 'all-in'
		switch (goodInfos.userActivityStatus) {
			case 1: //未集齐 未完成
				cName = 'no-all'
				break
			case 2: //已集齐,立即合成 完成待确认
				cName = 'all-in'
				break
			case 3: //已合成,待开奖 :确认待发放
				cName = 'wait'
				break
			case 4: //立即开奖 发放待提交
				cName = 'open'
				break
			case 5: //已开奖 :已提交
			case 6: //已完成
			case 7: //已完成
			case 8: //已完成
				cName = 'opened'
				break
			default:
				break
		}
		return cName
	}
	return (
		<PullToRefresh onRefresh={getInfo}>
			<div className="main-activity-m-page">
				<BackButton />
				<div className="main-activity-content">
					<div className="main-activity-content-tit"></div>
					<div className="price-table-content">
						{!!goodInfos.userAwards?.length && getRadioListView()}
					</div>

					{[1, 2].includes(goodInfos.userActivityStatus) && getDefaultDiv()}
					{[3, 4].includes(goodInfos.userActivityStatus) && getWaitOPenDiv()}
					{[5, 6, 7, 8].includes(goodInfos.userActivityStatus) &&
						getResultsGoods()}

					{/* 合并动画 */}
					{syntheticVis ? <div className="card-synthetic"></div> : null}
					{/* 中奖 S */}
					{runBoxVis ? (
						<div className="card-box-run">
							<div className="card-box-run-box"></div>
						</div>
					) : null}
					{winningVis ? (
						<div className="card-winning">
							<span className="mask" onClick={openWinningVis}></span>
							<div className="card-winning-tit">
								<div className="title">恭喜您 中奖啦！</div>
								<div>中奖后请及时填写收货地址和联系方式</div>
							</div>
							<div
								className={`card-winning-content ${
									giftLevel === 1 ? 'card-winning-1' : 'card-winning-2'
								}`}
								onClick={openWinningVis}
							></div>
						</div>
					) : null}
					{/* 中奖 E */}
					{/* no-all: 未集齐 all-in: 已集齐，请合成 open:等开启 opened:已开启 wait： 等开奖 */}
					<div
						className={`main-activity-btn ${getClassWithButton()}`}
						onClick={openAction}
					></div>
					<div className="endTimeVoew">
						{[6, 7, 8].includes(goodInfos.userActivityStatus) && (
							<div onClick={openAddressView}>
								{goodInfos.userActivityStatus === 6
									? ' 填写收货地址和联系方式 >'
									: ' 已填写收货地址，去查看 >'}
							</div>
						)}
						{goodInfos.userActivityStatus < 5 && (
							<div>{goodInfos.lotteryTime}</div>
						)}
					</div>

					{/* <div className='main-activity-btn open' onClick={openBoxVis}></div> */}
				</div>
				{/* 奖品一览 */}
				<div className="main-activity-info-item">
					<div className="title">
						<span className="line line-left"></span>
						<span className="name">奖品一览</span>
						<span className="line line-right"></span>
					</div>

					<div className="good-lists">
						{goodInfos.awards?.map?.((item: any, index: number) => {
							return (
								<div className="good-list-item" key={index}>
									<img src={item.awardImg} alt="" />
									<div className="name">{item.awardName}</div>
									<div className="tip">{item.awardDesc}</div>
								</div>
							)
						})}
					</div>
				</div>
				{/* 活动规则 */}
				<div className="main-activity-info-item">
					<div className="title">
						<span className="line line-left"></span>
						<span className="name">活动规则</span>
						<span className="line line-right"></span>
					</div>
					<div className="rules">
						<div className="tip">
							1、采⽤预报名机制，共有ABCDEF六款限量潮玩，注册即可购买；其中ABC款为S档，DE款为SR档，F为SSR档。除F外每款⼿办数量为5000个，F款共计2000个。集⻬六个NFT即可参与兑换⼤奖；
						</div>
						<div className="tip">
							2、每个账号每款抽奖数量限制为⼗次，若未中签则自动退款；
						</div>
						<div className="tip">
							3、每款限量潮玩均对应实物奖励，按照NFT的等级进⾏分类；
						</div>
					</div>
				</div>
				<Popup
					visible={addressVisible}
					onMaskClick={() => {}}
					bodyStyle={{
						borderTopLeftRadius: '36px',
						borderTopRightRadius: '36px',
					}}
					getContainer={null}
				>
					<div className="popHeader">
						收件人信息
						<div
							className="close"
							onClick={() => {
								setAddressVisible(false)
							}}
						></div>
					</div>
					{goodInfos.userActivityStatus === 6 && (
						<>
							<Form form={form} layout="horizontal" className="formClass">
								<Form.Item
									name="receiver"
									label="收件人"
									rules={[{ required: true, message: '请输入收件人姓名' }]}
								>
									<Input placeholder="请输入收件人姓名" />
								</Form.Item>
								<Form.Item
									name="phone"
									label="手机号"
									rules={[
										{
											required: true,
											message: '请输入收件人手机号码',
										},
										{
											message: '请输入正确手机号码',
											pattern: /^1\d{10}$/,
										},
									]}
								>
									<Input placeholder="请输入收件人手机号码" />
								</Form.Item>
								<Form.Item
									name="address"
									label="收货地址"
									rules={[{ required: true, message: '请输入地址' }]}
								>
									<TextArea
										placeholder="请输入详细的收货地址，如省市区县、街道、楼牌号等"
										autoSize={{ minRows: 4, maxRows: 4 }}
									/>
								</Form.Item>
							</Form>
							<div className="popButton">
								<Button
									loading={saveLocationLoading}
									block
									color="primary"
									size="large"
									onClick={submitAction}
								>
									保存
								</Button>
							</div>
						</>
					)}
					{goodInfos.userActivityStatus > 6 && (
						<div className="showAddress">
							<div className="nameAndPhone">
								<span>{goodInfos?.userWinAward?.receiver || '-'}</span>
								{goodInfos?.userWinAward?.phone || '-'}
							</div>
							<div className="detail">
								{goodInfos?.userWinAward?.address || '-'}
							</div>
						</div>
					)}
				</Popup>
			</div>
		</PullToRefresh>
	)
}
