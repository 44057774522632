// @ts-nocheck
import React, { useEffect, useState } from 'react'
import Privacy from './privacy'
import Service from './service'
import { getQueries } from '@ywfe/utils'
import OtherAgreement from './other'
import './style/index.less'

export default ({ history }: any) => {
	const [type, setType] = useState('')
	const params: any = getQueries()

	/* Loaded */
	useEffect(() => {
		const type = params.type
		if (!type) {
			window.location.href = `${window.location.origin}`
		}
		setType(type)
	}, [])

	return (
		<div>
			{type === 'service' && <Service />}
			{type === 'other' && <Privacy />}
			<div className="main-agreement-m-page">
				<OtherAgreement type={type} />
			</div>
		</div>
	)
}
